@media (max-width: 575.98px) {
    section.contact-section {
        min-height: calc(100vh - 130px);
        width: 100%;
        padding: 20px;
    }
    
    h1.contact-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    p.contact-txt-p {
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;;
    }
    
    form.contact-form {
        width: 100%;
        margin: 0 auto;
    }
    
    .contact-input {
        background-color: white;
        margin-bottom: 20px;
    }

    p.contact-accept-privacy {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
    button.contact-submit-btn {
        background-color: #007bff;
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        width: 100%;
    }
    
    button.contact-submit-btn:hover {
        background-color: #0065d1;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    section.contact-section {
        min-height: calc(100vh - 130px);
        width: 100%;
        padding: 20px;
    }
    
    h1.contact-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    p.contact-txt-p {
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;;
    }
    
    form.contact-form {
        width: 100%;
        margin: 0 auto;
    }
    
    .contact-input {
        background-color: white;
        margin-bottom: 20px;
    }

    p.contact-accept-privacy {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
    button.contact-submit-btn {
        background-color: #007bff;
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        width: 100%;
    }
    
    button.contact-submit-btn:hover {
        background-color: #0065d1;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    section.contact-section {
        min-height: calc(100vh - 130px);
        width: 100%;
        padding: 50px;
    }
    
    h1.contact-title-h1 {
        font-size: 2.5em;
        font-weight: 700;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    p.contact-txt-p {
        width: 600px;
        font-size: 1.2em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;;
    }
    
    form.contact-form {
        width: 600px;
        max-width: 90%;
        margin: 0 auto;
    }
    
    .contact-input {
        background-color: white;
        margin-bottom: 20px;
    }

    p.contact-accept-privacy {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
    button.contact-submit-btn {
        background-color: #007bff;
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        width: 100%;
    }
    
    button.contact-submit-btn:hover {
        background-color: #0065d1;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    section.contact-section {
        min-height: calc(100vh - 130px);
        width: 100%;
        padding: 50px;
    }
    
    h1.contact-title-h1 {
        font-size: 2.5em;
        font-weight: 700;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    p.contact-txt-p {
        width: 600px;
        font-size: 1.2em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;;
    }
    
    form.contact-form {
        width: 600px;
        max-width: 90%;
        margin: 0 auto;
    }
    
    .contact-input {
        background-color: white;
        margin-bottom: 20px;
    }

    p.contact-accept-privacy {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
    button.contact-submit-btn {
        background-color: #007bff;
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        width: 100%;
    }
    
    button.contact-submit-btn:hover {
        background-color: #0065d1;
    }
}

@media (min-width: 1200px) {
    section.contact-section {
        min-height: calc(100vh - 130px);
        width: 100%;
        padding: 50px;
    }
    
    h1.contact-title-h1 {
        font-size: 2.5em;
        font-weight: 700;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    p.contact-txt-p {
        width: 600px;
        font-size: 1.2em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;;
    }
    
    form.contact-form {
        width: 40%;
        margin: 0 auto;
    }
    
    .contact-input {
        background-color: white;
        margin-bottom: 20px;
    }

    p.contact-accept-privacy {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    
    button.contact-submit-btn {
        background-color: #007bff;
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        width: 100%;
    }
    
    button.contact-submit-btn:hover {
        background-color: #0065d1;
    }
}