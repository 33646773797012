@media (max-width: 575.98px) {
    div.menuupload-modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #ffffff;
        z-index: 10000;
        overflow: auto;
    }
    
    div.menuupload-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.menuupload-modal-container {
        width: 700px;
        min-height: 500px;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.menuupload-modal-top-bar-area {
        width: 100%;
        height: 50px;
    }
    
    div.menuupload-modal-title-area-div {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h3.menuupload-modal-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    div.menuupload-modal-close-area-div {
        width: 100px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn:hover {
        background-color:rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-modal-close-icon-span {
        font-size: 28px;
    }
    
    div.menuupload-modal-content-area {
        width: 100%;
        min-height: 50px;
        overflow: hidden;
    }
    
    div.menuupload-modal-upload-area {
        width: 100%;
        height: 400px;
        background-color: #f2f2f2;
        border-radius: 30px;
        margin-top: 20px;
    }
    
    .select-menutype {
        margin-top: 20px;
    }
    
    div.menuupload-modal-controls-area {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.menuupload-modal-submit-btn {
        height: 50px;
        background-color: #005eff;
        color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.menuupload-modal-submit-btn:hover {
        background-color: #0048c6;
    }
    
    button.menuupload-modal-cancel-btn {
        height: 50px;
        color: black;
        background-color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
        margin-right: 10px;
    }
    
    button.menuupload-modal-cancel-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .dropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }
    
    .dropzone.active {
        border-color: #0000ff;
    }
    
    button.menuupload-close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-close-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-close-icon-span {
        font-size: 25px;
    }
    
    div.menu-upload-drop-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    p.menu-upload-drop-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    div.menu-upload-add-file-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    span.menu-upload-file-icon-span {
        font-size: 100px;
        color: #4979ff;
    }
    
    p.menu-upload-add-file-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    a.files-browse-a {
        font-family: 'SF Pro Display', sans-serif;
        color: #005eff;
        text-decoration: underline dashed;
        cursor: pointer;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.menuupload-modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #ffffff;
        z-index: 10000;
        overflow: auto;
    }
    
    div.menuupload-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.menuupload-modal-container {
        width: 700px;
        min-height: 500px;
        background-color: white;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.menuupload-modal-top-bar-area {
        width: 100%;
        height: 50px;
    }
    
    div.menuupload-modal-title-area-div {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h3.menuupload-modal-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    div.menuupload-modal-close-area-div {
        width: 100px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn:hover {
        background-color:rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-modal-close-icon-span {
        font-size: 28px;
    }
    
    div.menuupload-modal-content-area {
        width: 100%;
        min-height: 50px;
        overflow: hidden;
    }
    
    div.menuupload-modal-upload-area {
        width: 100%;
        height: 400px;
        background-color: #f2f2f2;
        border-radius: 30px;
        margin-top: 20px;
    }
    
    .select-menutype {
        margin-top: 20px;
    }
    
    div.menuupload-modal-controls-area {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.menuupload-modal-submit-btn {
        height: 50px;
        background-color: #005eff;
        color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.menuupload-modal-submit-btn:hover {
        background-color: #0048c6;
    }
    
    button.menuupload-modal-cancel-btn {
        height: 50px;
        color: black;
        background-color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
        margin-right: 10px;
    }
    
    button.menuupload-modal-cancel-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .dropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }
    
    .dropzone.active {
        border-color: #0000ff;
    }
    
    button.menuupload-close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-close-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-close-icon-span {
        font-size: 25px;
    }
    
    div.menu-upload-drop-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    p.menu-upload-drop-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    div.menu-upload-add-file-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    span.menu-upload-file-icon-span {
        font-size: 100px;
        color: #4979ff;
    }
    
    p.menu-upload-add-file-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    a.files-browse-a {
        font-family: 'SF Pro Display', sans-serif;
        color: #005eff;
        text-decoration: underline dashed;
        cursor: pointer;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.menuupload-modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10000;
        overflow: auto;
    }
    
    div.menuupload-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.menuupload-modal-container {
        width: 700px;
        min-height: 500px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        margin: 50px 0;
    }
    
    div.menuupload-modal-top-bar-area {
        width: 100%;
        height: 50px;
    }
    
    div.menuupload-modal-title-area-div {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h3.menuupload-modal-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    div.menuupload-modal-close-area-div {
        width: 100px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn:hover {
        background-color:rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-modal-close-icon-span {
        font-size: 28px;
    }
    
    div.menuupload-modal-content-area {
        width: 100%;
        min-height: 50px;
        overflow: hidden;
    }
    
    div.menuupload-modal-upload-area {
        width: 100%;
        height: 400px;
        background-color: #f2f2f2;
        border-radius: 30px;
        margin-top: 20px;
    }
    
    .select-menutype {
        margin-top: 20px;
    }
    
    div.menuupload-modal-controls-area {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.menuupload-modal-submit-btn {
        height: 50px;
        background-color: #005eff;
        color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.menuupload-modal-submit-btn:hover {
        background-color: #0048c6;
    }
    
    button.menuupload-modal-cancel-btn {
        height: 50px;
        color: black;
        background-color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
        margin-right: 10px;
    }
    
    button.menuupload-modal-cancel-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .dropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }
    
    .dropzone.active {
        border-color: #0000ff;
    }
    
    button.menuupload-close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-close-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-close-icon-span {
        font-size: 25px;
    }
    
    div.menu-upload-drop-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    p.menu-upload-drop-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    div.menu-upload-add-file-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    span.menu-upload-file-icon-span {
        font-size: 100px;
        color: #4979ff;
    }
    
    p.menu-upload-add-file-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    a.files-browse-a {
        font-family: 'SF Pro Display', sans-serif;
        color: #005eff;
        text-decoration: underline dashed;
        cursor: pointer;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.menuupload-modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10000;
        overflow: auto;
    }
    
    div.menuupload-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.menuupload-modal-container {
        width: 700px;
        min-height: 500px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        margin: 50px 0;
    }
    
    div.menuupload-modal-top-bar-area {
        width: 100%;
        height: 50px;
    }
    
    div.menuupload-modal-title-area-div {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h3.menuupload-modal-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    div.menuupload-modal-close-area-div {
        width: 100px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn:hover {
        background-color:rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-modal-close-icon-span {
        font-size: 28px;
    }
    
    div.menuupload-modal-content-area {
        width: 100%;
        min-height: 50px;
        overflow: hidden;
    }
    
    div.menuupload-modal-upload-area {
        width: 100%;
        height: 400px;
        background-color: #f2f2f2;
        border-radius: 30px;
        margin-top: 20px;
    }
    
    .select-menutype {
        margin-top: 20px;
    }
    
    div.menuupload-modal-controls-area {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.menuupload-modal-submit-btn {
        height: 50px;
        background-color: #005eff;
        color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.menuupload-modal-submit-btn:hover {
        background-color: #0048c6;
    }
    
    button.menuupload-modal-cancel-btn {
        height: 50px;
        color: black;
        background-color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
        margin-right: 10px;
    }
    
    button.menuupload-modal-cancel-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .dropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }
    
    .dropzone.active {
        border-color: #0000ff;
    }
    
    button.menuupload-close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-close-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-close-icon-span {
        font-size: 25px;
    }
    
    div.menu-upload-drop-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    p.menu-upload-drop-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    div.menu-upload-add-file-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    span.menu-upload-file-icon-span {
        font-size: 100px;
        color: #4979ff;
    }
    
    p.menu-upload-add-file-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    a.files-browse-a {
        font-family: 'SF Pro Display', sans-serif;
        color: #005eff;
        text-decoration: underline dashed;
        cursor: pointer;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.menuupload-modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10000;
        overflow: auto;
    }
    
    div.menuupload-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.menuupload-modal-container {
        width: 700px;
        min-height: 500px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        margin: 50px 0;
    }
    
    div.menuupload-modal-top-bar-area {
        width: 100%;
        height: 50px;
    }
    
    div.menuupload-modal-title-area-div {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h3.menuupload-modal-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    div.menuupload-modal-close-area-div {
        width: 100px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn:hover {
        background-color:rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-modal-close-icon-span {
        font-size: 28px;
    }
    
    div.menuupload-modal-content-area {
        width: 100%;
        min-height: 50px;
        overflow: hidden;
    }
    
    div.menuupload-modal-upload-area {
        width: 100%;
        height: 400px;
        background-color: #f2f2f2;
        border-radius: 30px;
        margin-top: 20px;
    }
    
    .select-menutype {
        margin-top: 20px;
    }
    
    div.menuupload-modal-controls-area {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.menuupload-modal-submit-btn {
        height: 50px;
        background-color: #005eff;
        color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.menuupload-modal-submit-btn:hover {
        background-color: #0048c6;
    }
    
    button.menuupload-modal-cancel-btn {
        height: 50px;
        color: black;
        background-color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
        margin-right: 10px;
    }
    
    button.menuupload-modal-cancel-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .dropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }
    
    .dropzone.active {
        border-color: #0000ff;
    }
    
    button.menuupload-close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-close-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-close-icon-span {
        font-size: 25px;
    }
    
    div.menu-upload-drop-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    p.menu-upload-drop-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    div.menu-upload-add-file-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    span.menu-upload-file-icon-span {
        font-size: 100px;
        color: #4979ff;
    }
    
    p.menu-upload-add-file-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    a.files-browse-a {
        font-family: 'SF Pro Display', sans-serif;
        color: #005eff;
        text-decoration: underline dashed;
        cursor: pointer;
    }
}

@media (min-width: 1450px) {
    div.menuupload-modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 10000;
        overflow: auto;
    }
    
    div.menuupload-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.menuupload-modal-container {
        width: 700px;
        min-height: 500px;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        margin: 50px 0;
    }
    
    div.menuupload-modal-top-bar-area {
        width: 100%;
        height: 50px;
    }
    
    div.menuupload-modal-title-area-div {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h3.menuupload-modal-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    div.menuupload-modal-close-area-div {
        width: 100px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-modal-close-btn:hover {
        background-color:rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-modal-close-icon-span {
        font-size: 28px;
    }
    
    div.menuupload-modal-content-area {
        width: 100%;
        min-height: 50px;
        overflow: hidden;
    }
    
    div.menuupload-modal-upload-area {
        width: 100%;
        height: 400px;
        background-color: #f2f2f2;
        border-radius: 30px;
        margin-top: 20px;
    }
    
    .select-menutype {
        margin-top: 20px;
    }
    
    div.menuupload-modal-controls-area {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.menuupload-modal-submit-btn {
        height: 50px;
        background-color: #005eff;
        color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.menuupload-modal-submit-btn:hover {
        background-color: #0048c6;
    }
    
    button.menuupload-modal-cancel-btn {
        height: 50px;
        color: black;
        background-color: white;
        border-radius: 10px;
        padding: 0 20px;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
        margin-right: 10px;
    }
    
    button.menuupload-modal-cancel-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .dropzone {
        border: 2px dashed #cccccc;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        position: relative;
    }
    
    .dropzone.active {
        border-color: #0000ff;
    }
    
    button.menuupload-close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.menuupload-close-button:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    span.menuupload-close-icon-span {
        font-size: 25px;
    }
    
    div.menu-upload-drop-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    p.menu-upload-drop-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    div.menu-upload-add-file-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    span.menu-upload-file-icon-span {
        font-size: 100px;
        color: #4979ff;
    }
    
    p.menu-upload-add-file-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        margin: 0;
    }
    
    a.files-browse-a {
        font-family: 'SF Pro Display', sans-serif;
        color: #005eff;
        text-decoration: underline dashed;
        cursor: pointer;
    }
}