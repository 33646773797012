@media (max-width: 575.98px) {
    div#notes-list-container-div {
        width: 100%;
    }
    
    div#notes-list-title-area-div {
        width: 100%;
        height: 50px;
    }
    
    div#notes-list-inner-title-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2#notes-list-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: bold;
    }
    
    button#notes-list-add-note-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button#notes-list-add-note-btn:hover {
        background-color: #333;
    }
    
    span.notes-list-add-icon-span {
        color: white;
    }
    
    ul#notes-list-listing-ul {
        width: 100%;
        height: calc(100% - 50px);
        margin: 0;
        list-style-type: none;
        overflow: auto;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#notes-list-container-div {
        width: 100%;
    }
    
    div#notes-list-title-area-div {
        width: 100%;
        height: 50px;
    }
    
    div#notes-list-inner-title-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2#notes-list-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: bold;
    }
    
    button#notes-list-add-note-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    span.notes-list-add-icon-span {
        color: white;
    }
    
    ul#notes-list-listing-ul {
        width: 100%;
        height: calc(100% - 50px);
        margin: 0;
        list-style-type: none;
        overflow: auto;
    }
    
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#notes-list-container-div {
        width: 100%;
    }
    
    div#notes-list-title-area-div {
        width: 100%;
        height: 50px;
    }
    
    div#notes-list-inner-title-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2#notes-list-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: bold;
    }
    
    button#notes-list-add-note-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    span.notes-list-add-icon-span {
        color: white;
    }
    
    ul#notes-list-listing-ul {
        width: 100%;
        height: calc(100% - 50px);
        margin: 0;
        list-style-type: none;
        overflow: auto;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#notes-list-container-div {
        width: 100%;
    }
    
    div#notes-list-title-area-div {
        width: 100%;
        height: 50px;
    }
    
    div#notes-list-inner-title-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2#notes-list-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: bold;
    }
    
    button#notes-list-add-note-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    span.notes-list-add-icon-span {
        color: white;
    }
    
    ul#notes-list-listing-ul {
        width: 100%;
        height: calc(100% - 50px);
        margin: 0;
        list-style-type: none;
        overflow: auto;
    }

}

@media (min-width: 1200px) {
    div#notes-list-container-div {
        width: 100%;
        height: 465px;
        padding: 20px 0;
    }
    
    div#notes-list-title-area-div {
        width: 100%;
        height: 50px;
    }
    
    div#notes-list-inner-title-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2#notes-list-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: bold;
    }
    
    button#notes-list-add-note-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    span.notes-list-add-icon-span {
        color: white;
    }
    
    ul#notes-list-listing-ul {
        width: 100%;
        height: calc(100% - 50px);
        margin: 0;
        list-style-type: none;
        overflow: auto;
    }

    
    
}