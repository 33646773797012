@media (max-width: 575.98px) {
    div.unsubscribe-email-view {
        width: 100%;
        height: 100vh;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    div.unsubscribe-inner-view {
        width: 90%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    h1.unsubscribe-title {
        font-size: 1.3em;
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 1;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
        line-height: 1;
    }
    
    div.unsubscribe-inner-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    button.unsubscribe-btn {
        width: 100%;
        height: 50px;
        background-color: #ff0000;
        border: none;
        border-radius: 10px;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.unsubscribe-btn:hover {
        background-color: #ff3333;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.unsubscribe-email-view {
        width: 100%;
        height: 100vh;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    div.unsubscribe-inner-view {
        width: 90%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    h1.unsubscribe-title {
        font-size: 1.3em;
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 1;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
        line-height: 1;
    }
    
    div.unsubscribe-inner-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    button.unsubscribe-btn {
        width: 100%;
        height: 50px;
        background-color: #ff0000;
        border: none;
        border-radius: 10px;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.unsubscribe-btn:hover {
        background-color: #ff3333;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.unsubscribe-email-view {
        width: 100%;
        height: 100vh;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    div.unsubscribe-inner-view {
        width: 70%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    h1.unsubscribe-title {
        font-size: 2em;
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-inner-block {
        width: 500px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    button.unsubscribe-btn {
        width: 100%;
        height: 50px;
        background-color: #ff0000;
        border: none;
        border-radius: 10px;
        font-size: 1.2em;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.unsubscribe-btn:hover {
        background-color: #ff3333;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.unsubscribe-email-view {
        width: 100%;
        height: 100vh;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    div.unsubscribe-inner-view {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    h1.unsubscribe-title {
        font-size: 2em;
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-inner-block {
        width: 500px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    button.unsubscribe-btn {
        width: 100%;
        height: 50px;
        background-color: #ff0000;
        border: none;
        border-radius: 10px;
        font-size: 1.2em;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.unsubscribe-btn:hover {
        background-color: #ff3333;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.unsubscribe-email-view {
        width: 100%;
        height: 100vh;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    div.unsubscribe-inner-view {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    h1.unsubscribe-title {
        font-size: 2em;
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-inner-block {
        width: 500px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    button.unsubscribe-btn {
        width: 100%;
        height: 50px;
        background-color: #ff0000;
        border: none;
        border-radius: 10px;
        font-size: 1.2em;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.unsubscribe-btn:hover {
        background-color: #ff3333;
    }
}

@media (min-width: 1450px) {
    div.unsubscribe-email-view {
        width: 100%;
        height: 100vh;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    div.unsubscribe-inner-view {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    h1.unsubscribe-title {
        font-size: 2em;
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-inner-block {
        width: 500px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
    }
    
    p.unsubscribe-txt-p {
        font-size: 1.2em;
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    div.unsubscribe-btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    
    button.unsubscribe-btn {
        width: 100%;
        height: 50px;
        background-color: #ff0000;
        border: none;
        border-radius: 10px;
        font-size: 1.2em;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.unsubscribe-btn:hover {
        background-color: #ff3333;
    }
}