@media (max-width: 575.98px) {
    div.de-briefing-recap-step {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.de-briefing-recap-step {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.de-briefing-recap-step {
        width: 90%;
        margin: 0 auto;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.de-briefing-recap-step {
        width: 70%;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.de-briefing-recap-step {
        width: 70%;
        margin: 0 auto;
    }
}

@media (min-width: 1450px) {
    div.de-briefing-recap-step {
        width: 70%;
        margin: 0 auto;
    }

    .de-briefing-recap-textarea {
        font-size: 18px !important;
    }
}