div.subsidiary-logo-inner-wrapper {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.subsidiary-logo-profile-div {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border: 1px solid #d0d0d0;
    margin-top: 50px;
    position: relative;
}

div.subsidiary-logo-change-div {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.subsidiary-logo-change-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

button.subsidiary-logo-change-btn {
    width: 30px;
    height: 30px;
    background-color: rgb(0, 94, 255);
    position: absolute;
    bottom: 10px;
    right: 0;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.subsidiary-logo-change-btn:hover {
    background-color: rgb(0, 66, 181);
}

span.subsidiary-logo-change-icon-span {
    color: white;
    font-size: 20px;
}

div.subsidiary-logo-content-div {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    overflow: hidden;
}

img.subsidiary-logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

p.subsidiary-no-logo-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 80px;
    line-height: 150px;
    text-align: center;
    font-weight: bold;
}