@media (max-width: 575.98px) {
    div.note-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    div.note-wrapper {
        width: 100%;
        height: 100%;
    }
    
    div.note-content {
        background-color: rgb(234, 211, 87);
        padding: 20px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        position: relative;
        height: 100%;
    }
    
    div.note-top-bar {
        width: 100%;
        height: 40px;
        display: flex;
    }

    p.note-title-txt-p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-top: 5px;
        flex-grow: 1;
        float: left;
    }
    
    button.note-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
        flex-grow: 0;
    }
    
    button.note-close-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    textarea.note-textarea {
        width: 100%;
        height: calc(100% - 40px);
        border: none;
        background-color: transparent;
        resize: none;
        padding-top: 40px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    textarea.note-textarea:focus {
        outline: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.note-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    div.note-wrapper {
        width: 100%;
        height: 100%;
    }
    
    div.note-content {
        background-color: rgb(234, 211, 87);
        padding: 20px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        position: relative;
        height: 100%;
    }

    div.note-top-bar {
        width: 100%;
        height: 40px;
        display: flex;
    }

    p.note-title-txt-p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-top: 5px;
        flex-grow: 1;
        float: left;
    }
    
    button.note-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.note-close-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    textarea.note-textarea {
        width: 100%;
        height: calc(100% - 40px);
        border: none;
        background-color: transparent;
        resize: none;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    textarea.note-textarea:focus {
        outline: none;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.note-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    div.note-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.note-content {
        background-color: rgb(234, 211, 87);
        padding: 20px;
        border-radius: 10px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
        min-height: 300px;
    }

    div.note-top-bar {
        width: 100%;
        height: 40px;
        display: flex;
    }

    p.note-title-txt-p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-top: 5px;
        flex-grow: 1;
        float: left;
    }
    
    button.note-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.note-close-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    textarea.note-textarea {
        width: 100%;
        min-height: inherit;
        border: none;
        background-color: transparent;
        resize: none;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    textarea.note-textarea:focus {
        outline: none;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.note-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    div.note-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.note-content {
        background-color: rgb(234, 211, 87);
        padding: 20px;
        border-radius: 10px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
        min-height: 300px;
    }

    div.note-top-bar {
        width: 100%;
        height: 40px;
        display: flex;
    }

    p.note-title-txt-p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-top: 5px;
        flex-grow: 1;
        float: left;
    }
    
    button.note-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.note-close-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    textarea.note-textarea {
        width: 100%;
        min-height: inherit;
        border: none;
        background-color: transparent;
        resize: none;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    textarea.note-textarea:focus {
        outline: none;
    }
}

@media (min-width: 1200px) {
    div.note-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1001;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    div.note-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.note-content {
        background-color: rgb(234, 211, 87);
        padding: 20px;
        border-radius: 10px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
        min-height: 300px;
    }

    div.note-top-bar {
        width: 100%;
        height: 40px;
        display: flex;
    }

    p.note-title-txt-p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-top: 5px;
        flex-grow: 1;
        float: left;
    }
    
    button.note-close-btn {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.note-close-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    textarea.note-textarea {
        width: 100%;
        min-height: inherit;
        border: none;
        background-color: transparent;
        resize: none;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    textarea.note-textarea:focus {
        outline: none;
    }
}

div.note-content.note-type-1 {
    background-color: rgb(0, 208, 17);
}

div.note-content.note-type-2 {
    background-color: rgb(227, 121, 0);
}

div.note-content.note-type-3 {
    background-color: rgb(226, 211, 0);
}