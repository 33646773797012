/* Overlay Style */
div.add-event-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

/* Wrapper Style */
div.add-event-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Container Style */
div.add-event-modal-container {
    width: 100%;
    max-width: 800px; /* Gebruik max-width voor responsiviteit */
    min-height: 100px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box; /* Zorg ervoor dat padding niet bij de breedte wordt opgeteld */
}

/* Top Area Style */
div.add-event-modal-top-area {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd; /* Voeg een subtiele scheidinglijn toe voor visuele scheiding */
}

/* Title Area Style */
div.add-event-modal-top-inner-title-area {
    flex: 1; /* Zorg ervoor dat de titel de beschikbare ruimte gebruikt */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* Title Style */
h2.add-event-modal-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 0;
    padding: 0;
}

/* Close Button Area Style */
div.add-event-modal-top-inner-close-area {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* Close Button Style */
button.add-event-modal-close-btn {
    width: 40px;
    height: 40px;
    background-color: white;
    border: none;
    font-weight: bold;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer; /* Voeg een cursor toe om aan te geven dat het klikbaar is */
    transition: background-color 0.3s; /* Voeg een overgang toe voor de hover effect */
}

button.add-event-modal-close-btn:hover {
    background-color: #efefef;
}

button.add-event-modal-close-btn span {
    color: black;
    font-size: 30px;
}

/* Content Style */
div.add-event-modal-content {
    width: 100%;
}

/* Controls Area Style */
div.add-event-modal-controls-area {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 10px; /* Voeg ruimte toe tussen knoppen */
    margin-top: 20px;
}

/* Add Button Style */
button.add-event-modal-add-btn {
    height: 40px;
    background-color: rgb(0, 110, 255);
    color: white;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    cursor: pointer; /* Voeg een cursor toe om aan te geven dat het klikbaar is */
    transition: background-color 0.3s; /* Voeg een overgang toe voor de hover effect */
}

button.add-event-modal-add-btn:hover {
    background-color: rgb(0, 94, 255);
}

/* Cancel Button Style */
button.add-event-modal-cancel-btn {
    height: 40px;
    background-color: white;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    cursor: pointer; /* Voeg een cursor toe om aan te geven dat het klikbaar is */
    transition: background-color 0.3s; /* Voeg een overgang toe voor de hover effect */
}

button.add-event-modal-cancel-btn:hover {
    background-color: #efefef;
}
