
@font-face {
  font-family: 'SF Pro Display';
  src: url('assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('assets/fonts/SFProDisplay-Regular.woff') format('woff'),
      url('assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
      url('assets/fonts/SFProDisplay-Bold.woff') format('woff'),
      url('assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  height: 100dvh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow:hidden;
}

div#root {
  height: 100%;
  overflow: hidden;
}