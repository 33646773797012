@media (max-width: 575.98px) {
    nav#settings-nav-bar {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 15px;
        position: absolute;
        z-index: 100;
        background-color: white;
    }

    nav#settings-nav-bar.mobile-menu-closed {
        display: none;
    }

    ul.settings-nav-bar-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-nav-bar-li {
        width: 100%;
        height: 50px;
        list-style-type: none;
        margin-bottom: 5px;
    }

    p.settings-title-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        font-size: 18px;
        line-height: 50px;
        font-weight: bold;
    }

    button.settings-nav-btn {
        width: 100%;
        height: 50px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-content: center;
        padding: 0 10px;
    }

    button.settings-nav-btn:hover {
        background-color: #5555552a;
    }

    span.settings-nav-icon-span {
        float: left;
        font-size: 30px;
        vertical-align: middle;
        line-height: 50px;
    }

    span.settings-nav-txt-span {
        float: left;
        font-size: 16px;
        line-height: 50px;
        vertical-align: middle;
        margin-left: 15px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    nav#settings-nav-bar {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 15px;
        position: absolute;
        z-index: 100;
        background-color: white;
    }

    nav#settings-nav-bar.mobile-menu-closed {
        display: none;
    }

    ul.settings-nav-bar-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-nav-bar-li {
        width: 100%;
        height: 50px;
        list-style-type: none;
        margin-bottom: 5px;
    }

    p.settings-title-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        font-size: 18px;
        line-height: 50px;
        font-weight: bold;
    }

    button.settings-nav-btn {
        width: 100%;
        height: 50px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-content: center;
        padding: 0 10px;
    }

    button.settings-nav-btn:hover {
        background-color: #5555552a;
    }

    span.settings-nav-icon-span {
        float: left;
        font-size: 30px;
        vertical-align: middle;
        line-height: 50px;
    }

    span.settings-nav-txt-span {
        float: left;
        font-size: 16px;
        line-height: 50px;
        vertical-align: middle;
        margin-left: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    nav#settings-nav-bar {
        width: 100%;
        height: 100%;
        float: left;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 15px;
        position: absolute;
        z-index: 100;
        background-color: white;
    }

    nav#settings-nav-bar.mobile-menu-closed {
        display: none;
    }

    ul.settings-nav-bar-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-nav-bar-li {
        width: 100%;
        height: 50px;
        list-style-type: none;
        margin-bottom: 5px;
    }

    p.settings-title-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        font-size: 18px;
        line-height: 50px;
        font-weight: bold;
    }

    button.settings-nav-btn {
        width: 100%;
        height: 50px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-content: center;
        padding: 0 10px;
    }

    button.settings-nav-btn:hover {
        background-color: #5555552a;
    }

    span.settings-nav-icon-span {
        float: left;
        font-size: 30px;
        vertical-align: middle;
        line-height: 50px;
    }

    span.settings-nav-txt-span {
        float: left;
        font-size: 16px;
        line-height: 50px;
        vertical-align: middle;
        margin-left: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    nav#settings-nav-bar {
        width: 300px;
        height: 100%;
        float: left;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 1px solid #d0d0d0;
        padding: 15px;
    }

    ul.settings-nav-bar-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-nav-bar-li {
        width: 100%;
        height: 50px;
        list-style-type: none;
        margin-bottom: 5px;
    }

    p.settings-title-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        font-size: 18px;
        line-height: 50px;
        font-weight: bold;
    }

    button.settings-nav-btn {
        width: 100%;
        height: 50px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-content: center;
        padding: 0 10px;
    }

    button.settings-nav-btn:hover {
        background-color: #5555552a;
    }

    span.settings-nav-icon-span {
        float: left;
        font-size: 30px;
        vertical-align: middle;
        line-height: 50px;
    }

    span.settings-nav-txt-span {
        float: left;
        font-size: 16px;
        line-height: 50px;
        vertical-align: middle;
        margin-left: 15px;
    }
}

@media (min-width: 1200px) {
    nav#settings-nav-bar {
        width: 300px;
        height: 100%;
        float: left;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: 1px solid #d0d0d0;
        padding: 15px;
    }

    ul.settings-nav-bar-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-nav-bar-li {
        width: 100%;
        height: 50px;
        list-style-type: none;
        margin-bottom: 5px;
    }

    p.settings-title-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        font-size: 18px;
        line-height: 50px;
        font-weight: bold;
    }

    button.settings-nav-btn {
        width: 100%;
        height: 50px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-content: center;
        padding: 0 10px;
    }

    button.settings-nav-btn:hover {
        background-color: #5555552a;
    }

    span.settings-nav-icon-span {
        float: left;
        font-size: 30px;
        vertical-align: middle;
        line-height: 50px;
    }

    span.settings-nav-txt-span {
        float: left;
        font-size: 16px;
        line-height: 50px;
        vertical-align: middle;
        margin-left: 15px;
    }
}