li.menu-upload-content-list-item-li {
    width: 100%;
    background-color: white;
    padding: 10px 20px;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
}

div.menu-upload-content-fav-area {
    width: 50px;
    float: left;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-shrink: 0;
}

button.menu-upload-content-fav-btn {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    border-radius: 50px;
}

button.menu-upload-content-fav-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

div.menu-upload-content-list-right-area {
    height: 100%;
    float: left;
    flex-grow: 1;
}

p.menu-upload-content-list-item-p {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: bold;
    margin: 0;
    color: black;
    font-size: 16px;
}

p.menu-upload-content-list-desc-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    margin: 0;
    color: rgb(97, 97, 97);
    font-size: 14px;
}

div.menu-upload-content-price-area {
    float: left;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-shrink: 0;
    padding-left: 10px;
}

p.menu-upload-content-list-price-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    margin: 0;
    color: black;
    font-size: 18px;
}