div.note-item-debriefing {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    list-style-type: none;
    overflow: hidden;
}

textarea#note-item-textarea {
    width: 100%;
    min-height: 200px;
    resize: none;
    background-color: transparent;
    border: none;
    padding: 20px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    box-sizing: border-box;
}

textarea#note-item-textarea:focus {
    outline: none;
}

.note-type-1 {
    background-color: rgb(0, 208, 17);
}

.note-type-2 {
    background-color: rgb(227, 121, 0);
}

.note-type-3 {
    background-color: rgb(226, 211, 0);
}