@media (max-width: 575.98px) {
    div.day-reports-overview-list {
        width: 100%;
        margin: 0 auto;
    }
    
    div.day-reports-overview-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 10px 10px;
        margin: 0 10px;
        margin-bottom: 20px;

    }
    
    h1.day-reports-overview-title-h1 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    ul.day-reports-overview-listing-ul {
        list-style-type: none;
        padding: 0;
    }
    
    li.day-report-item-li {
        list-style-type: none;
        margin: 10px 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.day-report-date-item {
        min-width: 50px;
        background-color: white;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }
    
    h5.day-report-date-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    div.day-report-item-div {
        width: 100%;
        min-height: 100px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.day-report-top-view {
        width: 100%;
    }
    
    div.day-report-top-view-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    h3.day-report-shift-type-h3 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    
    div.day-report-shift-grade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    div.day-report-info-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    div.day-report-info-item {
        margin: 10px 0;
    }
    
    h5.day-report-info-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    p.day-report-info-p {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
    }
    
    div.day-report-controls-area {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
        gap: 20px;
    }
    
    button.day-report-controls-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 50px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.day-report-controls-btn:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.day-reports-overview-list {
        width: 100%;
        margin: 0 auto;
    }
    
    div.day-reports-overview-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 10px 10px;
        margin: 0 10px;
        margin-bottom: 20px;

    }
    
    h1.day-reports-overview-title-h1 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    ul.day-reports-overview-listing-ul {
        list-style-type: none;
        padding: 0;
    }
    
    li.day-report-item-li {
        list-style-type: none;
        margin: 10px 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.day-report-date-item {
        min-width: 50px;
        background-color: white;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }
    
    h5.day-report-date-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    div.day-report-item-div {
        width: 100%;
        min-height: 100px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.day-report-top-view {
        width: 100%;
    }
    
    div.day-report-top-view-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    h3.day-report-shift-type-h3 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    
    div.day-report-shift-grade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    div.day-report-info-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    div.day-report-info-item {
        margin: 10px 0;
    }
    
    h5.day-report-info-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    p.day-report-info-p {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
    }
    
    div.day-report-controls-area {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
        gap: 20px;
    }
    
    button.day-report-controls-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 50px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.day-report-controls-btn:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.day-reports-overview-list {
        width: 80%;
        margin: 0 auto;
        max-width: 900px;
    }
    
    div.day-reports-overview-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
    }
    
    h1.day-reports-overview-title-h1 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    ul.day-reports-overview-listing-ul {
        list-style-type: none;
        padding: 0;
    }
    
    li.day-report-item-li {
        list-style-type: none;
        margin: 10px 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.day-report-date-item {
        min-width: 50px;
        background-color: white;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }
    
    h5.day-report-date-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    div.day-report-item-div {
        width: 100%;
        min-height: 100px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.day-report-top-view {
        width: 100%;
    }
    
    div.day-report-top-view-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    h3.day-report-shift-type-h3 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    
    div.day-report-shift-grade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    div.day-report-info-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    div.day-report-info-item {
        margin: 10px 0;
    }
    
    h5.day-report-info-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    p.day-report-info-p {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
    }
    
    div.day-report-controls-area {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
        gap: 20px;
    }
    
    button.day-report-controls-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 50px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.day-report-controls-btn:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.day-reports-overview-list {
        width: 80%;
        margin: 0 auto;
        max-width: 900px;
    }
    
    div.day-reports-overview-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
    }
    
    h1.day-reports-overview-title-h1 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    ul.day-reports-overview-listing-ul {
        list-style-type: none;
        padding: 0;
    }
    
    li.day-report-item-li {
        list-style-type: none;
        margin: 10px 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.day-report-date-item {
        min-width: 50px;
        background-color: white;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }
    
    h5.day-report-date-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    div.day-report-item-div {
        width: 100%;
        min-height: 100px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.day-report-top-view {
        width: 100%;
    }
    
    div.day-report-top-view-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    h3.day-report-shift-type-h3 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    
    div.day-report-shift-grade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    div.day-report-info-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    div.day-report-info-item {
        margin: 10px 0;
    }
    
    h5.day-report-info-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    p.day-report-info-p {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
    }
    
    div.day-report-controls-area {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
        gap: 20px;
    }
    
    button.day-report-controls-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 50px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.day-report-controls-btn:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.day-reports-overview-list {
        width: 80%;
        margin: 0 auto;
        max-width: 900px;
    }
    
    div.day-reports-overview-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
    }
    
    h1.day-reports-overview-title-h1 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    ul.day-reports-overview-listing-ul {
        list-style-type: none;
        padding: 0;
    }
    
    li.day-report-item-li {
        list-style-type: none;
        margin: 10px 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.day-report-date-item {
        min-width: 50px;
        background-color: white;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }
    
    h5.day-report-date-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    div.day-report-item-div {
        width: 100%;
        min-height: 100px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.day-report-top-view {
        width: 100%;
    }
    
    div.day-report-top-view-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    h3.day-report-shift-type-h3 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    
    div.day-report-shift-grade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    div.day-report-info-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    div.day-report-info-item {
        margin: 10px 0;
    }
    
    h5.day-report-info-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    p.day-report-info-p {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
    }
    
    div.day-report-controls-area {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
        gap: 20px;
    }
    
    button.day-report-controls-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 50px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.day-report-controls-btn:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

@media (min-width: 1450px) {
    div.day-reports-overview-list {
        width: 80%;
        margin: 0 auto;
        max-width: 900px;
    }
    
    div.day-reports-overview-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
    }
    
    h1.day-reports-overview-title-h1 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    ul.day-reports-overview-listing-ul {
        list-style-type: none;
        padding: 0;
    }
    
    li.day-report-item-li {
        list-style-type: none;
        margin: 10px 0;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.day-report-date-item {
        min-width: 50px;
        background-color: white;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }
    
    h5.day-report-date-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    div.day-report-item-div {
        width: 100%;
        min-height: 100px;
        background-color: white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.day-report-top-view {
        width: 100%;
    }
    
    div.day-report-top-view-title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    h3.day-report-shift-type-h3 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
    }
    
    
    div.day-report-shift-grade-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background-color: white;
        border-radius: 50px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }
    
    div.day-report-info-area {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    div.day-report-info-item {
        margin: 10px 0;
    }
    
    h5.day-report-info-h5 {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
    }
    
    p.day-report-info-p {
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
    }
    
    div.day-report-controls-area {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
        gap: 20px;
    }
    
    button.day-report-controls-btn {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 50px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.day-report-controls-btn:hover {
        background-color: #0056b3;
        cursor: pointer;
    }
}

