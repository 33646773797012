div.add-thing-item {
    width: 100%;
    min-height: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

div.add-thing-item:hover {
    opacity: 0.85;
}

span.add-thing-icon-span {
    font-size: 50px;
    color: white;
}