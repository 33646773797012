@media (max-width: 575.98px) {
    div.pre-briefing-focuspoints-step {
        width: 100%;
        margin: 0 auto;
    }
    
    h2.pre-briefing-focuspoints-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    
    div.pre-briefing-focuspoints-selection-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.pre-briefing-focuspoints-step {
        width: 100%;
        margin: 0 auto;
    }
    
    h2.pre-briefing-focuspoints-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    
    div.pre-briefing-focuspoints-selection-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.pre-briefing-focuspoints-step {
        width: 100%;
        margin: 0 auto;
    }
    
    h2.pre-briefing-focuspoints-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.3em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    
    div.pre-briefing-focuspoints-selection-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.pre-briefing-focuspoints-step {
        width: 70%;
        margin: 0 auto;
    }
    
    h2.pre-briefing-focuspoints-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-focuspoints-selection-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.pre-briefing-focuspoints-step {
        width: 70%;
        margin: 0 auto;
    }
    
    h2.pre-briefing-focuspoints-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-focuspoints-selection-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
}

@media (min-width: 1450px) {
    div.pre-briefing-focuspoints-step {
        width: 50%;
        margin: 0 auto;
    }
    
    h2.pre-briefing-focuspoints-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-focuspoints-selection-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
}