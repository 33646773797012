@media (max-width: 575.98px) {
    nav.main-top-navbar-nav {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
    }

    ul#main-top-navbar-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
    }

    li#main-top-left-area {
        width: 60px;
        height: 100%;
        float: left;
        display: flex;
        padding-left: 10px;
    }

    li#main-top-center-area {
        height: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li#main-top-right-area {
        height: 100%;
        float: left;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 10px;
        padding-right: 10px;
    }

    div.main-top-searchbar-wrapper {
        width: 100%;
        height: 45px;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    input.main-top-searchbar-input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        color: #000000;

    }

    button.main-top-searchbar-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        float: right;
        color: #000000;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        padding: 0;
        margin: 0;
        top: 5px;
        right: 5px;
        vertical-align: middle;
    }

    button.main-top-searchbar-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-searchbar-icon-span {
        font-size: 25px;
        vertical-align: middle;
    }

    div.main-btn-wrapper {}

    button.main-top-btn-div {
        float: right;
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.main-top-btn-div:hover {
        background-color: #f0f0f0;

    }

    p.main-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 0;
        padding: 0;
        line-height: 45px;
        text-align: center;
    }

    div#main-user-dropdown-div {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        min-height: 50px;
        height: auto;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        z-index: 110;
    }

    div.main-user-dropdown-menu-section {
        width: 100%;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid lightgray;
    }

    img#main-user-menu-profile-img {
        width: 50px;
        height: 50px;
        float: left;
        border-radius: 10px;
    }

    div#main-user-dropdown-top-info-txt-area {
        width: calc(100% - 60px);
        height: 50px;
        float: left;
        padding-left: 10px;
    }

    p#main-user-menu-name-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    p#main-user-menu-email-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    ul.main-user-dropdown-menu-btns-listing-ul {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.main-user-dropdown-menu-btn-item-li {
        width: 100%;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        padding: 7.5px 10px;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-user-dropdown-menu-icon-span {
        font-size: 22px;
        float: left;
        vertical-align: middle;
    }

    p.main-user-dropdown-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        float: left;
        margin-left: 10px;
        line-height: 22px;
    }

    button.main-top-left-btn {
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.main-top-left-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-left-icon-span {
        font-size: 40px;
        vertical-align: middle;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    nav.main-top-navbar-nav {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    ul#main-top-navbar-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li#main-top-left-area {
        width: 200px;
        height: 100%;
        float: left;
        display: flex;
    }

    li#main-top-center-area {
        width: calc(100% - 400px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li#main-top-right-area {
        width: 200px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;
    }

    div.main-top-searchbar-wrapper {
        width: 100%;
        height: 45px;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    input.main-top-searchbar-input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        color: #000000;

    }

    button.main-top-searchbar-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        float: right;
        color: #000000;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        padding: 0;
        margin: 0;
        top: 5px;
        right: 5px;
        vertical-align: middle;
    }

    button.main-top-searchbar-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-searchbar-icon-span {
        font-size: 25px;
        vertical-align: middle;
    }

    div.main-btn-wrapper {}

    button.main-top-btn-div {
        float: right;
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.main-top-btn-div:hover {
        background-color: #f0f0f0;

    }

    p.main-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 0;
        padding: 0;
        line-height: 45px;
        text-align: center;
    }

    div#main-user-dropdown-div {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        min-height: 50px;
        height: auto;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        z-index: 110;
    }

    div.main-user-dropdown-menu-section {
        width: 100%;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid lightgray;
    }

    img#main-user-menu-profile-img {
        width: 50px;
        height: 50px;
        float: left;
        border-radius: 10px;
    }

    div#main-user-dropdown-top-info-txt-area {
        width: calc(100% - 60px);
        height: 50px;
        float: left;
        padding-left: 10px;
    }

    p#main-user-menu-name-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    p#main-user-menu-email-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    ul.main-user-dropdown-menu-btns-listing-ul {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.main-user-dropdown-menu-btn-item-li {
        width: 100%;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        padding: 7.5px 10px;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-user-dropdown-menu-icon-span {
        font-size: 22px;
        float: left;
        vertical-align: middle;
    }

    p.main-user-dropdown-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        float: left;
        margin-left: 10px;
        line-height: 22px;
    }

    button.main-top-left-btn {
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.main-top-left-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-left-icon-span {
        font-size: 40px;
        vertical-align: middle;
    }

    button.mobile-menu-btn {}
}

@media (min-width: 768px) and (max-width: 991.98px) {
    nav.main-top-navbar-nav {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    ul#main-top-navbar-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li#main-top-left-area {
        width: 200px;
        height: 100%;
        float: left;
        display: flex;
    }

    li#main-top-center-area {
        width: calc(100% - 400px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li#main-top-right-area {
        width: 200px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;
    }

    div.main-top-searchbar-wrapper {
        width: 100%;
        height: 45px;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    input.main-top-searchbar-input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        color: #000000;

    }

    button.main-top-searchbar-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        float: right;
        color: #000000;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        padding: 0;
        margin: 0;
        top: 5px;
        right: 5px;
        vertical-align: middle;
    }

    button.main-top-searchbar-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-searchbar-icon-span {
        font-size: 25px;
        vertical-align: middle;
    }

    div.main-btn-wrapper {}

    button.main-top-btn-div {
        float: right;
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.main-top-btn-div:hover {
        background-color: #f0f0f0;

    }

    p.main-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 0;
        padding: 0;
        line-height: 45px;
        text-align: center;
    }

    div#main-user-dropdown-div {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        min-height: 50px;
        height: auto;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        z-index: 110;
    }

    div.main-user-dropdown-menu-section {
        width: 100%;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid lightgray;
    }

    img#main-user-menu-profile-img {
        width: 50px;
        height: 50px;
        float: left;
        border-radius: 10px;
    }

    div#main-user-dropdown-top-info-txt-area {
        width: calc(100% - 60px);
        height: 50px;
        float: left;
        padding-left: 10px;
    }

    p#main-user-menu-name-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    p#main-user-menu-email-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    ul.main-user-dropdown-menu-btns-listing-ul {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.main-user-dropdown-menu-btn-item-li {
        width: 100%;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        padding: 7.5px 10px;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-user-dropdown-menu-icon-span {
        font-size: 22px;
        float: left;
        vertical-align: middle;
    }

    p.main-user-dropdown-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        float: left;
        margin-left: 10px;
        line-height: 22px;
    }

    button.main-top-left-btn {
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.main-top-left-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-left-icon-span {
        font-size: 40px;
        vertical-align: middle;
    }

    button.mobile-menu-btn {}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    nav.main-top-navbar-nav {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    ul#main-top-navbar-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li#main-top-left-area {
        width: 200px;
        height: 100%;
        float: left;
        display: flex;
    }

    li#main-top-center-area {
        width: calc(100% - 400px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li#main-top-right-area {
        width: 200px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;
    }

    div.main-top-searchbar-wrapper {
        width: 100%;
        height: 45px;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    input.main-top-searchbar-input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        color: #000000;

    }

    button.main-top-searchbar-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        float: right;
        color: #000000;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        padding: 0;
        margin: 0;
        top: 5px;
        right: 5px;
        vertical-align: middle;
    }

    button.main-top-searchbar-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-searchbar-icon-span {
        font-size: 25px;
        vertical-align: middle;
    }

    div.main-btn-wrapper {}

    button.main-top-btn-div {
        float: right;
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.main-top-btn-div:hover {
        background-color: #f0f0f0;

    }

    p.main-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 0;
        padding: 0;
        line-height: 45px;
        text-align: center;
    }

    div#main-user-dropdown-div {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        min-height: 50px;
        height: auto;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        z-index: 110;
    }

    div.main-user-dropdown-menu-section {
        width: 100%;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid lightgray;
    }

    img#main-user-menu-profile-img {
        width: 50px;
        height: 50px;
        float: left;
        border-radius: 10px;
    }

    div#main-user-dropdown-top-info-txt-area {
        width: calc(100% - 60px);
        height: 50px;
        float: left;
        padding-left: 10px;
    }

    p#main-user-menu-name-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    p#main-user-menu-email-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    ul.main-user-dropdown-menu-btns-listing-ul {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.main-user-dropdown-menu-btn-item-li {
        width: 100%;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        padding: 7.5px 10px;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-user-dropdown-menu-icon-span {
        font-size: 22px;
        float: left;
        vertical-align: middle;
    }

    p.main-user-dropdown-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        float: left;
        margin-left: 10px;
        line-height: 22px;
    }

    button.main-top-left-btn {
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.main-top-left-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-left-icon-span {
        font-size: 40px;
        vertical-align: middle;
    }

    button.mobile-menu-btn {}
}

@media (min-width: 1200px) {
    nav.main-top-navbar-nav {
        width: 100%;
        height: 60px;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    ul#main-top-navbar-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li#main-top-left-area {
        width: 400px;
        height: 100%;
        float: left;
    }

    li#main-top-center-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li#main-top-right-area {
        width: 400px;
        height: 100%;
        float: left;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 20px;
    }

    div.main-top-searchbar-wrapper {
        width: 100%;
        height: 45px;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    input.main-top-searchbar-input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        color: #000000;

    }

    button.main-top-searchbar-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        float: right;
        color: #000000;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        padding: 0;
        margin: 0;
        top: 5px;
        right: 5px;
        vertical-align: middle;
    }

    button.main-top-searchbar-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-searchbar-icon-span {
        font-size: 25px;
        vertical-align: middle;
    }

    div.main-btn-wrapper {
        position: relative;
    }

    button.main-top-btn-div {
        float: right;
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.main-top-btn-div:hover {
        background-color: #f0f0f0;

    }

    p.main-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 0;
        padding: 0;
        line-height: 45px;
        text-align: center;
    }

    div#main-user-dropdown-div {
        position: absolute;
        top: 60px;
        right: 0;
        width: 300px;
        min-height: 50px;
        height: auto;
        background-color: white;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        z-index: 110;
    }

    div.main-user-dropdown-menu-section {
        width: 100%;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid lightgray;
    }

    img#main-user-menu-profile-img {
        width: 50px;
        height: 50px;
        float: left;
        border-radius: 10px;
    }

    div#main-user-dropdown-top-info-txt-area {
        width: calc(100% - 60px);
        height: 50px;
        float: left;
        padding-left: 10px;
    }

    p#main-user-menu-name-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    p#main-user-menu-email-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    ul.main-user-dropdown-menu-btns-listing-ul {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.main-user-dropdown-menu-btn-item-li {
        width: 100%;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        padding: 7.5px 10px;
        box-sizing: border-box;
    }

    button.main-user-dropdown-menu-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-user-dropdown-menu-icon-span {
        font-size: 22px;
        float: left;
        vertical-align: middle;
    }

    p.main-user-dropdown-menu-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        float: left;
        margin-left: 10px;
        line-height: 22px;
    }

    button.main-top-left-btn {
        width: 45px;
        height: 45px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.main-top-left-btn:hover {
        background-color: #f0f0f0;
    }

    span.main-top-left-icon-span {
        font-size: 40px;
        vertical-align: middle;
    }

    button.mobile-menu-btn {
        display: none;
    }

    p.no-noted-found-txt-p {
        text-align: center;
        font-size: 18px;
        color: red;
    }

}