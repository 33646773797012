@media (max-width: 575.98px) {
    div.payment-method-input-div {
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        /* display: none; */
        overflow: hidden;
    }
    
    div.payment-method-input-div.active {
        min-height: 50px;
        height: auto;
        cursor: default;
    }
    
    div.payment-method-input-div.active label#payment-method-label {
        cursor: default;
    }
    
    label#payment-method-label {
        cursor: pointer;
    }
    
    div.payment-pay-method-inner-bar {
        width: 100%;
        height: 50px;
    }
    
    div.payment-pay-method-check-area {
        width: 40px;
        height: 100%;
        float: left;
    }
    
    .radio {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 14px;
    }
    
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .radio .radio-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid black;
    }
    
    .radio:hover .radio-btn {
        background-color: #ccc;
    }
    
    .radio input:checked+.radio-btn {
        background-color: #ffffff;
    }
    
    .radio .radio-btn:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .radio input:checked+.radio-btn:after {
        display: block;
    }
    
    .radio .radio-btn:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
    }
    
    div.payment-method-title-area {
        width: calc(100% - 40px);
        height: 100%;
        float: left;
    }
    
    p.payment-method-title-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        line-height: 50px;
        float: left;
    }
    
    img.payment-method-issuers-img {
        float: right;
        height: 25px;
        margin-top: 12.5px;
        margin-left: 5px;
    }
    
    div.payment-method-content-div {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }
    
    form.betaling-pay-method-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    ul.payment-method-issuers-lising-ul {
        width: 100%;
        min-height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: left;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    
    li.payment-method-issuer-item-li {
        height: 80px;
        outline: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        float: left;
        border: 1px solid transparent;
    }
    
    li.payment-method-issuer-item-li:nth-child(4n+1) {
        margin-left: 0;
    }
    
    li.payment-method-issuer-item-li.selected {
        border-color: rgb(0, 0, 0);
    }
    
    label.payment-method-issuer-label {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    img.payment-method-issuer-img {
        height: 30px;
        width: auto;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    
    p.payment-method-issuer-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
        user-select: none;
        font-size: 12px;
    }
    
    input.invisible-radio {
        opacity: 0;
        position: absolute;
    }
    
    .invisible-radio:checked+label:after {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.payment-method-submit-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    button.payment-method-submit-butt:hover {
        background-color: #1c1c1c;
    }
    
    span.payment-method-submit-butt-txt-span {
        vertical-align: middle;
    }
    
    span.payment-method-submit-butt-icon-span {
        vertical-align: middle;
        margin-left: 10px;
    }
    
    div.payment-method-row {
        width: 100%;
        display: flex;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
    
    .form-group-expiry-date {
        width: calc(60% - 20px);
    }
    
    .form-group-verification-code {
        margin-left: 20px;
        width: 40%;
    }
    
    
    div.payment-method-input-area {
        width: 100%;
        float: left;
    }
    
    label.payment-method-input-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: normal;
        margin: 0;
        float: left;
        margin-bottom: 10px;
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    .mollie-component {
        background: #fff;
        border-radius: 4px;
        padding: 13px;
        border: 1px solid lightgray;
        transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
    }
    
    .mollie-component.has-focus {
        border-color: #0077ff;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .mollie-component.is-invalid {
        border-color: #ff1717;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.payment-method-input-div {
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        /* display: none; */
        overflow: hidden;
    }
    
    div.payment-method-input-div.active {
        min-height: 50px;
        height: auto;
        cursor: default;
    }
    
    div.payment-method-input-div.active label#payment-method-label {
        cursor: default;
    }
    
    label#payment-method-label {
        cursor: pointer;
    }
    
    div.payment-pay-method-inner-bar {
        width: 100%;
        height: 50px;
    }
    
    div.payment-pay-method-check-area {
        width: 40px;
        height: 100%;
        float: left;
    }
    
    .radio {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 14px;
    }
    
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .radio .radio-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid black;
    }
    
    .radio:hover .radio-btn {
        background-color: #ccc;
    }
    
    .radio input:checked+.radio-btn {
        background-color: #ffffff;
    }
    
    .radio .radio-btn:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .radio input:checked+.radio-btn:after {
        display: block;
    }
    
    .radio .radio-btn:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
    }
    
    div.payment-method-title-area {
        width: calc(100% - 40px);
        height: 100%;
        float: left;
    }
    
    p.payment-method-title-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        line-height: 50px;
        float: left;
    }
    
    img.payment-method-issuers-img {
        float: right;
        height: 25px;
        margin-top: 12.5px;
        margin-left: 5px;
    }
    
    div.payment-method-content-div {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }
    
    form.betaling-pay-method-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    ul.payment-method-issuers-lising-ul {
        width: 100%;
        min-height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: left;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    
    li.payment-method-issuer-item-li {
        height: 80px;
        outline: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        float: left;
        border: 1px solid transparent;
    }
    
    li.payment-method-issuer-item-li:nth-child(4n+1) {
        margin-left: 0;
    }
    
    li.payment-method-issuer-item-li.selected {
        border-color: rgb(0, 0, 0);
    }
    
    label.payment-method-issuer-label {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    img.payment-method-issuer-img {
        height: 30px;
        width: auto;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    
    p.payment-method-issuer-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
        user-select: none;
        font-size: 12px;
    }
    
    input.invisible-radio {
        opacity: 0;
        position: absolute;
    }
    
    .invisible-radio:checked+label:after {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.payment-method-submit-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    button.payment-method-submit-butt:hover {
        background-color: #1c1c1c;
    }
    
    span.payment-method-submit-butt-txt-span {
        vertical-align: middle;
    }
    
    span.payment-method-submit-butt-icon-span {
        vertical-align: middle;
        margin-left: 10px;
    }
    
    div.payment-method-row {
        width: 100%;
        display: flex;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
    
    .form-group-expiry-date {
        width: calc(60% - 20px);
    }
    
    .form-group-verification-code {
        margin-left: 20px;
        width: 40%;
    }
    
    
    div.payment-method-input-area {
        width: 100%;
        float: left;
    }
    
    label.payment-method-input-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: normal;
        margin: 0;
        float: left;
        margin-bottom: 10px;
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    .mollie-component {
        background: #fff;
        border-radius: 4px;
        padding: 13px;
        border: 1px solid lightgray;
        transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
    }
    
    .mollie-component.has-focus {
        border-color: #0077ff;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .mollie-component.is-invalid {
        border-color: #ff1717;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.payment-method-input-div {
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        /* display: none; */
        overflow: hidden;
    }
    
    div.payment-method-input-div.active {
        min-height: 50px;
        height: auto;
        cursor: default;
    }
    
    div.payment-method-input-div.active label#payment-method-label {
        cursor: default;
    }
    
    label#payment-method-label {
        cursor: pointer;
    }
    
    div.payment-pay-method-inner-bar {
        width: 100%;
        height: 50px;
    }
    
    div.payment-pay-method-check-area {
        width: 40px;
        height: 100%;
        float: left;
    }
    
    .radio {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 14px;
    }
    
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .radio .radio-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid black;
    }
    
    .radio:hover .radio-btn {
        background-color: #ccc;
    }
    
    .radio input:checked+.radio-btn {
        background-color: #ffffff;
    }
    
    .radio .radio-btn:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .radio input:checked+.radio-btn:after {
        display: block;
    }
    
    .radio .radio-btn:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
    }
    
    div.payment-method-title-area {
        width: calc(100% - 40px);
        height: 100%;
        float: left;
    }
    
    p.payment-method-title-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        line-height: 50px;
        float: left;
    }
    
    img.payment-method-issuers-img {
        float: right;
        height: 25px;
        margin-top: 12.5px;
        margin-left: 5px;
    }
    
    div.payment-method-content-div {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }
    
    form.betaling-pay-method-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    ul.payment-method-issuers-lising-ul {
        width: 100%;
        min-height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: left;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    
    li.payment-method-issuer-item-li {
        height: 80px;
        outline: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        float: left;
        border: 1px solid transparent;
    }
    
    li.payment-method-issuer-item-li:nth-child(4n+1) {
        margin-left: 0;
    }
    
    li.payment-method-issuer-item-li.selected {
        border-color: rgb(0, 0, 0);
    }
    
    label.payment-method-issuer-label {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    img.payment-method-issuer-img {
        height: 30px;
        width: auto;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    
    p.payment-method-issuer-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
        user-select: none;
        font-size: 12px;
    }
    
    input.invisible-radio {
        opacity: 0;
        position: absolute;
    }
    
    .invisible-radio:checked+label:after {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.payment-method-submit-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    button.payment-method-submit-butt:hover {
        background-color: #1c1c1c;
    }
    
    span.payment-method-submit-butt-txt-span {
        vertical-align: middle;
    }
    
    span.payment-method-submit-butt-icon-span {
        vertical-align: middle;
        margin-left: 10px;
    }
    
    div.payment-method-row {
        width: 100%;
        display: flex;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
    
    .form-group-expiry-date {
        width: calc(60% - 20px);
    }
    
    .form-group-verification-code {
        margin-left: 20px;
        width: 40%;
    }
    
    
    div.payment-method-input-area {
        width: 100%;
        float: left;
    }
    
    label.payment-method-input-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: normal;
        margin: 0;
        float: left;
        margin-bottom: 10px;
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    .mollie-component {
        background: #fff;
        border-radius: 4px;
        padding: 13px;
        border: 1px solid lightgray;
        transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
    }
    
    .mollie-component.has-focus {
        border-color: #0077ff;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .mollie-component.is-invalid {
        border-color: #ff1717;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.payment-method-input-div {
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        /* display: none; */
        overflow: hidden;
    }
    
    div.payment-method-input-div.active {
        min-height: 50px;
        height: auto;
        cursor: default;
    }
    
    div.payment-method-input-div.active label#payment-method-label {
        cursor: default;
    }
    
    label#payment-method-label {
        cursor: pointer;
    }
    
    div.payment-pay-method-inner-bar {
        width: 100%;
        height: 50px;
    }
    
    div.payment-pay-method-check-area {
        width: 40px;
        height: 100%;
        float: left;
    }
    
    .radio {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 14px;
    }
    
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .radio .radio-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid black;
    }
    
    .radio:hover .radio-btn {
        background-color: #ccc;
    }
    
    .radio input:checked+.radio-btn {
        background-color: #ffffff;
    }
    
    .radio .radio-btn:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .radio input:checked+.radio-btn:after {
        display: block;
    }
    
    .radio .radio-btn:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
    }
    
    div.payment-method-title-area {
        width: calc(100% - 40px);
        height: 100%;
        float: left;
    }
    
    p.payment-method-title-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        line-height: 50px;
        float: left;
    }
    
    img.payment-method-issuers-img {
        float: right;
        height: 25px;
        margin-top: 12.5px;
        margin-left: 5px;
    }
    
    div.payment-method-content-div {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }
    
    form.betaling-pay-method-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    ul.payment-method-issuers-lising-ul {
        width: 100%;
        min-height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: left;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }
    
    li.payment-method-issuer-item-li {
        height: 80px;
        outline: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        float: left;
        border: 1px solid transparent;
    }
    
    li.payment-method-issuer-item-li:nth-child(4n+1) {
        margin-left: 0;
    }
    
    li.payment-method-issuer-item-li.selected {
        border-color: rgb(0, 0, 0);
    }
    
    label.payment-method-issuer-label {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    img.payment-method-issuer-img {
        height: 30px;
        width: auto;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    
    p.payment-method-issuer-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
        user-select: none;
        font-size: 12px;
    }
    
    input.invisible-radio {
        opacity: 0;
        position: absolute;
    }
    
    .invisible-radio:checked+label:after {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.payment-method-submit-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    button.payment-method-submit-butt:hover {
        background-color: #1c1c1c;
    }
    
    span.payment-method-submit-butt-txt-span {
        vertical-align: middle;
    }
    
    span.payment-method-submit-butt-icon-span {
        vertical-align: middle;
        margin-left: 10px;
    }
    
    div.payment-method-row {
        width: 100%;
        display: flex;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
    
    .form-group-expiry-date {
        width: calc(60% - 20px);
    }
    
    .form-group-verification-code {
        margin-left: 20px;
        width: 40%;
    }
    
    
    div.payment-method-input-area {
        width: 100%;
        float: left;
    }
    
    label.payment-method-input-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: normal;
        margin: 0;
        float: left;
        margin-bottom: 10px;
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    .mollie-component {
        background: #fff;
        border-radius: 4px;
        padding: 13px;
        border: 1px solid lightgray;
        transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
    }
    
    .mollie-component.has-focus {
        border-color: #0077ff;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .mollie-component.is-invalid {
        border-color: #ff1717;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.payment-method-input-div {
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        /* display: none; */
        overflow: hidden;
    }
    
    div.payment-method-input-div.active {
        min-height: 50px;
        height: auto;
        cursor: default;
    }
    
    div.payment-method-input-div.active label#payment-method-label {
        cursor: default;
    }
    
    label#payment-method-label {
        cursor: pointer;
    }
    
    div.payment-pay-method-inner-bar {
        width: 100%;
        height: 50px;
    }
    
    div.payment-pay-method-check-area {
        width: 40px;
        height: 100%;
        float: left;
    }
    
    .radio {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 14px;
    }
    
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .radio .radio-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid black;
    }
    
    .radio:hover .radio-btn {
        background-color: #ccc;
    }
    
    .radio input:checked+.radio-btn {
        background-color: #ffffff;
    }
    
    .radio .radio-btn:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .radio input:checked+.radio-btn:after {
        display: block;
    }
    
    .radio .radio-btn:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
    }
    
    div.payment-method-title-area {
        width: calc(100% - 40px);
        height: 100%;
        float: left;
    }
    
    p.payment-method-title-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        line-height: 50px;
        float: left;
    }
    
    img.payment-method-issuers-img {
        float: right;
        height: 25px;
        margin-top: 12.5px;
        margin-left: 5px;
    }
    
    div.payment-method-content-div {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }
    
    form.betaling-pay-method-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    ul.payment-method-issuers-lising-ul {
        width: 100%;
        min-height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: left;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }
    
    li.payment-method-issuer-item-li {
        height: 80px;
        outline: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
        float: left;
        border: 1px solid transparent;
    }
    
    li.payment-method-issuer-item-li:nth-child(4n+1) {
        margin-left: 0;
    }
    
    li.payment-method-issuer-item-li.selected {
        border-color: rgb(0, 0, 0);
    }
    
    label.payment-method-issuer-label {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    img.payment-method-issuer-img {
        height: 30px;
        width: auto;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    
    p.payment-method-issuer-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
        user-select: none;
        font-size: 12px;
    }
    
    input.invisible-radio {
        opacity: 0;
        position: absolute;
    }
    
    .invisible-radio:checked+label:after {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.payment-method-submit-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    button.payment-method-submit-butt:hover {
        background-color: #1c1c1c;
    }
    
    span.payment-method-submit-butt-txt-span {
        vertical-align: middle;
    }
    
    span.payment-method-submit-butt-icon-span {
        vertical-align: middle;
        margin-left: 10px;
    }
    
    div.payment-method-row {
        width: 100%;
        display: flex;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
    
    .form-group-expiry-date {
        width: calc(60% - 20px);
    }
    
    .form-group-verification-code {
        margin-left: 20px;
        width: 40%;
    }
    
    
    div.payment-method-input-area {
        width: 100%;
        float: left;
    }
    
    label.payment-method-input-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: normal;
        margin: 0;
        float: left;
        margin-bottom: 10px;
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    .mollie-component {
        background: #fff;
        border-radius: 4px;
        padding: 13px;
        border: 1px solid lightgray;
        transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
    }
    
    .mollie-component.has-focus {
        border-color: #0077ff;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .mollie-component.is-invalid {
        border-color: #ff1717;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media (min-width: 1450px) {
    div.payment-method-input-div {
        width: 100%;
        height: 50px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        /* display: none; */
        overflow: hidden;
    }
    
    div.payment-method-input-div.active {
        min-height: 50px;
        height: auto;
        cursor: default;
    }
    
    div.payment-method-input-div.active label#payment-method-label {
        cursor: default;
    }
    
    label#payment-method-label {
        cursor: pointer;
    }
    
    div.payment-pay-method-inner-bar {
        width: 100%;
        height: 50px;
    }
    
    div.payment-pay-method-check-area {
        width: 40px;
        height: 100%;
        float: left;
    }
    
    .radio {
        display: inline-block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        margin-top: 14px;
    }
    
    .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .radio .radio-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid black;
    }
    
    .radio:hover .radio-btn {
        background-color: #ccc;
    }
    
    .radio input:checked+.radio-btn {
        background-color: #ffffff;
    }
    
    .radio .radio-btn:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .radio input:checked+.radio-btn:after {
        display: block;
    }
    
    .radio .radio-btn:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
    }
    
    div.payment-method-title-area {
        width: calc(100% - 40px);
        height: 100%;
        float: left;
    }
    
    p.payment-method-title-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        line-height: 50px;
        float: left;
    }
    
    img.payment-method-issuers-img {
        float: right;
        height: 25px;
        margin-top: 12.5px;
        margin-left: 5px;
    }
    
    div.payment-method-content-div {
        width: 100%;
        min-height: 100px;
        margin-top: 10px;
    }
    
    form.betaling-pay-method-form {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
    }
    
    ul.payment-method-issuers-lising-ul {
        width: 100%;
        min-height: 50px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: left;
    }
    
    li.payment-method-issuer-item-li {
        width: calc((100% / 4) - 15.5px);
        height: 80px;
        outline: 1px solid #ccc;
        margin-bottom: 10px;
        position: relative;
        border-radius: 5px;
        float: left;
        margin-left: 15px;
        border: 1px solid transparent;
    }
    
    li.payment-method-issuer-item-li:nth-child(4n+1) {
        margin-left: 0;
    }
    
    li.payment-method-issuer-item-li.selected {
        border-color: rgb(0, 0, 0);
    }
    
    label.payment-method-issuer-label {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
    }
    
    img.payment-method-issuer-img {
        height: 30px;
        width: auto;
        margin: auto;
        display: block;
        margin-bottom: 10px;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    
    p.payment-method-issuer-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        pointer-events: none;
        user-select: none;
        font-size: 12px;
    }
    
    input.invisible-radio {
        opacity: 0;
        position: absolute;
    }
    
    .invisible-radio:checked+label:after {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    button.payment-method-submit-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    
    button.payment-method-submit-butt:hover {
        background-color: #1c1c1c;
    }
    
    span.payment-method-submit-butt-txt-span {
        vertical-align: middle;
    }
    
    span.payment-method-submit-butt-icon-span {
        vertical-align: middle;
        margin-left: 10px;
    }
    
    div.payment-method-row {
        width: 100%;
        display: flex;
    }
    
    .form-group {
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
    
    .form-group-expiry-date {
        width: calc(60% - 20px);
    }
    
    .form-group-verification-code {
        margin-left: 20px;
        width: 40%;
    }
    
    
    div.payment-method-input-area {
        width: 100%;
        float: left;
    }
    
    label.payment-method-input-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 17px;
        font-weight: normal;
        margin: 0;
        float: left;
        margin-bottom: 10px;
    }
    
    div.form-field-error {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        float: right;
    }
    
    .mollie-component {
        background: #fff;
        border-radius: 4px;
        padding: 13px;
        border: 1px solid lightgray;
        transition: 0.15s border-color cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 500;
    }
    
    .mollie-component.has-focus {
        border-color: #0077ff;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .mollie-component.is-invalid {
        border-color: #ff1717;
        transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
    }
}