@media (max-width: 575.98px) {
    div.yellow-note-view {
        width: 100%;
        margin: 0 auto;
    }
    
    h2.note-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.yellow-note-view {
        width: 100%;
        margin: 0 auto;
    }
    
    h2.note-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.yellow-note-view {
        width: 90%;
        margin: 0 auto;
    }
    
    h2.note-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.yellow-note-view {
        width: 90%;
        margin: 0 auto;
    }
    
    h2.note-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.yellow-note-view {
        width: 70%;
        margin: 0 auto;
    }
    
    h2.note-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 1450px) {
    div.yellow-note-view {
        width: 70%;
        margin: 0 auto;
    }
    
    h2.note-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
}