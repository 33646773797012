@media (max-width: 575.98px) {
    .active-shift-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row; /* Zorg ervoor dat de inhoud horizontaal wordt uitgelijnd */
        align-items: center; 
        gap: 10px;
    }
    
    .active-shift-bar-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
        display: none;
    }
    
    .download-button {
        color: black;
        /* background-color: #007bff; */
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .download-button:hover {
        color: #494949;
    }
    
    .material-symbols-outlined {
        font-size: 1.5em;
    }
    
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .active-shift-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row; /* Zorg ervoor dat de inhoud horizontaal wordt uitgelijnd */
        align-items: center; 
        gap: 10px;
    }
    
    .active-shift-bar-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }
    
    .download-button {
        color: black;
        /* background-color: #007bff; */
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .download-button:hover {
        color: #494949;
    }
    
    .material-symbols-outlined {
        font-size: 1.5em;
    }
    
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .active-shift-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row; /* Zorg ervoor dat de inhoud horizontaal wordt uitgelijnd */
        align-items: center; 
        gap: 10px;
    }
    
    .active-shift-bar-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }
    
    .download-button {
        color: black;
        /* background-color: #007bff; */
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .download-button:hover {
        color: #494949;
    }
    
    .material-symbols-outlined {
        font-size: 1.5em;
    }
    
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .active-shift-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row; /* Zorg ervoor dat de inhoud horizontaal wordt uitgelijnd */
        align-items: center; 
        gap: 10px;
    }
    
    .active-shift-bar-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }
    
    .download-button {
        color: black;
        /* background-color: #007bff; */
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .download-button:hover {
        color: #494949;
    }
    
    .material-symbols-outlined {
        font-size: 1.5em;
    }
    
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    .active-shift-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row; /* Zorg ervoor dat de inhoud horizontaal wordt uitgelijnd */
        align-items: center; 
        gap: 10px;
    }
    
    .active-shift-bar-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }
    
    .download-button {
        color: black;
        /* background-color: #007bff; */
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .download-button:hover {
        color: #494949;
    }
    
    .material-symbols-outlined {
        font-size: 1.5em;
    }
    
}

@media (min-width: 1450px) {
    .active-shift-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row; /* Zorg ervoor dat de inhoud horizontaal wordt uitgelijnd */
        align-items: center; 
        gap: 10px;
    }
    
    .active-shift-bar-title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }
    
    .download-button {
        color: black;
        /* background-color: #007bff; */
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .download-button:hover {
        color: #494949;
    }
    
    .material-symbols-outlined {
        font-size: 1.5em;
    }
    
}