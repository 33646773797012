@media (max-width: 575.98px) {
    div.pre-briefing-overview-step {
        width: 100%;
    }
    
    div.pre-briefing-input-area {
        width: 100%;    
        margin-bottom: 20px;
    }
    
    .pre-briefing-select {
        width: 100%;
    }
    
    h3.pre-briefing-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-report-view {
        width: 100%;
        background-color: lightgray;
        border-radius: 20px;
        border: 1px solid lightgray;
        overflow: hidden;
        margin-top: 20px;
        cursor: pointer;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.pre-briefing-overview-step {
        width: 70%;
        margin-left: 15%;
    }
    
    div.pre-briefing-input-area {
        width: 100%;    
        margin-bottom: 20px;
    }
    
    .pre-briefing-select {
        width: 100%;
    }
    
    h3.pre-briefing-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-report-view {
        width: 100%;
        background-color: lightgray;
        border-radius: 20px;
        border: 1px solid lightgray;
        overflow: hidden;
        margin-top: 20px;
        cursor: pointer;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.pre-briefing-overview-step {
        width: 70%;
        margin-left: 15%;
    }
    
    div.pre-briefing-input-area {
        width: 100%;    
        margin-bottom: 20px;
    }
    
    .pre-briefing-select {
        width: 100%;
    }
    
    h3.pre-briefing-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-report-view {
        width: 100%;
        background-color: lightgray;
        border-radius: 20px;
        border: 1px solid lightgray;
        overflow: hidden;
        margin-top: 20px;
        cursor: pointer;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.pre-briefing-overview-step {
        width: 70%;
        margin-left: 15%;
    }
    
    div.pre-briefing-input-area {
        width: 100%;    
        margin-bottom: 20px;
    }
    
    .pre-briefing-select {
        width: 100%;
    }
    
    h3.pre-briefing-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-report-view {
        width: 100%;
        background-color: lightgray;
        border-radius: 20px;
        border: 1px solid lightgray;
        overflow: hidden;
        margin-top: 20px;
        cursor: pointer;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.pre-briefing-overview-step {
        width: 70%;
        margin-left: 15%;
    }
    
    div.pre-briefing-input-area {
        width: 100%;    
        margin-bottom: 20px;
    }
    
    .pre-briefing-select {
        width: 100%;
    }
    
    h3.pre-briefing-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-report-view {
        width: 100%;
        background-color: lightgray;
        border-radius: 20px;
        border: 1px solid lightgray;
        overflow: hidden;
        margin-top: 20px;
        cursor: pointer;
    }
}

@media (min-width: 1450px) {
    div.pre-briefing-overview-step {
        width: 70%;
        margin-left: 15%;
    }
    
    div.pre-briefing-input-area {
        width: 100%;    
        margin-bottom: 20px;
    }
    
    .pre-briefing-select {
        width: 100%;
    }
    
    h3.pre-briefing-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1.2em;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-report-view {
        width: 100%;
        background-color: lightgray;
        border-radius: 20px;
        border: 1px solid lightgray;
        overflow: hidden;
        margin-top: 20px;
        cursor: pointer;
    }
}