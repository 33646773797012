@media (max-width: 575.98px) {
    div.settings-top-bar {
        width: 100%;
        height: 80px;
        background-color: white;
        border-bottom: 1px solid #d0d0d0;
        padding: 20px;
        z-index: 100;
    }

    h2.settings-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        display: inline-block;
    }

    div.settings-controls-area {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: right;
        display: none;
    }

    button.settings-save-btn {
        height: 40px;
        background-color: #007bff;
        border: 1px solid #007bff;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        color: white;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    button.settings-save-btn:hover {
        background-color: #0056b3;
    }

    button.settings-cancel-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-right: 20px;
    }

    button.settings-cancel-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-mobile-close-btn {
        height: 40px;
        width: 40px;
        background-color: white;
        cursor: pointer;
        border-radius: 50px;
        float: right;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    button.settings-mobile-close-btn:hover {
        background-color: #f0f0f0;
    }

    span.settings-mobile-close-icon-span {
        font-size: 25px;
        color: black;
        line-height: 40px;
        display: block;
        text-align: center;
    }

    ul.settings-listing-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-item-li {
        width: 100%;
        min-height: 50px;
        list-style-type: none;
        padding: 20px 20px;
        border-bottom: 1px solid #d0d0d0;
        overflow: hidden;
    }

    li.settings-item-li:last-child {
        border-bottom: none;
    }

    div.settings-left-area {
        width: 100%;
        min-height: inherit;
        float: left;
        margin-bottom: 20px;
    }

    div.settings-right-area {
        width: 100%;
        min-height: inherit;
        float: right;
        display: flex;
        justify-content: right;
    }

    p.settings-type-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    p.settings-value-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        margin-top: 5px;
    }

    button.settings-edit-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
    }

    button.settings-edit-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-delete-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
        color: red;
    }

    button.settings-delete-btn:hover {
        background-color: #f0f0f0;
    }

    div.settings-static-values {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-values {
        width: 100%;
        min-height: 50px;
    }

    form.settings-edit-form {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-bar {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-label-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    label.settings-edit-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 40px;
    }

    div.settings-edit-input-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    input.settings-edit-input {
        width: 100%;
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: 10px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        padding: 0 10px;
    }

    input.settings-edit-input:focus {
        outline: none;
    }

    input.settings-edit-input.half-input {
        width: 100%;
        margin-bottom: 20px;
    }

    input.settings-edit-input.half-input:last-child {
        margin-right: 0;
    }

    div.settings-inner-content {
        width: 100%;
    }

    .select-input {
        border: 1px solid lightgray;
        border-radius: 12px;
    }

    div.mobile-settings-save-area {
        position: fixed;
        bottom: 0;
        background-color: white;
        justify-content: right;
        align-content: center;
        display: flex;
        padding: 20px;
        border-top: 1px solid lightgray;
        width: 100%;
        left: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.settings-top-bar {
        width: 100%;
        height: 80px;
        background-color: white;
        border-bottom: 1px solid #d0d0d0;
        padding: 20px;
        z-index: 100;
    }

    h2.settings-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        display: inline-block;
    }

    div.settings-controls-area {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: right;
        display: none;
    }

    button.settings-save-btn {
        height: 40px;
        background-color: #007bff;
        border: 1px solid #007bff;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        color: white;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    button.settings-save-btn:hover {
        background-color: #0056b3;
    }

    button.settings-cancel-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-right: 20px;
    }

    button.settings-cancel-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-mobile-close-btn {
        height: 40px;
        width: 40px;
        background-color: white;
        cursor: pointer;
        border-radius: 50px;
        float: right;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    button.settings-mobile-close-btn:hover {
        background-color: #f0f0f0;
    }

    span.settings-mobile-close-icon-span {
        font-size: 25px;
        color: black;
        line-height: 40px;
        display: block;
        text-align: center;
    }

    ul.settings-listing-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-item-li {
        width: 100%;
        min-height: 50px;
        list-style-type: none;
        padding: 20px 20px;
        border-bottom: 1px solid #d0d0d0;
        overflow: hidden;
    }

    li.settings-item-li:last-child {
        border-bottom: none;
    }

    div.settings-left-area {
        width: 100%;
        min-height: inherit;
        float: left;
        margin-bottom: 20px;
    }

    div.settings-right-area {
        width: 100%;
        min-height: inherit;
        float: right;
        display: flex;
        justify-content: right;
    }

    p.settings-type-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    p.settings-value-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        margin-top: 5px;
    }

    button.settings-edit-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
    }

    button.settings-edit-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-delete-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
        color: red;
    }

    button.settings-delete-btn:hover {
        background-color: #f0f0f0;
    }

    div.settings-static-values {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-values {
        width: 100%;
        min-height: 50px;
    }

    form.settings-edit-form {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-bar {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-label-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    label.settings-edit-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 40px;
    }

    div.settings-edit-input-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    input.settings-edit-input {
        width: 100%;
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: 10px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        padding: 0 10px;
    }

    input.settings-edit-input:focus {
        outline: none;
    }

    input.settings-edit-input.half-input {
        width: 100%;
        margin-bottom: 20px;
    }

    input.settings-edit-input.half-input:last-child {
        margin-right: 0;
    }

    div.settings-inner-content {
        width: 100%;
    }

    .select-input {
        border: 1px solid lightgray;
        border-radius: 12px;
    }

    div.mobile-settings-save-area {
        position: fixed;
        bottom: 0;
        background-color: white;
        justify-content: right;
        align-content: center;
        display: flex;
        padding: 20px;
        border-top: 1px solid lightgray;
        width: 100%;
        left: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.settings-top-bar {
        width: 100%;
        height: 80px;
        background-color: white;
        border-bottom: 1px solid #d0d0d0;
        padding: 20px;
        z-index: 100;
    }

    h2.settings-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        display: inline-block;
    }

    div.settings-controls-area {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: right;
        display: none;
    }

    button.settings-save-btn {
        height: 40px;
        background-color: #007bff;
        border: 1px solid #007bff;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        color: white;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    button.settings-save-btn:hover {
        background-color: #0056b3;
    }

    button.settings-cancel-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-right: 20px;
    }

    button.settings-cancel-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-mobile-close-btn {
        height: 40px;
        width: 40px;
        background-color: white;
        cursor: pointer;
        border-radius: 50px;
        float: right;
        display: flex;
        align-content: center;
        justify-content: center;
    }

    button.settings-mobile-close-btn:hover {
        background-color: #f0f0f0;
    }

    span.settings-mobile-close-icon-span {
        font-size: 25px;
        color: black;
        line-height: 40px;
        display: block;
        text-align: center;
    }

    ul.settings-listing-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-item-li {
        width: 100%;
        min-height: 50px;
        list-style-type: none;
        padding: 20px 20px;
        border-bottom: 1px solid #d0d0d0;
        overflow: hidden;
    }

    li.settings-item-li:last-child {
        border-bottom: none;
    }

    div.settings-left-area {
        width: 100%;
        min-height: inherit;
        float: left;
        margin-bottom: 20px;
    }

    div.settings-right-area {
        width: 100%;
        min-height: inherit;
        float: right;
        display: flex;
        justify-content: right;
    }

    p.settings-type-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    p.settings-value-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        margin-top: 5px;
    }

    button.settings-edit-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
    }

    button.settings-edit-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-delete-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
        color: red;
    }

    button.settings-delete-btn:hover {
        background-color: #f0f0f0;
    }

    div.settings-static-values {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-values {
        width: 100%;
        min-height: 50px;
    }

    form.settings-edit-form {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-bar {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-label-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    label.settings-edit-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 40px;
    }

    div.settings-edit-input-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    input.settings-edit-input {
        width: 100%;
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: 10px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        padding: 0 10px;
    }

    input.settings-edit-input:focus {
        outline: none;
    }

    input.settings-edit-input.half-input {
        width: 100%;
        margin-bottom: 20px;
    }

    input.settings-edit-input.half-input:last-child {
        margin-right: 0;
    }

    div.settings-inner-content {
        width: 100%;
    }

    .select-input {
        border: 1px solid lightgray;
        border-radius: 12px;
    }

    div.mobile-settings-save-area {
        position: fixed;
        bottom: 0;
        background-color: white;
        justify-content: right;
        align-content: center;
        display: flex;
        padding: 20px;
        border-top: 1px solid lightgray;
        width: 100%;
        left: 0;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.settings-top-bar {
        width: 100%;
        height: 80px;
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        border-bottom: 1px solid #d0d0d0;
        padding: 20px;
        z-index: 100;
    }

    h2.settings-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    div.settings-controls-area {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: right;
    }

    button.settings-save-btn {
        height: 40px;
        background-color: #007bff;
        border: 1px solid #007bff;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        color: white;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    button.settings-save-btn:hover {
        background-color: #0056b3;
    }

    button.settings-cancel-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-right: 20px;
    }

    button.settings-cancel-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-mobile-close-btn {
        display: none;
    }

    ul.settings-listing-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-item-li {
        width: 100%;
        min-height: 50px;
        list-style-type: none;
        padding: 20px 20px;
        border-bottom: 1px solid #d0d0d0;
        overflow: hidden;
    }

    div.settings-left-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.settings-right-area {
        width: 100%;
        min-height: inherit;
        float: right;
        display: flex;
        justify-content: right;
    }

    p.settings-type-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    p.settings-value-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        margin-top: 5px;
    }

    button.settings-edit-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
    }

    button.settings-edit-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-delete-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
        color: red;
    }

    button.settings-delete-btn:hover {
        background-color: #f0f0f0;
    }

    div.settings-static-values {
        width: 100%;
        min-height: 50px;
        display: flex;
    }

    div.settings-edit-values {
        width: 100%;
        min-height: 50px;
    }

    form.settings-edit-form {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-bar {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-label-area {
        width: 200px;
        float: left;
        min-height: inherit;
    }

    label.settings-edit-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 40px;
    }

    div.settings-edit-input-area {
        width: calc(100% - 200px);
        float: left;
        min-height: inherit;
    }

    input.settings-edit-input {
        width: 100%;
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: 10px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        padding: 0 10px;
    }

    input.settings-edit-input:focus {
        outline: none;
    }

    input.settings-edit-input.half-input {
        width: calc(50% - 10px);
        margin-right: 20px;
    }

    input.settings-edit-input.half-input:last-child {
        margin-right: 0;
    }

    div.settings-inner-content {
        width: 100%;
    }

    .select-input {
        border: 1px solid lightgray;
        border-radius: 12px;
    }

    div.mobile-settings-save-area {
        display: none;
    }
}

@media (min-width: 1200px) {
    div.settings-top-bar {
        width: 100%;
        height: 80px;
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        border-bottom: 1px solid #d0d0d0;
        padding: 20px;
        z-index: 100;
    }

    h2.settings-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 24px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    div.settings-controls-area {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: right;
    }

    button.settings-save-btn {
        height: 40px;
        background-color: #007bff;
        border: 1px solid #007bff;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        color: white;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    button.settings-save-btn:hover {
        background-color: #0056b3;
    }

    button.settings-cancel-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 7.5px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-right: 20px;
    }

    button.settings-cancel-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-mobile-close-btn {
        display: none;
    }

    ul.settings-listing-ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    li.settings-item-li {
        width: 100%;
        min-height: 50px;
        list-style-type: none;
        padding: 20px 20px;
        border-bottom: 1px solid #d0d0d0;
        overflow: hidden;
    }

    div.settings-left-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.settings-right-area {
        width: 100%;
        min-height: inherit;
        float: right;
        display: flex;
        justify-content: right;
    }

    p.settings-type-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }

    p.settings-value-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        margin-top: 5px;
    }

    button.settings-edit-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
    }

    button.settings-edit-btn:hover {
        background-color: #f0f0f0;
    }

    button.settings-delete-btn {
        height: 40px;
        background-color: white;
        border: 1px solid #d0d0d0;
        cursor: pointer;
        border-radius: 20px;
        padding: 0 20px;
        color: red;
    }

    button.settings-delete-btn:hover {
        background-color: #f0f0f0;
    }

    div.settings-static-values {
        width: 100%;
        min-height: 50px;
        display: flex;
    }

    div.settings-edit-values {
        width: 100%;
        min-height: 50px;
    }

    form.settings-edit-form {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-bar {
        width: 100%;
        min-height: 50px;
    }

    div.settings-edit-label-area {
        width: 200px;
        float: left;
        min-height: inherit;
    }

    label.settings-edit-label {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 40px;
    }

    div.settings-edit-input-area {
        width: calc(100% - 200px);
        float: left;
        min-height: inherit;
    }

    input.settings-edit-input {
        width: 100%;
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: 10px;
        padding: 0 20px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        padding: 0 10px;
    }

    input.settings-edit-input:focus {
        outline: none;
    }

    input.settings-edit-input.half-input {
        width: calc(50% - 10px);
        margin-right: 20px;
    }

    input.settings-edit-input.half-input:last-child {
        margin-right: 0;
    }

    div.settings-inner-content {
        width: 100%;
    }

    .select-input {
        border: 1px solid lightgray;
        border-radius: 12px;
    }

    div.mobile-settings-save-area {
        display: none;
    }
}