@media (max-width: 575.98px) {
    main#main-content {
        width: 100%;
        height: 100vh;
        float: right;
        box-sizing: border-box;
        overflow: auto;
        transition: 200ms;
        transition-property: width;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    main#main-content {
        width: 100%;
        height: 100vh;
        float: right;
        box-sizing: border-box;
        overflow: auto;
        transition: 200ms;
        transition-property: width;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    main#main-content {
        width: 100%;
        height: 100vh;
        float: right;
        box-sizing: border-box;
        overflow: auto;
        transition: 200ms;
        transition-property: width;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    main#main-content {
        width: 100%;
        height: 100vh;
        float: right;
        box-sizing: border-box;
        overflow: auto;
        transition: 200ms;
        transition-property: width;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    main#main-content {
        width: calc(100% - 80px);
        height: 100vh;
        float: right;
        box-sizing: border-box;
        overflow: auto;
        transition: 200ms;
        transition-property: width;
    }
}

@media (min-width: 1600px) {
    main#main-content {
        width: calc(100% - 80px);
        height: 100vh;
        float: right;
        box-sizing: border-box;
        overflow: auto;
        transition: 200ms;
        transition-property: width;
    }

}