div.months-container-div {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 20px;
    position: relative;
}

div.month-area-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2.month-year-title-h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

div.month-inner-content {}

div.day-labels {
    float: left;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
}

div.day-labels span {
    width: 40px;
    height: 40px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: black;
}

button.date-button {
    width: 40px;
    height: 40px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
}

.date-button.selected {
    background-color: rgb(0, 128, 255);
    color: white;
    font-weight: bold;
}

.date-button.deactivated {
    color: gray;
    cursor: not-allowed;
}

button.month-backward-btn {
    width: 40px;
    height: 40px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 50px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
    position: absolute;
    left: 10px;
    top: 15px;
}

button.month-backward-btn:hover {
    background-color: #efefef;
}

button.month-forward-btn {
    width: 40px;
    height: 40px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 50px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
    position: absolute;
    right: 10px;
    top: 15px;
}

button.month-forward-btn:hover {
    background-color: #efefef;
}


span.month-icon-span {
    font-size: 35px;
}