@media (max-width: 575.98px) {
    button.main-goback-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    button.main-goback-butt:hover {
        background-color: #e7e7e7;
    }
    
    span.main-goback-icon-span {
        vertical-align: middle;
    }
    
    div#inloggen-wrapper-div {
        width: 400px;
        max-width: 90%;
        min-height: 100vh;
        display: block;
        margin: auto;
    }
    
    div#inloggen-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div#inloggen-login-view-div {
        width: 100%;
        min-height: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    div#inloggen-login-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    img.inloggen-login-logo {
        height: 100px;
        display: block;
        margin: auto;
    }
    
    div#inloggen-login-form-area-div {
        width: 100%;
        min-height: 100px;
    }
    
    div.inloggen-login-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }
    
    label.inloggen-login-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }
    
    input.inloggen-login-input {
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 7.5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        box-sizing: border-box;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.login-form-error-field {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        margin-top: 5px;
    }
    
    div.inloggen-login-form-left-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: left;
    }
    
    div.inloggen-login-form-right-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: right;
    }
    
    #login-remember-me-checkbox input[type="checkbox"] {
        display: none;
    }
    
    #login-remember-me-checkbox .checkmark {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid lightgray;
        cursor: pointer;
        position: relative;
        float: left;
        border-radius: 5px;
    }
    
    #login-remember-me-checkbox .checkmark::after {
        content: "";
        display: none;
        position: absolute;
        width: 6px;
        height: 15px;
        border: solid #0058ff;
        border-width: 0 2px 2px 0;
        left: 6px;
        transform: rotate(45deg);
    }
    
    #login-remember-me-checkbox input:checked~.checkmark::after {
        display: block;
    }
    
    span.checkbox-text {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: 10px;
        float: left;
    }
    
    p.inloggen-login-forget-pass-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        text-align: right;
    }
    
    button.inloggen-login-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-login-button:hover {
        background-color: #0056b3;
    }

    button.inloggen-register-btn {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: white;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-register-btn:hover {
        background-color: #f7f7f7;
    }
    
    .progress {
        position: absolute;
        top: 0;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-top: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: rgb(255, 255, 255);
        border-left-color: rgb(255, 255, 255);
        animation: spin 1s linear infinite;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    button.main-goback-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    button.main-goback-butt:hover {
        background-color: #e7e7e7;
    }
    
    span.main-goback-icon-span {
        vertical-align: middle;
    }
    
    div#inloggen-wrapper-div {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }
    
    div#inloggen-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div#inloggen-login-view-div {
        width: 100%;
        min-height: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    div#inloggen-login-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    img.inloggen-login-logo {
        height: 100px;
        display: block;
        margin: auto;
    }
    
    div#inloggen-login-form-area-div {
        width: 100%;
        min-height: 100px;
    }
    
    div.inloggen-login-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }
    
    label.inloggen-login-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }
    
    input.inloggen-login-input {
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 7.5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        box-sizing: border-box;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.login-form-error-field {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        margin-top: 5px;
    }
    
    div.inloggen-login-form-left-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: left;
    }
    
    div.inloggen-login-form-right-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: right;
    }
    
    #login-remember-me-checkbox input[type="checkbox"] {
        display: none;
    }
    
    #login-remember-me-checkbox .checkmark {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid lightgray;
        cursor: pointer;
        position: relative;
        float: left;
        border-radius: 5px;
    }
    
    #login-remember-me-checkbox .checkmark::after {
        content: "";
        display: none;
        position: absolute;
        width: 6px;
        height: 15px;
        border: solid #0058ff;
        border-width: 0 2px 2px 0;
        left: 6px;
        transform: rotate(45deg);
    }
    
    #login-remember-me-checkbox input:checked~.checkmark::after {
        display: block;
    }
    
    span.checkbox-text {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: 10px;
        float: left;
    }
    
    p.inloggen-login-forget-pass-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        text-align: right;
    }
    
    button.inloggen-login-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-login-button:hover {
        background-color: #0056b3;
    }
    
    button.inloggen-register-btn {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: white;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-register-btn:hover {
        background-color: #f7f7f7;
    }
    
    .progress {
        position: absolute;
        top: 0;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-top: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: rgb(255, 255, 255);
        border-left-color: rgb(255, 255, 255);
        animation: spin 1s linear infinite;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    button.main-goback-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    button.main-goback-butt:hover {
        background-color: #e7e7e7;
    }
    
    span.main-goback-icon-span {
        vertical-align: middle;
    }
    
    div#inloggen-wrapper-div {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }
    
    div#inloggen-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div#inloggen-login-view-div {
        width: 100%;
        min-height: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    div#inloggen-login-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    img.inloggen-login-logo {
        height: 100px;
        display: block;
        margin: auto;
    }
    
    div#inloggen-login-form-area-div {
        width: 100%;
        min-height: 100px;
    }
    
    div.inloggen-login-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }
    
    label.inloggen-login-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }
    
    input.inloggen-login-input {
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 7.5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        box-sizing: border-box;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.login-form-error-field {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        margin-top: 5px;
    }
    
    div.inloggen-login-form-left-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: left;
    }
    
    div.inloggen-login-form-right-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: right;
    }
    
    #login-remember-me-checkbox input[type="checkbox"] {
        display: none;
    }
    
    #login-remember-me-checkbox .checkmark {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid lightgray;
        cursor: pointer;
        position: relative;
        float: left;
        border-radius: 5px;
    }
    
    #login-remember-me-checkbox .checkmark::after {
        content: "";
        display: none;
        position: absolute;
        width: 6px;
        height: 15px;
        border: solid #0058ff;
        border-width: 0 2px 2px 0;
        left: 6px;
        transform: rotate(45deg);
    }
    
    #login-remember-me-checkbox input:checked~.checkmark::after {
        display: block;
    }
    
    span.checkbox-text {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: 10px;
        float: left;
    }
    
    p.inloggen-login-forget-pass-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        text-align: right;
    }
    
    button.inloggen-login-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-login-button:hover {
        background-color: #0056b3;
    }

    button.inloggen-register-btn {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: white;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-register-btn:hover {
        background-color: #f7f7f7;
    }
    
    .progress {
        position: absolute;
        top: 0;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-top: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: rgb(255, 255, 255);
        border-left-color: rgb(255, 255, 255);
        animation: spin 1s linear infinite;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    button.main-goback-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    button.main-goback-butt:hover {
        background-color: #e7e7e7;
    }
    
    span.main-goback-icon-span {
        vertical-align: middle;
    }
    
    div#inloggen-wrapper-div {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }
    
    div#inloggen-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div#inloggen-login-view-div {
        width: 100%;
        min-height: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    div#inloggen-login-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    img.inloggen-login-logo {
        height: 100px;
        display: block;
        margin: auto;
    }
    
    div#inloggen-login-form-area-div {
        width: 100%;
        min-height: 100px;
    }
    
    div.inloggen-login-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }
    
    label.inloggen-login-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }
    
    input.inloggen-login-input {
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 7.5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        box-sizing: border-box;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.login-form-error-field {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        margin-top: 5px;
    }
    
    div.inloggen-login-form-left-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: left;
    }
    
    div.inloggen-login-form-right-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: right;
    }
    
    #login-remember-me-checkbox input[type="checkbox"] {
        display: none;
    }
    
    #login-remember-me-checkbox .checkmark {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid lightgray;
        cursor: pointer;
        position: relative;
        float: left;
        border-radius: 5px;
    }
    
    #login-remember-me-checkbox .checkmark::after {
        content: "";
        display: none;
        position: absolute;
        width: 6px;
        height: 15px;
        border: solid #0058ff;
        border-width: 0 2px 2px 0;
        left: 6px;
        transform: rotate(45deg);
    }
    
    #login-remember-me-checkbox input:checked~.checkmark::after {
        display: block;
    }
    
    span.checkbox-text {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: 10px;
        float: left;
    }
    
    p.inloggen-login-forget-pass-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        text-align: right;
    }
    
    button.inloggen-login-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-login-button:hover {
        background-color: #0056b3;
    }

    button.inloggen-register-btn {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: white;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-register-btn:hover {
        background-color: #f7f7f7;
    }
    
    .progress {
        position: absolute;
        top: 0;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-top: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: rgb(255, 255, 255);
        border-left-color: rgb(255, 255, 255);
        animation: spin 1s linear infinite;
    }
}

@media (min-width: 1200px) {
    button.main-goback-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    button.main-goback-butt:hover {
        background-color: #e7e7e7;
    }
    
    span.main-goback-icon-span {
        vertical-align: middle;
    }
    
    div#inloggen-wrapper-div {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }
    
    div#inloggen-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div#inloggen-login-view-div {
        width: 100%;
        min-height: 100px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    
    div#inloggen-login-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }
    
    img.inloggen-login-logo {
        height: 100px;
        display: block;
        margin: auto;
    }
    
    div#inloggen-login-form-area-div {
        width: 100%;
        min-height: 100px;
    }
    
    div.inloggen-login-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }
    
    label.inloggen-login-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }
    
    input.inloggen-login-input {
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        border-radius: 7.5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        box-sizing: border-box;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.login-form-error-field {
        font-size: 15px;
        margin-top: 2px;
        color: #ff1717;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        margin-top: 5px;
    }
    
    div.inloggen-login-form-left-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: left;
    }
    
    div.inloggen-login-form-right-area {
        width: 50%;
        min-height: 0px;
        margin-bottom: 20px;
        float: right;
    }
    
    #login-remember-me-checkbox input[type="checkbox"] {
        display: none;
    }
    
    #login-remember-me-checkbox .checkmark {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid lightgray;
        cursor: pointer;
        position: relative;
        float: left;
        border-radius: 5px;
    }
    
    #login-remember-me-checkbox .checkmark::after {
        content: "";
        display: none;
        position: absolute;
        width: 6px;
        height: 15px;
        border: solid #0058ff;
        border-width: 0 2px 2px 0;
        left: 6px;
        transform: rotate(45deg);
    }
    
    #login-remember-me-checkbox input:checked~.checkmark::after {
        display: block;
    }
    
    span.checkbox-text {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        margin-left: 10px;
        float: left;
    }
    
    p.inloggen-login-forget-pass-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: 400;
        font-size: 15px;
        text-align: right;
    }
    
    button.inloggen-login-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-login-button:hover {
        background-color: #0056b3;
    }

    button.inloggen-register-btn {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: white;
        color: black;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }
    
    button.inloggen-register-btn:hover {
        background-color: #f7f7f7;
    }
    
    .progress {
        position: absolute;
        top: 0;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-top: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: rgb(255, 255, 255);
        border-left-color: rgb(255, 255, 255);
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

div.login-view {
    overflow: auto;
    height: 100dvh;
}