@media (max-width: 575.98px) {
    nav#main-portal-nav {
        width: 0;
        height: 100vh;
        background-color: white;
        padding-top: 10px;
        box-sizing: border-box;
        float: left;
        position: fixed;
        top: 0;
        left: 0;
        transition: 200ms;
        transition-property: width;
        overflow: hidden;
        z-index: 1000;
        border: none;
    }
    
    nav#main-portal-nav.nav-open {
        width: 300px;
        border-right: 1px solid lightgray;
    }
    
    div#main-portal-nav-top-area {
        min-height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-area {
        width: 100%;
        height: 140px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-inner-logo-area {
        width: 100%;
        min-height: 50px;
        float: left;
    }
    
    img#main-portal-nav-top-logo-img {
        width: 50px;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        transition-property: width;
        transition: 200ms;
    }
    
    .nav-open img#main-portal-nav-top-logo-img {
        height: 100px;
        width: auto;
        transition-property: width;
        transition: 200ms;
    }
    
    div#main-portal-nav-top-logo-inner-butt-area {
        width: 50px;
        height: 50px;
        float: left;
        transition: 200ms;
    }
    
    button#main-portal-nav-top-logo-menu-butt {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        float: right;
        border: none;
        cursor: pointer;
        background-color: transparent;
    }
    
    button#main-portal-nav-top-logo-menu-butt:hover {
        background-color: #efefef;
    }
    
    span#main-portal-nav-menu-icon {
        vertical-align: middle;
    }
    
    ul#main-portal-menu-listing-ul {
        height: calc(100% - 250px);
        padding: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }
    
    .nav-open ul#main-portal-menu-listing-ul {
        width: 300px;
    }
    
    div#main-portal-menu-under-bar-div {
        height: 60px;
        border-top: 1px solid lightgray;
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    nav#main-portal-nav {
        width: 0;
        height: 100vh;
        border-right: 1px solid lightgray;
        background-color: white;
        padding-top: 10px;
        box-sizing: border-box;
        float: left;
        position: fixed;
        top: 0;
        left: 0;
        transition: 200ms;
        transition-property: width;
        overflow: hidden;
        z-index: 1000;
    }
    
    nav#main-portal-nav.nav-open {
        width: 300px;
    }
    
    div#main-portal-nav-top-area {
        min-height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-area {
        width: 100%;
        height: 140px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-inner-logo-area {
        width: 100%;
        min-height: 50px;
        float: left;
    }
    
    img#main-portal-nav-top-logo-img {
        width: 50px;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        transition-property: width;
        transition: 200ms;
    }
    
    .nav-open img#main-portal-nav-top-logo-img {
        height: 100px;
        width: auto;
        transition-property: width;
        transition: 200ms;
    }
    
    div#main-portal-nav-top-logo-inner-butt-area {
        width: 50px;
        height: 50px;
        float: left;
        transition: 200ms;
    }
    
    button#main-portal-nav-top-logo-menu-butt {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        float: right;
        border: none;
        cursor: pointer;
        background-color: transparent;
    }
    
    button#main-portal-nav-top-logo-menu-butt:hover {
        background-color: #efefef;
    }
    
    span#main-portal-nav-menu-icon {
        vertical-align: middle;
    }
    
    ul#main-portal-menu-listing-ul {
        height: calc(100% - 250px);
        padding: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }
    
    .nav-open ul#main-portal-menu-listing-ul {
        width: 300px;
    }
    
    div#main-portal-menu-under-bar-div {
        height: 60px;
        border-top: 1px solid lightgray;
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    nav#main-portal-nav {
        width: 0;
        height: 100vh;
        border-right: 1px solid lightgray;
        background-color: white;
        padding-top: 10px;
        box-sizing: border-box;
        float: left;
        position: fixed;
        top: 0;
        left: 0;
        transition: 200ms;
        transition-property: width;
        overflow: hidden;
        z-index: 1000;
    }
    
    nav#main-portal-nav.nav-open {
        width: 300px;
    }
    
    div#main-portal-nav-top-area {
        min-height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-area {
        width: 100%;
        height: 140px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-inner-logo-area {
        width: 100%;
        min-height: 50px;
        float: left;
    }
    
    img#main-portal-nav-top-logo-img {
        width: 50px;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        transition-property: width;
        transition: 200ms;
    }
    
    .nav-open img#main-portal-nav-top-logo-img {
        height: 100px;
        width: auto;
        transition-property: width;
        transition: 200ms;
    }
    
    div#main-portal-nav-top-logo-inner-butt-area {
        width: 50px;
        height: 50px;
        float: left;
        transition: 200ms;
    }
    
    button#main-portal-nav-top-logo-menu-butt {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        float: right;
        border: none;
        cursor: pointer;
        background-color: transparent;
    }
    
    button#main-portal-nav-top-logo-menu-butt:hover {
        background-color: #efefef;
    }
    
    span#main-portal-nav-menu-icon {
        vertical-align: middle;
    }
    
    ul#main-portal-menu-listing-ul {
        height: calc(100% - 250px);
        padding: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }
    
    .nav-open ul#main-portal-menu-listing-ul {
        width: 300px;
    }
    
    div#main-portal-menu-under-bar-div {
        height: 60px;
        border-top: 1px solid lightgray;
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    nav#main-portal-nav {
        width: 0;
        height: 100vh;
        border-right: 1px solid lightgray;
        background-color: white;
        padding-top: 10px;
        box-sizing: border-box;
        float: left;
        position: fixed;
        top: 0;
        left: 0;
        transition: 200ms;
        transition-property: width;
        overflow: hidden;
        z-index: 1000;
    }
    
    nav#main-portal-nav.nav-open {
        width: 300px;
    }
    
    div#main-portal-nav-top-area {
        min-height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-area {
        width: 100%;
        height: 140px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-inner-logo-area {
        width: 100%;
        min-height: 50px;
        float: left;
    }
    
    img#main-portal-nav-top-logo-img {
        width: 50px;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        transition-property: width;
        transition: 200ms;
    }
    
    .nav-open img#main-portal-nav-top-logo-img {
        height: 100px;
        width: auto;
        transition-property: width;
        transition: 200ms;
    }
    
    div#main-portal-nav-top-logo-inner-butt-area {
        width: 50px;
        height: 50px;
        float: left;
        transition: 200ms;
    }
    
    button#main-portal-nav-top-logo-menu-butt {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        float: right;
        border: none;
        cursor: pointer;
        background-color: transparent;
    }
    
    button#main-portal-nav-top-logo-menu-butt:hover {
        background-color: #efefef;
    }
    
    span#main-portal-nav-menu-icon {
        vertical-align: middle;
    }
    
    ul#main-portal-menu-listing-ul {
        height: calc(100% - 250px);
        padding: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }
    
    .nav-open ul#main-portal-menu-listing-ul {
        width: 300px;
    }
    
    div#main-portal-menu-under-bar-div {
        height: 60px;
        border-top: 1px solid lightgray;
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
    }
}

@media (min-width: 1200px) {
    nav#main-portal-nav {
        width: 80px;
        height: 100vh;
        border-right: 1px solid lightgray;
        background-color: white;
        padding-top: 10px;
        box-sizing: border-box;
        float: left;
        position: fixed;
        top: 0;
        left: 0;
        transition: 200ms;
        transition-property: width;
        overflow: hidden;
        z-index: 1000;
    }
    
    nav#main-portal-nav.nav-open {
        width: 300px;
    }
    
    div#main-portal-nav-top-area {
        min-height: 50px;
        padding-left: 15px;
        padding-right: 15px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-area {
        width: 100%;
        height: 140px;
        overflow: hidden;
    }
    
    div#main-portal-nav-top-logo-inner-logo-area {
        width: 100%;
        min-height: 50px;
        float: left;
    }
    
    img#main-portal-nav-top-logo-img {
        width: 50px;
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
        transition-property: width;
        transition: 200ms;
    }
    
    .nav-open img#main-portal-nav-top-logo-img {
        height: 100px;
        width: auto;
        transition-property: width;
        transition: 200ms;
    }
    
    div#main-portal-nav-top-logo-inner-butt-area {
        width: 50px;
        height: 50px;
        float: left;
        transition: 200ms;
    }
    
    button#main-portal-nav-top-logo-menu-butt {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        float: right;
        border: none;
        cursor: pointer;
        background-color: transparent;
    }
    
    button#main-portal-nav-top-logo-menu-butt:hover {
        background-color: #efefef;
    }
    
    span#main-portal-nav-menu-icon {
        vertical-align: middle;
    }
    
    ul#main-portal-menu-listing-ul {
        height: calc(100% - 250px);
        padding: 0;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }
    
    .nav-open ul#main-portal-menu-listing-ul {
        width: 300px;
    }
    
    div#main-portal-menu-under-bar-div {
        height: 60px;
        border-top: 1px solid lightgray;
        margin-left: 15px;
        margin-right: 15px;
        box-sizing: border-box;
    }
}

