@media (max-width: 575.98px) {
    div.accountsettings-container {
        position: relative;
    }
    
    div.accountsettings-profile-div {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        background-color: #ffffff;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
        line-height: 120px;
        border: 1px solid #d0d0d0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.accountsettings-container {
        position: relative;
    }
    
    div.accountsettings-profile-div {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        background-color: #ffffff;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
        line-height: 120px;
        border: 1px solid #d0d0d0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.accountsettings-container {
        position: relative;
    }
    
    div.accountsettings-profile-div {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        background-color: #ffffff;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
        line-height: 120px;
        border: 1px solid #d0d0d0;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.accountsettings-container {
        position: relative;
    }
    
    div.accountsettings-profile-div {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        background-color: #ffffff;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
        line-height: 120px;
        border: 1px solid #d0d0d0;
    }
}

@media (min-width: 1200px) {
    div.accountsettings-container {
        position: relative;
    }
    
    div.accountsettings-profile-div {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        background-color: #ffffff;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 60px;
        font-weight: bold;
        text-align: center;
        line-height: 120px;
        border: 1px solid #d0d0d0;
    }
}