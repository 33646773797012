.title-input {
    margin-bottom: 20px;
}

.description-input {
    margin-bottom: 20px;
}

div.thingtype-showcase-div {
    width: 100%;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid rgb(228, 228, 228);
    margin-bottom: 20px;
    padding: 0 10px;
    display: flex;
    justify-content: left;
    align-items: center;
}

span.thingtype-showcase-color-span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: black;
    margin-right: 10px;
}

p.thingtype-showcase-type-txt-p {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'SF Pro Display', sans-serif;
}

div.addthing-controls-div {
    width: 100%;
    height: 50px;
}

button.addthing-submit-control-btn {
    width: 100px;
    height: 40px;
    background-color: rgb(0, 110, 255);
    color: white;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}

button.addthing-submit-control-btn:hover {
    background-color: rgb(0, 90, 235);
}

button.addthing-cancel-control-btn {
    width: 100px;
    height: 40px;
    background-color: white;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 10px;
    color: black;
}

button.addthing-cancel-control-btn:hover {
    background-color: #f0f0f0;
}