div.faq-question-bar {
    width: 100%;
    display: block;
    margin: auto;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid lightgray;
}

div.faq-question-area {
    width: 100%;
    display: block;
    margin: auto;
    overflow: hidden;

}

div.faq-question-icon-area {
    width: 60px;
    float: left;
}

span.faq-question-icon-open-span {
    float: left;
    font-size: 35px;
    line-height: 80px;
}

div.faq-question-txt-area {
    width: calc(100% - 60px);
    float: left;
}

h3.faq-question-title-h3 {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 20px;
    padding: 25px 0px;
}

div.faq-anwser-area {
    width: 100%;
}

p.faq-anwser-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
}