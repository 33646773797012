@media (max-width: 575.98px) {
    h1.settings-subscription-title-h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
        margin-top: 50px;
    }
    
    p.settings-subscription-txt-p {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .subscription-type-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    
    div.settings-subscriptions-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    div.settings-subscription-card {
        width: 400px;
        margin: 0 1rem 1rem 1rem;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        float: right;
    }
    
    h3.settings-subscription-card-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
    }
    
    p.settings-subscription-card-desc-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    h1.settings-subscription-pricing-h1 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    span.settings-subscription-small-span {
        font-size: 0.8rem;
        font-weight: 400;
    }
    
    ul.settings-subscription-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li.settings-subscription-item-item-li {
        width: 100%;
        min-height: 0px;
        overflow: hidden;
    }

    span.settings-subscription-item-icon-span {
        font-size: 1.8rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        color: rgb(0, 110, 255);
        float: left;
    }

    p.settings-subscription-item-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        float: left;
    }
    
    button.settings-start-subscription-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-start-subscription-btn:hover {
        background-color: rgb(0, 94, 255);
    }

    button.settings-cancel-subscription-btn {
        background-color: rgb(223, 7, 7);
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-cancel-subscription-btn:hover {
        background-color: rgb(255, 0, 0);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    h1.settings-subscription-title-h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    p.settings-subscription-txt-p {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .subscription-type-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    
    div.settings-subscriptions-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    div.settings-subscription-card {
        width: 400px;
        margin: 0 1rem 1rem 1rem;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        float: right;
    }
    
    h3.settings-subscription-card-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
    }
    
    p.settings-subscription-card-desc-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    h1.settings-subscription-pricing-h1 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    span.settings-subscription-small-span {
        font-size: 0.8rem;
        font-weight: 400;
    }
    
    ul.settings-subscription-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li.settings-subscription-item-item-li {
        width: 100%;
        min-height: 0px;
        overflow: hidden;
    }

    span.settings-subscription-item-icon-span {
        font-size: 1.8rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        color: rgb(0, 110, 255);
        float: left;
    }

    p.settings-subscription-item-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        float: left;
    }
    
    button.settings-start-subscription-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-start-subscription-btn:hover {
        background-color: rgb(0, 94, 255);
    }

    button.settings-cancel-subscription-btn {
        background-color: rgb(223, 7, 7);
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-cancel-subscription-btn:hover {
        background-color: rgb(255, 0, 0);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    h1.settings-subscription-title-h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    p.settings-subscription-txt-p {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .subscription-type-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    
    div.settings-subscriptions-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    div.settings-subscription-card {
        width: 400px;
        margin: 0 1rem 1rem 1rem;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        float: right;
    }
    
    h3.settings-subscription-card-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
    }
    
    p.settings-subscription-card-desc-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    h1.settings-subscription-pricing-h1 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    span.settings-subscription-small-span {
        font-size: 0.8rem;
        font-weight: 400;
    }
    
    ul.settings-subscription-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li.settings-subscription-item-item-li {
        width: 100%;
        min-height: 0px;
        overflow: hidden;
    }

    span.settings-subscription-item-icon-span {
        font-size: 1.8rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        color: rgb(0, 110, 255);
        float: left;
    }

    p.settings-subscription-item-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        float: left;
    }
    
    button.settings-start-subscription-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-start-subscription-btn:hover {
        background-color: rgb(0, 94, 255);
    }

    button.settings-cancel-subscription-btn {
        background-color: rgb(223, 7, 7);
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-cancel-subscription-btn:hover {
        background-color: rgb(255, 0, 0);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    h1.settings-subscription-title-h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    p.settings-subscription-txt-p {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .subscription-type-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    
    div.settings-subscriptions-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    div.settings-subscription-card {
        width: 400px;
        margin: 0 1rem 1rem 1rem;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        float: right;
    }
    
    h3.settings-subscription-card-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
    }
    
    p.settings-subscription-card-desc-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    h1.settings-subscription-pricing-h1 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    span.settings-subscription-small-span {
        font-size: 0.8rem;
        font-weight: 400;
    }
    
    ul.settings-subscription-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li.settings-subscription-item-item-li {
        width: 100%;
        min-height: 0px;
        overflow: hidden;
    }

    span.settings-subscription-item-icon-span {
        font-size: 1.8rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        color: rgb(0, 110, 255);
        float: left;
    }

    p.settings-subscription-item-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        float: left;
    }
    
    button.settings-start-subscription-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-start-subscription-btn:hover {
        background-color: rgb(0, 94, 255);
    }

    button.settings-cancel-subscription-btn {
        background-color: rgb(223, 7, 7);
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-cancel-subscription-btn:hover {
        background-color: rgb(255, 0, 0);
    }
    
}

@media (min-width: 1200px) {
    div.subscriptions-container {
        height: 100%;
        min-height: 100%;
    }

    div.settings-subscription-content-box {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    h1.settings-subscription-title-h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    p.settings-subscription-txt-p {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .subscription-type-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    
    div.settings-subscriptions-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    div.settings-subscription-card {
        width: 400px;
        margin: 0 1rem 1rem 1rem;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        background-color: white;
        float: right;
    }
    
    h3.settings-subscription-card-title {
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
    }
    
    p.settings-subscription-card-desc-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    h1.settings-subscription-pricing-h1 {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    span.settings-subscription-small-span {
        font-size: 0.8rem;
        font-weight: 400;
    }
    

    ul.settings-subscription-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li.settings-subscription-item-item-li {
        width: 100%;
        min-height: 0px;
        overflow: hidden;
    }

    span.settings-subscription-item-icon-span {
        font-size: 1.8rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        color: rgb(0, 110, 255);
        float: left;
    }

    p.settings-subscription-item-txt-p {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        float: left;
    }
    
    button.settings-start-subscription-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-start-subscription-btn:hover {
        background-color: rgb(0, 94, 255);
    }

    button.settings-cancel-subscription-btn {
        background-color: rgb(223, 7, 7);
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.settings-cancel-subscription-btn:hover {
        background-color: rgb(255, 0, 0);
    }

    button.settings-edit-subscription-btn {
        background-color: rgb(227, 227, 227);
        color: black;
        font-size: 1rem;
        font-weight: 700;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        width: 100%;
        cursor: pointer;
        transition: background-color 0.1s;
    }

    button.settings-edit-subscription-btn:hover {
        background-color: rgb(200, 200, 200);
    }
}