div.logo-upload-preview {
    position: relative;
    width: 100%;
    height: 100%;
}

img.logo-upload-preview-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 100px;
}