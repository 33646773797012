div.logo-upload-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.logo-upload-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.logo-upload-modal-container {
    width: 700px;
    min-height: 100px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
}

h1.logo-upload-title-h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

div.logo-upload-modal-content-area {
    width: 100%;
}

div.logo-upload-modal-upload-container {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.logo-upload-modal-inner-upload-div {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-color: #f0f0f0;
}

div.logo-upload-dropzone {
    border: 2px dashed #cccccc;
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.logo-upload-drop-area {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.logo-upload-drop-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
}

div.logo-upload-add-file-inner-area {
    width: 90%;
}

span.logo-upload-file-icon-span {
    font-size: 70px;
    color: rgb(0, 94, 255);
}

p.logo-upload-add-file-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
}

div.logo-upload-modal-controls-area {
    width: 100%;
    margin-top: 20px;
}

button.logo-upload-modal-cancel-btn {
    background-color: #ffffff;
    color: #000000;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    margin-right: 10px;
}

button.logo-upload-modal-cancel-btn:hover {
    background-color: #f5f5f5;
}

button.logo-upload-modal-upload-btn {
    background-color: rgb(0, 94, 255);
    color: #ffffff;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}

button.logo-upload-modal-upload-btn:hover {
    background-color: rgb(0, 66, 181);
}