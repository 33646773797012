@media (max-width: 575.98px) {
    div.register-container {
        height: 100dvh;
        background-color: white;
        overflow: auto;
    }
    
    div.register-left-area {
        background-color: white;
        display: flex;
        min-height: 100%;
        width: 100%;
        float: left;
        padding: 20px;
    }
    
    div.register-right-area {
        width: 60%;
        height: 100%;
        position: fixed;
        right: 0;
        display: none;
    }
    
    img.register-inner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    nav.register-top-nav {
        width: 100%;
        z-index: 1000;
        top: 0;
        min-height: 50px;
        margin-bottom: 40px;
        overflow: hidden;
    }
    
    ul.register-top-nav-listing-ul {
        width: 100%;
        list-style-type: none;
    }
    
    li.register-top-left-area-li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    button.register-back-btn {
        background-color: white;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.register-back-btn:hover {
        background-color: rgb(240, 240, 240);
    }
    
    li.register-top-middle-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 100%;
    }
    
    img.register-logo-img {
        width: 150px;
        margin: 0 auto;
    }
    
    li.register-top-right-area-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 200px;
        display: none;
    }
    
    div.register-inner-wrapper-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.register-inner-container-div {
        width: 100%;
    }
    
    h1.register-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        line-height: 1;
    }
    
    p.register-txt-p {
        font-size: 1em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
        color: rgb(118, 118, 118);
        line-height: 1;;
    }
    
    form.register-form {}
    
    div.register-input-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
    }
    
    label.register-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.register-required-span {
        color: red;
    }
    
    input.register-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 10px;
    }
    
    input.register-input:focus {
        outline: none;
    }
    
    label.register-label {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    
    .register-checkbox {
        display: none;
    }
    
    p.register-checkbox-txt-p {
    
        font-family: 'SF Pro Display', sans-serif;
        line-height: 25px;
    }
    
    .register-checkbox-custom {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1.5px solid lightgray;
        /* Blue border */
        border-radius: 6px;
        float: left;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    }
    
    .register-checkbox:checked+.register-checkbox-custom {
        background-color: #007BFF;
        /* Blue background */
    }
    
    .register-checkbox-custom::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px; /* Adjusted width */
        height: 16px; /* Adjusted height */
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
    }
    
    .register-checkbox:checked+.register-checkbox-custom::after {
        display: block;
    }
    
    .register-link {
        color: #007BFF;
        /* Blue color for links */
        text-decoration: none;
    }
    
    .register-link:hover {
        text-decoration: underline;
    }
    
    button.register-next-btn {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 10px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    button.register-next-btn:hover {
        background-color: rgb(0, 80, 220);
    }
    
    P.register-err-txt-p {
        color: red;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        margin-top: 10px;
        text-align: right;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.register-container {
        height: 100dvh;
        background-color: white;
        overflow: auto;
    }
    
    div.register-left-area {
        background-color: white;
        display: flex;
        min-height: 100%;
        width: 100%;
        float: left;
        padding: 20px;
    }
    
    div.register-right-area {
        width: 60%;
        height: 100%;
        position: fixed;
        right: 0;
        display: none;
    }
    
    img.register-inner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    nav.register-top-nav {
        width: 100%;
        z-index: 1000;
        top: 0;
        min-height: 50px;
        margin-bottom: 40px;
        overflow: hidden;
    }
    
    ul.register-top-nav-listing-ul {
        width: 100%;
        list-style-type: none;
    }
    
    li.register-top-left-area-li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    button.register-back-btn {
        background-color: white;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.register-back-btn:hover {
        background-color: rgb(240, 240, 240);
    }
    
    li.register-top-middle-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 100%;
    }
    
    img.register-logo-img {
        width: 150px;
        margin: 0 auto;
    }
    
    li.register-top-right-area-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 200px;
        display: none;
    }
    
    div.register-inner-wrapper-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.register-inner-container-div {
        width: 100%;
    }
    
    h1.register-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        line-height: 1;
    }
    
    p.register-txt-p {
        font-size: 1em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
        color: rgb(118, 118, 118);
        line-height: 1;;
    }
    
    form.register-form {}
    
    div.register-input-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
    }
    
    label.register-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.register-required-span {
        color: red;
    }
    
    input.register-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 10px;
    }
    
    input.register-input:focus {
        outline: none;
    }
    
    label.register-label {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    
    .register-checkbox {
        display: none;
    }
    
    p.register-checkbox-txt-p {
    
        font-family: 'SF Pro Display', sans-serif;
        line-height: 25px;
    }
    
    .register-checkbox-custom {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1.5px solid lightgray;
        /* Blue border */
        border-radius: 6px;
        float: left;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    }
    
    .register-checkbox:checked+.register-checkbox-custom {
        background-color: #007BFF;
        /* Blue background */
    }
    
    .register-checkbox-custom::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px; /* Adjusted width */
        height: 16px; /* Adjusted height */
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
    }
    
    .register-checkbox:checked+.register-checkbox-custom::after {
        display: block;
    }
    
    .register-link {
        color: #007BFF;
        /* Blue color for links */
        text-decoration: none;
    }
    
    .register-link:hover {
        text-decoration: underline;
    }
    
    button.register-next-btn {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 10px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    button.register-next-btn:hover {
        background-color: rgb(0, 80, 220);
    }
    
    P.register-err-txt-p {
        color: red;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        margin-top: 10px;
        text-align: right;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.register-container {
        height: 100dvh;
        background-color: white;
        overflow: auto;
    }
    
    div.register-left-area {
        background-color: white;
        display: flex;
        min-height: 100%;
        width: 100%;
        float: left;
        padding: 20px;
    }
    
    div.register-right-area {
        width: 60%;
        height: 100%;
        position: fixed;
        right: 0;
        display: none;
    }
    
    img.register-inner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    nav.register-top-nav {
        width: 100%;
        z-index: 1000;
        top: 0;
        min-height: 50px;
        margin-bottom: 40px;
        overflow: hidden;
    }
    
    ul.register-top-nav-listing-ul {
        width: 100%;
        list-style-type: none;
    }
    
    li.register-top-left-area-li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    button.register-back-btn {
        background-color: white;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.register-back-btn:hover {
        background-color: rgb(240, 240, 240);
    }
    
    li.register-top-middle-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 100%;
    }
    
    img.register-logo-img {
        width: 150px;
        margin: 0 auto;
    }
    
    li.register-top-right-area-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 200px;
        display: none;
    }
    
    div.register-inner-wrapper-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.register-inner-container-div {
        width: 100%;
    }
    
    h1.register-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        line-height: 1;
    }
    
    p.register-txt-p {
        font-size: 1em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
        color: rgb(118, 118, 118);
        line-height: 1;;
    }
    
    form.register-form {}
    
    div.register-input-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
    }
    
    label.register-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.register-required-span {
        color: red;
    }
    
    input.register-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 10px;
    }
    
    input.register-input:focus {
        outline: none;
    }
    
    label.register-label {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    
    .register-checkbox {
        display: none;
    }
    
    p.register-checkbox-txt-p {
    
        font-family: 'SF Pro Display', sans-serif;
        line-height: 25px;
    }
    
    .register-checkbox-custom {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1.5px solid lightgray;
        /* Blue border */
        border-radius: 6px;
        float: left;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    }
    
    .register-checkbox:checked+.register-checkbox-custom {
        background-color: #007BFF;
        /* Blue background */
    }
    
    .register-checkbox-custom::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px; /* Adjusted width */
        height: 16px; /* Adjusted height */
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
    }
    
    .register-checkbox:checked+.register-checkbox-custom::after {
        display: block;
    }
    
    .register-link {
        color: #007BFF;
        /* Blue color for links */
        text-decoration: none;
    }
    
    .register-link:hover {
        text-decoration: underline;
    }
    
    button.register-next-btn {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 10px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    button.register-next-btn:hover {
        background-color: rgb(0, 80, 220);
    }
    
    P.register-err-txt-p {
        color: red;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        margin-top: 10px;
        text-align: right;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.register-container {
        height: 100dvh;
        background-color: white;
        overflow: auto;
    }
    
    div.register-left-area {
        background-color: white;
        display: flex;
        min-height: 100%;
        width: 60%;
        float: left;
        padding: 50px;
    }
    
    div.register-right-area {
        width: 40%;
        height: 100%;
        position: fixed;
        right: 0;
    }
    
    img.register-inner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    nav.register-top-nav {
        width: 100%;
        height: 80px;
        z-index: 1000;
        top: 0;
        margin-bottom: 50px;
    }
    
    ul.register-top-nav-listing-ul {
        width: 100%;
        height: 100%;
        list-style-type: none;
    }
    
    li.register-top-left-area-li {
        width: 65px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    button.register-back-btn {
        background-color: white;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.register-back-btn:hover {
        background-color: rgb(240, 240, 240);
    }
    
    li.register-top-middle-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: calc(100% - 130px);
    }
    
    img.register-logo-img {
        height: 100%;
        margin: 0 auto;
    }
    
    li.register-top-right-area-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 65px;
    }
    
    div.register-inner-wrapper-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.register-inner-container-div {
        width: 100%;
    }
    
    h1.register-title-h1 {
        font-size: 2em;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
    }
    
    p.register-txt-p {
        font-size: 1em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
        color: rgb(118, 118, 118);
    }
    
    form.register-form {}
    
    div.register-input-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
    }
    
    label.register-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.register-required-span {
        color: red;
    }
    
    input.register-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 10px;
    }
    
    input.register-input:focus {
        outline: none;
    }
    
    label.register-label {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    
    .register-checkbox {
        display: none;
    }
    
    p.register-checkbox-txt-p {
    
        font-family: 'SF Pro Display', sans-serif;
        line-height: 25px;
    }
    
    .register-checkbox-custom {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1.5px solid lightgray;
        /* Blue border */
        border-radius: 6px;
        float: left;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    }
    
    .register-checkbox:checked+.register-checkbox-custom {
        background-color: #007BFF;
        /* Blue background */
    }
    
    .register-checkbox-custom::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px; /* Adjusted width */
        height: 16px; /* Adjusted height */
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
    }
    
    .register-checkbox:checked+.register-checkbox-custom::after {
        display: block;
    }
    
    .register-link {
        color: #007BFF;
        /* Blue color for links */
        text-decoration: none;
    }
    
    .register-link:hover {
        text-decoration: underline;
    }
    
    button.register-next-btn {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 10px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    button.register-next-btn:hover {
        background-color: rgb(0, 80, 220);
    }
    
    P.register-err-txt-p {
        color: red;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        margin-top: 10px;
        text-align: right;
    }
}

@media (min-width: 1200px) {
    div.register-container {
        height: 100dvh;
        background-color: white;
        overflow: auto;
    }
    
    div.register-left-area {
        background-color: white;
        display: flex;
        min-height: 100%;
        width: 45%;
        float: left;
        padding: 50px;
    }
    
    div.register-right-area {
        width: 55%;
        height: 100%;
        position: fixed;
        right: 0;
    }
    
    img.register-inner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    nav.register-top-nav {
        width: 100%;
        height: 80px;
        z-index: 1000;
        top: 0;
        margin-bottom: 50px;
    }
    
    ul.register-top-nav-listing-ul {
        width: 100%;
        height: 100%;
        list-style-type: none;
    }
    
    li.register-top-left-area-li {
        width: 65px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    button.register-back-btn {
        background-color: white;
        padding: 10px 20px;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.register-back-btn:hover {
        background-color: rgb(240, 240, 240);
    }
    
    li.register-top-middle-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: calc(100% - 130px);
    }
    
    img.register-logo-img {
        height: 100%;
        margin: 0 auto;
    }
    
    li.register-top-right-area-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        width: 65px;
    }
    
    div.register-inner-wrapper-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.register-inner-container-div {
        width: 100%;
    }
    
    h1.register-title-h1 {
        font-size: 2.2em;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        line-height: 1;
    }
    
    p.register-txt-p {
        font-size: 1.1em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
        color: rgb(118, 118, 118);
    }
    
    form.register-form {}
    
    div.register-input-bar {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
    }
    
    label.register-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.register-required-span {
        color: red;
    }
    
    input.register-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
        margin-top: 10px;
    }
    
    input.register-input:focus {
        outline: none;
    }
    
    label.register-label {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    
    .register-checkbox {
        display: none;
    }
    
    p.register-checkbox-txt-p {
    
        font-family: 'SF Pro Display', sans-serif;
        line-height: 25px;
    }
    
    .register-checkbox-custom {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1.5px solid lightgray;
        /* Blue border */
        border-radius: 6px;
        float: left;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
    }
    
    .register-checkbox:checked+.register-checkbox-custom {
        background-color: #007BFF;
        /* Blue background */
    }
    
    .register-checkbox-custom::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px; /* Adjusted width */
        height: 16px; /* Adjusted height */
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: translate(-50%, -50%) rotate(45deg);
        display: none;
    }
    
    .register-checkbox:checked+.register-checkbox-custom::after {
        display: block;
    }
    
    .register-link {
        color: #007BFF;
        /* Blue color for links */
        text-decoration: none;
    }
    
    .register-link:hover {
        text-decoration: underline;
    }
    
    button.register-next-btn {
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 10px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    button.register-next-btn:hover {
        background-color: rgb(0, 80, 220);
    }
    
    P.register-err-txt-p {
        color: red;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 1em;
        margin-top: 10px;
        text-align: right;
    }
}