div.addmailer-input-area {
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;
    float: left;
}

label.addmailer-label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    float: left;
}

input.addmailer-input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    outline: none;
}

div.addmailer-half-area {
    width: calc(100% / 2 - 10px);
    justify-content: space-between;
    margin-top: 10px;
    float: left;
}

div.addmailer-half-area:first-child {
    margin-right: 20px;
}

div.addmailer-controls-area {
    width: 100%;
    min-height: 50px;
    float: left;
}

button.addmailer-submit-btn {
    height: 45px;
    border: none;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    outline: none;
    float: right;
}

button.addmailer-submit-btn:hover {
    background-color: #0056b3;
}

button.addmailer-cancel-btn {
    height: 45px;
    border: none;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    float: right;
    margin-right: 20px;
}

button.addmailer-cancel-btn:hover {
    background-color: #d1d1d1;
}