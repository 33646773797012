.options-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; /* High z-index to ensure it overlays other content */
}

.options {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); /* Optional: Add a shadow for a "pop-out" effect */
    width: 200px; /* Or set a specific width */
    min-height: 100px;
    position: absolute;
}

ul.options-listing-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li.options-listing-li {
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
}

li.options-listing-li.delete {
    color: red;
}

li.options-listing-li:hover {
    background-color: #e6e6e6;
}

span.options-li-icon-span {
    margin-right: 10px;
    font-size: 20px;
    float: left;
}

span.options-li-txt-span {
    font-size: 16px;
    float: left;
}