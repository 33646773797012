@media (max-width: 575.98px) {
    .stepper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
    }
    
    .step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .step:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -1;
        transition: background-color 0.3s ease, width 0.5s ease;
    }
    
    .step.completed:not(:last-child)::after {
        background-color: #4caf50;
        width: 100%;
    }
    
    .step.active:not(:last-child)::after {
        width: 0;
    }
    
    .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: bold;
        position: relative;
        z-index: 1;
        border: 2px solid #ccc;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    .step.completed .step-number {
        border-color: #4caf50;
        background-color: #4caf50;
        color: #fff;
        box-shadow: 0 0 15px 0 rgba(76, 175, 79, 0.5);
    }
    
    .step.active .step-number {
        border-color: #2196f3;
        background-color: #2196f3;
        color: white;
        box-shadow: 0 0 15px 0 rgba(33, 149, 243, 0.5);
    }
    
    .checkmark {
        font-size: 18px;
    }
    
    .step-label {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
    }
    
    .stepper::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -2;
    }
    
    .stepper .step.completed::after {
        animation: fillLine 0.5s forwards;
    }
    
    .stepper .step:not(.completed)::after {
        animation: unfillLine 0.5s forwards;
    }
    
    @keyframes fillLine {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
    
    @keyframes unfillLine {
        from {
            width: 100%;
        }
        to {
            width: 0;
        }
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .stepper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    .step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
    }
    
    .step:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -1;
        transition: background-color 0.3s ease, width 0.5s ease;
    }
    
    .step.completed:not(:last-child)::after {
        background-color: #4caf50;
        width: 100%;
    }
    
    .step.active:not(:last-child)::after {
        width: 0;
    }
    
    .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: bold;
        position: relative;
        z-index: 1;
        border: 2px solid #ccc;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    .step.completed .step-number {
        border-color: #4caf50;
        background-color: #4caf50;
        color: #fff;
        box-shadow: 0 0 15px 0 rgba(76, 175, 79, 0.5);
    }
    
    .step.active .step-number {
        border-color: #2196f3;
        background-color: #2196f3;
        color: white;
        box-shadow: 0 0 15px 0 rgba(33, 149, 243, 0.5);
    }
    
    .checkmark {
        font-size: 18px;
    }
    
    .step-label {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
    }
    
    .stepper::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -2;
    }
    
    .stepper .step.completed::after {
        animation: fillLine 0.5s forwards;
    }
    
    .stepper .step:not(.completed)::after {
        animation: unfillLine 0.5s forwards;
    }
    
    @keyframes fillLine {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
    
    @keyframes unfillLine {
        from {
            width: 100%;
        }
        to {
            width: 0;
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .stepper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    .step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
    }
    
    .step:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -1;
        transition: background-color 0.3s ease, width 0.5s ease;
    }
    
    .step.completed:not(:last-child)::after {
        background-color: #4caf50;
        width: 100%;
    }
    
    .step.active:not(:last-child)::after {
        width: 0;
    }
    
    .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: bold;
        position: relative;
        z-index: 1;
        border: 2px solid #ccc;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    .step.completed .step-number {
        border-color: #4caf50;
        background-color: #4caf50;
        color: #fff;
        box-shadow: 0 0 15px 0 rgba(76, 175, 79, 0.5);
    }
    
    .step.active .step-number {
        border-color: #2196f3;
        background-color: #2196f3;
        color: white;
        box-shadow: 0 0 15px 0 rgba(33, 149, 243, 0.5);
    }
    
    .checkmark {
        font-size: 18px;
    }
    
    .step-label {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
    }
    
    .stepper::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -2;
    }
    
    .stepper .step.completed::after {
        animation: fillLine 0.5s forwards;
    }
    
    .stepper .step:not(.completed)::after {
        animation: unfillLine 0.5s forwards;
    }
    
    @keyframes fillLine {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
    
    @keyframes unfillLine {
        from {
            width: 100%;
        }
        to {
            width: 0;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .stepper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    .step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
    }
    
    .step:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -1;
        transition: background-color 0.3s ease, width 0.5s ease;
    }
    
    .step.completed:not(:last-child)::after {
        background-color: #4caf50;
        width: 100%;
    }
    
    .step.active:not(:last-child)::after {
        width: 0;
    }
    
    .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: bold;
        position: relative;
        z-index: 1;
        border: 2px solid #ccc;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    .step.completed .step-number {
        border-color: #4caf50;
        background-color: #4caf50;
        color: #fff;
        box-shadow: 0 0 15px 0 rgba(76, 175, 79, 0.5);
    }
    
    .step.active .step-number {
        border-color: #2196f3;
        background-color: #2196f3;
        color: white;
        box-shadow: 0 0 15px 0 rgba(33, 149, 243, 0.5);
    }
    
    .checkmark {
        font-size: 18px;
    }
    
    .step-label {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
    }
    
    .stepper::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -2;
    }
    
    .stepper .step.completed::after {
        animation: fillLine 0.5s forwards;
    }
    
    .stepper .step:not(.completed)::after {
        animation: unfillLine 0.5s forwards;
    }
    
    @keyframes fillLine {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
    
    @keyframes unfillLine {
        from {
            width: 100%;
        }
        to {
            width: 0;
        }
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    .stepper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    .step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
    }
    
    .step:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -1;
        transition: background-color 0.3s ease, width 0.5s ease;
    }
    
    .step.completed:not(:last-child)::after {
        background-color: #4caf50;
        width: 100%;
    }
    
    .step.active:not(:last-child)::after {
        width: 0;
    }
    
    .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: bold;
        position: relative;
        z-index: 1;
        border: 2px solid #ccc;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    .step.completed .step-number {
        border-color: #4caf50;
        background-color: #4caf50;
        color: #fff;
        box-shadow: 0 0 15px 0 rgba(76, 175, 79, 0.5);
    }
    
    .step.active .step-number {
        border-color: #2196f3;
        background-color: #2196f3;
        color: white;
        box-shadow: 0 0 15px 0 rgba(33, 149, 243, 0.5);
    }
    
    .checkmark {
        font-size: 18px;
    }
    
    .step-label {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
    }
    
    .stepper::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -2;
    }
    
    .stepper .step.completed::after {
        animation: fillLine 0.5s forwards;
    }
    
    .stepper .step:not(.completed)::after {
        animation: unfillLine 0.5s forwards;
    }
    
    @keyframes fillLine {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
    
    @keyframes unfillLine {
        from {
            width: 100%;
        }
        to {
            width: 0;
        }
    }
}

@media (min-width: 1450px) {
    .stepper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    
    .step {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
    }
    
    .step:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(50%);
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -1;
        transition: background-color 0.3s ease, width 0.5s ease;
    }
    
    .step.completed:not(:last-child)::after {
        background-color: #4caf50;
        width: 100%;
    }
    
    .step.active:not(:last-child)::after {
        width: 0;
    }
    
    .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: bold;
        position: relative;
        z-index: 1;
        border: 2px solid #ccc;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    .step.completed .step-number {
        border-color: #4caf50;
        background-color: #4caf50;
        color: #fff;
        box-shadow: 0 0 15px 0 rgba(76, 175, 79, 0.5);
    }
    
    .step.active .step-number {
        border-color: #2196f3;
        background-color: #2196f3;
        color: white;
        box-shadow: 0 0 15px 0 rgba(33, 149, 243, 0.5);
    }
    
    .checkmark {
        font-size: 18px;
    }
    
    .step-label {
        margin-top: 8px;
        font-size: 14px;
        text-align: center;
    }
    
    .stepper::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ccc;
        z-index: -2;
    }
    
    .stepper .step.completed::after {
        animation: fillLine 0.5s forwards;
    }
    
    .stepper .step:not(.completed)::after {
        animation: unfillLine 0.5s forwards;
    }
    
    @keyframes fillLine {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
    
    @keyframes unfillLine {
        from {
            width: 100%;
        }
        to {
            width: 0;
        }
    }
}

