@media (max-width: 575.98px) {
    div#calendarview-container {
        width: 100%;
        min-height: 300px;
        padding: 10px;
    }


    div#calendarview-daysofweek-area {
        width: 100%;
    }

    button#calendar-week-picker-btn {
        width: 60px;
        height: 100%;
        float: left;
    }

    ul#calendarview-daysoftweek-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
    }

    li.calendarview-daysoftweek-item {}

    button.calendarview-dayofweek-btn {
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 100px;
        color: black;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        aspect-ratio: 1/1;
    }

    button.calendarview-dayofweek-btn.active {
        background-color: black;
        color: white;
    }

    p.calendarview-dayofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        display: none;
    }

    p.calendarview-dateofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#calendarview-container {
        width: 100%;
        min-height: 300px;
        padding: 10px;
    }


    div#calendarview-daysofweek-area {
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
    }

    button#calendar-week-picker-btn {
        width: 100px;
        height: 100%;
        float: left;
    }

    ul#calendarview-daysoftweek-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
    }

    li.calendarview-daysoftweek-item {
        display: flex;
        align-content: center;
        justify-content: center;
    }

    button.calendarview-dayofweek-btn {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 100px;
        color: black;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        aspect-ratio: 1/1;
    }

    button.calendarview-dayofweek-btn.active {
        background-color: black;
        color: white;
    }

    p.calendarview-dayofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        display: none;
    }

    p.calendarview-dateofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#calendarview-container {
        width: 100%;
        min-height: 300px;
        padding: 10px;
    }


    div#calendarview-daysofweek-area {
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
    }

    button#calendar-week-picker-btn {
        width: 100px;
        height: 100%;
        float: left;
    }

    ul#calendarview-daysoftweek-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
    }

    li.calendarview-daysoftweek-item {
        display: flex;
        align-content: center;
        justify-content: center;
    }

    button.calendarview-dayofweek-btn {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 100px;
        color: black;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        aspect-ratio: 1/1;
    }

    button.calendarview-dayofweek-btn.active {
        background-color: black;
        color: white;
    }

    p.calendarview-dayofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        display: none;
    }

    p.calendarview-dateofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#calendarview-container {
        width: 100%;
        min-height: 300px;
        padding: 10px;
    }


    div#calendarview-daysofweek-area {
        width: 100%;
        height: 100px;
        margin-bottom: 50px;
    }

    button#calendar-week-picker-btn {
        width: 100px;
        height: 100%;
        float: left;
    }

    ul#calendarview-daysoftweek-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
    }

    li.calendarview-daysoftweek-item {}

    button.calendarview-dayofweek-btn {
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 20px;
        color: black;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.calendarview-dayofweek-btn.active {
        background-color: black;
        color: white;
    }

    p.calendarview-dayofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
    }

    p.calendarview-dateofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
    }
}

@media (min-width: 1200px) {
    div#calendarview-container {
        width: 100%;
        min-height: 300px;
        padding: 10px;
    }


    div#calendarview-daysofweek-area {
        width: 100%;
        height: 100px;
        margin-bottom: 50px;
    }

    button#calendar-week-picker-btn {
        width: 100px;
        height: 100%;
        float: left;
    }

    ul#calendarview-daysoftweek-listing-ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
    }

    li.calendarview-daysoftweek-item {}

    button.calendarview-dayofweek-btn {
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 20px;
        color: black;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    button.calendarview-dayofweek-btn.active {
        background-color: black;
        color: white;
    }

    p.calendarview-dayofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
    }

    p.calendarview-dateofweek-span {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 25px;
        font-weight: bold;
    }
}