@media (max-width: 575.98px) {
    div.menu-upload-container {
        width: 100%;
    }
    
    div.menu-upload-inner-div {
        width: 100%;
        margin: 0 auto;
    }
    
    nav#menu-upload-top-nav {
        height: 60px;
        margin: 0 20px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 20px;
    }
    
    div.menu-upload-content-div {
        padding: 0 20px;
    }
    
    ul.menu-upload-top-nav-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }
    
    li.menu-upload-top-nav-title-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h1.menu-upload-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    li.menu-upload-top-nav-add-menu-btn-li {
        width: 100px;
        height: 100%;
        float: right;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menu-upload-top-nav-add-menu-btn {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 110, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.menu-upload-top-nav-add-menu-btn:hover {
        background-color: rgb(0, 90, 235);
    }
    
    span.menu-upload-icon-span {
        font-size: 30px;
    }
    
    div.menu-upload-title-area {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
    }
    
    h3.menu-upload-title-item-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.4em;
        float: left;
    }
    
    button.menu-upload-delete-btn {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: white;
        border-radius: 50px;
        margin-left: 20px;
    }
    
    ul.menu-upload-content-listing-ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        margin-top: 20px;
    }
    
    div.menu-upload-incomplete-div {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.menu-upload-container {
        width: 100%;
    }
    
    div.menu-upload-inner-div {
        width: 100%;
        margin: 0 auto;
    }
    
    nav#menu-upload-top-nav {
        height: 60px;
        margin: 0 20px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 20px;
    }
    
    div.menu-upload-content-div {
        padding: 0 20px;
    }
    
    ul.menu-upload-top-nav-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }
    
    li.menu-upload-top-nav-title-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h1.menu-upload-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    li.menu-upload-top-nav-add-menu-btn-li {
        width: 100px;
        height: 100%;
        float: right;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menu-upload-top-nav-add-menu-btn {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 110, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.menu-upload-top-nav-add-menu-btn:hover {
        background-color: rgb(0, 90, 235);
    }
    
    span.menu-upload-icon-span {
        font-size: 30px;
    }
    
    div.menu-upload-title-area {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
    }
    
    h3.menu-upload-title-item-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.4em;
        float: left;
    }
    
    button.menu-upload-delete-btn {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: white;
        border-radius: 50px;
        margin-left: 20px;
    }
    
    ul.menu-upload-content-listing-ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        margin-top: 20px;
    }
    
    div.menu-upload-incomplete-div {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.menu-upload-container {
        width: 100%;
    }
    
    div.menu-upload-inner-div {
        width: 100%;
        margin: 0 auto;
    }
    
    nav#menu-upload-top-nav {
        height: 60px;
        margin: 0 20px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 20px;
    }
    
    div.menu-upload-content-div {
        padding: 0 20px;
    }
    
    ul.menu-upload-top-nav-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }
    
    li.menu-upload-top-nav-title-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h1.menu-upload-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    li.menu-upload-top-nav-add-menu-btn-li {
        width: 100px;
        height: 100%;
        float: right;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menu-upload-top-nav-add-menu-btn {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 110, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.menu-upload-top-nav-add-menu-btn:hover {
        background-color: rgb(0, 90, 235);
    }
    
    span.menu-upload-icon-span {
        font-size: 30px;
    }
    
    div.menu-upload-title-area {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
    }
    
    h3.menu-upload-title-item-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.4em;
        float: left;
    }
    
    button.menu-upload-delete-btn {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: white;
        border-radius: 50px;
        margin-left: 20px;
    }
    
    ul.menu-upload-content-listing-ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        margin-top: 20px;
    }
    
    div.menu-upload-incomplete-div {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.menu-upload-container {
        width: 100%;
    }
    
    div.menu-upload-inner-div {
        width: 100%;
        margin: 0 auto;
    }
    
    nav#menu-upload-top-nav {
        height: 60px;
        margin: 0 20px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 20px;
    }
    
    div.menu-upload-content-div {
        padding: 0 20px;
    }
    
    ul.menu-upload-top-nav-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }
    
    li.menu-upload-top-nav-title-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h1.menu-upload-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.5em;
    }
    
    li.menu-upload-top-nav-add-menu-btn-li {
        width: 100px;
        height: 100%;
        float: right;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menu-upload-top-nav-add-menu-btn {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 110, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.menu-upload-top-nav-add-menu-btn:hover {
        background-color: rgb(0, 90, 235);
    }
    
    span.menu-upload-icon-span {
        font-size: 30px;
    }
    
    div.menu-upload-title-area {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgray;
        display: flex;
        align-items: center;
    }
    
    h3.menu-upload-title-item-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 1.4em;
        float: left;
    }
    
    button.menu-upload-delete-btn {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: white;
        border-radius: 50px;
        margin-left: 20px;
    }
    
    ul.menu-upload-content-listing-ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        margin-top: 20px;
    }
    
    div.menu-upload-incomplete-div {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.menu-upload-container {
        width: 100%;
    }
    
    div.menu-upload-inner-div {
        width: 80%;
        margin: 0 auto;
    }
    
    nav#menu-upload-top-nav {
        width: 100%;
        height: 80px;
        padding: 0 20px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 50px;
    }
    
    div.menu-upload-content-div {
        padding: 0 50px;
    }
    
    ul.menu-upload-top-nav-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }
    
    li.menu-upload-top-nav-title-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h1.menu-upload-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 40px;
    }
    
    li.menu-upload-top-nav-add-menu-btn-li {
        width: 100px;
        height: 100%;
        float: right;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menu-upload-top-nav-add-menu-btn {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 110, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.menu-upload-top-nav-add-menu-btn:hover {
        background-color: rgb(0, 90, 235);
    }
    
    span.menu-upload-icon-span {
        font-size: 30px;
    }
    
    div.menu-upload-title-area {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgray;
    }
    
    h3.menu-upload-title-item-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 30px;
        float: left;
    }
    
    button.menu-upload-delete-btn {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: white;
        border-radius: 50px;
        margin-top: 12.5px;
        margin-left: 20px;
    }
    
    ul.menu-upload-content-listing-ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        margin-top: 20px;
    }
    
    div.menu-upload-incomplete-div {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1450px) {
    div.menu-upload-container {
        width: 100%;
    }
    
    div.menu-upload-inner-div {
        width: 50%;
        min-width: 1200px;
        margin: 0 auto;
    }
    
    nav#menu-upload-top-nav {
        width: 100%;
        height: 80px;
        padding: 0 20px;
        border-bottom: 1px solid lightgray;
        margin-bottom: 50px;
    }
    
    div.menu-upload-content-div {
        padding: 0 50px;
    }
    
    ul.menu-upload-top-nav-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 100%;
    }
    
    li.menu-upload-top-nav-title-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    
    h1.menu-upload-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 40px;
    }
    
    li.menu-upload-top-nav-add-menu-btn-li {
        width: 100px;
        height: 100%;
        float: right;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.menu-upload-top-nav-add-menu-btn {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 110, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.menu-upload-top-nav-add-menu-btn:hover {
        background-color: rgb(0, 90, 235);
    }
    
    span.menu-upload-icon-span {
        font-size: 30px;
    }
    
    div.menu-upload-title-area {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid lightgray;
    }
    
    h3.menu-upload-title-item-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: black;
        font-size: 30px;
        float: left;
    }
    
    button.menu-upload-delete-btn {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        color: white;
        border-radius: 50px;
        margin-top: 12.5px;
        margin-left: 20px;
    }
    
    ul.menu-upload-content-listing-ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        margin-top: 20px;
    }
    
    div.menu-upload-incomplete-div {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}