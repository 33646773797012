@media (max-width: 575.98px) {
    div.home-briefing-title-container {
        position: -webkit-sticky;
        position: sticky;
        top: 150px;
    }

    h1.home-briefing-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-briefing-stack-container {
        width: 90%;
        margin: 0 auto;
        position: relative;
    }

    div.home-briefing-stack-card {
        width: 100%;
        min-height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        border-radius: 40px;
        padding: 30px;
    }

    div.home-briefing-card-text-area {
        width: 100%;
        float: left;
    }

    p.home-briefing-card-small-top-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    h1.home-briefing-card-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    p.home-briefing-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    div.home-briefing-mockup-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-briefing-mockup-img {
        height: auto;
        width: 100%;
        margin-top: 50px;
    }

    div.home-briefing-stack-card.is-first {
        background-color: #8ab2ff;
        top: 50px;
    }

    div.home-briefing-stack-card.is-extra-space {
        z-index: -1;
        border-radius: 0;
        height: 50vh;
        top: 400px;
    }

    div.home-briefing-stack-card.is-second {
        background-color: #da73ff;
        margin-top: -300px;
    }

    div.home-briefing-stack-card.is-third {
        background-color: #ffa3f1;
        margin-top: -685px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.home-briefing-title-container {
        position: -webkit-sticky;
        position: sticky;
        top: 150px;
    }

    h1.home-briefing-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-briefing-stack-container {
        width: 90%;
        margin: 0 auto;
        position: relative;
    }

    div.home-briefing-stack-card {
        width: 100%;
        min-height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        border-radius: 50px;
        padding: 50px 50px 50px 50px;
    }

    div.home-briefing-card-text-area {
        width: 100%;
        float: left;
    }

    p.home-briefing-card-small-top-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    h1.home-briefing-card-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    p.home-briefing-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    div.home-briefing-mockup-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-briefing-mockup-img {
        height: auto;
        width: 60%;
        margin-top: 50px;
    }

    div.home-briefing-stack-card.is-first {
        background-color: #8ab2ff;
        top: 50px;
    }

    div.home-briefing-stack-card.is-extra-space {
        z-index: -1;
        border-radius: 0;
        height: 50vh;
        top: 400px;
    }

    div.home-briefing-stack-card.is-second {
        background-color: #da73ff;
        margin-top: -300px;
    }

    div.home-briefing-stack-card.is-third {
        background-color: #ffa3f1;
        margin-top: -685px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.home-briefing-title-container {
        position: -webkit-sticky;
        position: sticky;
        top: 150px;
    }

    h1.home-briefing-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-briefing-stack-container {
        width: 90%;
        margin: 0 auto;
        position: relative;
    }

    div.home-briefing-stack-card {
        width: 100%;
        min-height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        border-radius: 50px;
        padding: 50px 50px 50px 50px;
    }

    div.home-briefing-card-text-area {
        width: 100%;
        float: left;
    }

    p.home-briefing-card-small-top-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    h1.home-briefing-card-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    p.home-briefing-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    div.home-briefing-mockup-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-briefing-mockup-img {
        height: auto;
        width: 60%;
        margin-top: 50px;
    }

    div.home-briefing-stack-card.is-first {
        background-color: #8ab2ff;
        top: 50px;
    }

    div.home-briefing-stack-card.is-extra-space {
        z-index: -1;
        border-radius: 0;
        height: 50vh;
        top: 400px;
    }

    div.home-briefing-stack-card.is-second {
        background-color: #da73ff;
        margin-top: -300px;
    }

    div.home-briefing-stack-card.is-third {
        background-color: #ffa3f1;
        margin-top: -685px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.home-briefing-title-container {
        position: -webkit-sticky;
        position: sticky;
        top: 150px;
    }

    h1.home-briefing-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-briefing-stack-container {
        width: 90%;
        margin: 0 auto;
        position: relative;
    }

    div.home-briefing-stack-card {
        width: 100%;
        min-height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        border-radius: 50px;
        padding: 50px 50px 50px 50px;
    }

    div.home-briefing-card-text-area {
        width: 100%;
        float: left;
    }

    p.home-briefing-card-small-top-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    h1.home-briefing-card-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    p.home-briefing-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    div.home-briefing-mockup-area {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-briefing-mockup-img {
        height: auto;
        width: 60%;
        margin-top: 50px;
    }

    div.home-briefing-stack-card.is-first {
        background-color: #8ab2ff;
        top: 50px;
    }

    div.home-briefing-stack-card.is-extra-space {
        z-index: -1;
        border-radius: 0;
        height: 50vh;
        top: 400px;
    }

    div.home-briefing-stack-card.is-second {
        background-color: #da73ff;
        margin-top: -300px;
    }

    div.home-briefing-stack-card.is-third {
        background-color: #ffa3f1;
        margin-top: -685px;
    }
}

@media (min-width: 1200px) {
    div.home-briefing-title-container {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        height: auto;
    }

    h1.home-briefing-title-h1 {
        font-size: 40px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-briefing-stack-container {
        width: 90%;
        margin: 0 auto;
        position: relative;
    }

    div.home-briefing-stack-card {
        width: 100%;
        height: 70vh;
        position: -webkit-sticky;
        position: sticky;
        top: 160px;
        border-radius: 20px;
        padding: 50px;
        display: flex;
        flex-direction: row;
    }

    div.home-briefing-card-text-area {
        width: 45%;
    }

    p.home-briefing-card-small-top-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    h1.home-briefing-card-title-h1 {
        font-size: 32px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    p.home-briefing-card-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    div.home-briefing-mockup-area {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-briefing-mockup-img {
        max-width: 70%;
        max-height: 100%;
    }

    div.home-briefing-stack-card.is-first {
        background-color: #8ab2ff;
    }

    div.home-briefing-stack-card.is-extra-space {
        z-index: -1;
        border-radius: 0;
        height: 50vh;
        top: 400px;
    }

    div.home-briefing-stack-card.is-second {
        background-color: #da73ff;
        margin-top: -490px;
    }

    div.home-briefing-stack-card.is-extra-space-third {
        z-index: -1;
        border-radius: 0;
        height: 50vh;
        top: 400px;
    }

    div.home-briefing-stack-card.is-third {
        background-color: #ffa3f1;
        margin-top: -490px;
    }
}