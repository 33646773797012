@media (max-width: 575.98px) {
    li.notes-list-item {
        width: calc(100% - 20px);
        /* Subtract double the padding from width */
        background-color: rgb(234, 211, 87);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        margin-right: 10px;
        /* Add right margin equal to padding */
        margin-left: 10px;
        /* Add left margin equal to padding */
        margin-top: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: relative;
        min-height: 80px;
    }

    h3.notes-list-item-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        font-size: 12px;
    }

    div.notes-under-bar-options-area {
        width: 100%;
        height: 35px;
        position: relative;
    }

    p.notes-list-item-created-at {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 12px;
        margin: 0;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        position: absolute;
    }

    button.note-markdone-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: transparent;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    button.note-markdone-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    li.notes-list-item {
        width: calc(100% - 20px);
        /* Subtract double the padding from width */
        background-color: rgb(234, 211, 87);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        margin-right: 10px;
        /* Add right margin equal to padding */
        margin-left: 10px;
        /* Add left margin equal to padding */
        margin-top: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: relative;
        min-height: 100px;
    }

    h3.notes-list-item-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        font-size: 14px;
    }

    p.notes-list-item-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        margin-top: 10px;
    }

    div.notes-under-bar-options-area {
        width: 100%;
        height: 35px;
        position: relative;
    }

    p.notes-list-item-created-at {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 12px;
        margin: 0;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        position: absolute;
    }

    button.note-markdone-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: transparent;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    button.note-markdone-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    li.notes-list-item {
        width: calc(100% - 20px);
        /* Subtract double the padding from width */
        background-color: rgb(234, 211, 87);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        margin-right: 10px;
        /* Add right margin equal to padding */
        margin-left: 10px;
        /* Add left margin equal to padding */
        margin-top: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: relative;
        min-height: 100px;
    }

    h3.notes-list-item-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
    }

    p.notes-list-item-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        margin-top: 10px;
    }

    div.notes-under-bar-options-area {
        width: 100%;
        height: 35px;
        position: relative;
    }

    p.notes-list-item-created-at {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 12px;
        margin: 0;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        position: absolute;
    }

    button.note-markdone-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: transparent;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    button.note-markdone-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    li.notes-list-item {
        width: calc(100% - 20px);
        /* Subtract double the padding from width */
        background-color: rgb(234, 211, 87);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        margin-right: 10px;
        /* Add right margin equal to padding */
        margin-left: 10px;
        /* Add left margin equal to padding */
        margin-top: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: relative;
        min-height: 100px;
    }

    h3.notes-list-item-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
    }

    p.notes-list-item-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        margin-top: 10px;
    }

    div.notes-under-bar-options-area {
        width: 100%;
        height: 35px;
        position: relative;
    }

    p.notes-list-item-created-at {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 12px;
        margin: 0;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        position: absolute;
    }

    button.note-markdone-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: transparent;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    button.note-markdone-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

@media (min-width: 1200px) {
    li.notes-list-item {
        width: calc(100% - 20px);
        /* Subtract double the padding from width */
        background-color: rgb(234, 211, 87);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        margin-right: 10px;
        /* Add right margin equal to padding */
        margin-left: 10px;
        /* Add left margin equal to padding */
        margin-top: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: relative;
        min-height: 100px;
    }

    h3.notes-list-item-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        font-size: 18px;
    }

    p.notes-list-item-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        margin-top: 10px;
    }

    div.notes-under-bar-options-area {
        width: 100%;
        height: 35px;
        position: relative;
    }

    p.notes-list-item-created-at {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 12px;
        margin: 0;
        margin-top: 5px;
        color: rgba(0, 0, 0, 0.5);
        bottom: 0;
        left: 0;
        position: absolute;
    }

    button.note-markdone-btn {
        width: 35px;
        height: 35px;
        border-radius: 25px;
        background-color: transparent;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        float: left;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    button.note-markdone-btn:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

li.notes-list-item.note-type-1 {
    background-color: rgb(0, 208, 17);
}

li.notes-list-item.note-type-2 {
    background-color: rgb(227, 121, 0);
}

li.notes-list-item.note-type-3 {
    background-color: rgb(226, 211, 0);
}