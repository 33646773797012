@media (max-width: 575.98px) {

    html,
    body {
        margin: 0;
        background-color: #eee;
        scroll-behavior: smooth;
    }

    section.home-hero-section {
        width: 100%;
        min-height: calc(100vh - 120px);
        padding: 0 40px;
    }

    div.home-hero-container {
        width: 100%;
        min-height: inherit;
    }

    img.home-hero-img {
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        transform: translateX(-50%) translateY(-50%);
    }


    div.home-hero-text-sections-area {
        width: 100%;
        min-height: inherit;
    }

    div.home-hero-top-section {
        width: 100%;
        margin-top: 20px;
    }

    div.home-hero-left-text-section {
        width: 100%;
    }

    div.home-hero-model-area {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    
    div.home-hero-model-wrapper {
        min-height: 100px;
        margin: 0 auto;
    }
    
    div.home-hero-display-mac {
        position: relative;
        width: 100%;
    }
    
    img.home-hero-display-mac-canvas-img {
        width: 100%;
        height: auto;
        top: 0;
        z-index: 10;
    }
    
    video.home-hero-display-mac-video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        padding: 4.3%;
    }

    div.home-hero-left-inner-text-container {}

    div.home-hero-middle-text-section {}

    h1.home-hero-title-h1 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    p.home-hero-txt-p {
        font-size: 15px;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .home-hero-cta-link {
        width: 100%;
        margin-top: 50px;
    }
    button.home-hero-cta-btn {
        height: 45px;
        width: 80%;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;    }

    button.home-hero-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    div.home-hero-right-text-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }

    div.home-hero-right-content {
        width: 375px;
    }

    div.home-hero-right-inner-text-container {
        width: 70%;
    }

    h3.home-hero-title-h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    img.home-hero-right-img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    div.home-hero-under-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2.home-hero-right-title-h2 {
        font-size: 25px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.home-hero-under-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    li.home-hero-under-item-li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 20px;
        position: relative;
    }

    h4.home-hero-under-item-txt-h4 {
        font-size: 16px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    span.home-hero-under-icon-span {
        font-size: 30px;
        margin-left: 10px;
        position: absolute;
        right: 10px;
        display: none;
    }

    li.home-hero-under-item-li.briefing {
        background-color: rgba(54, 104, 255, 0.35);
        border: 3px solid #3668ff;
        transform: translateX(-20px);
    }

    li.home-hero-under-item-li.briefing span.home-hero-under-icon-span {
        color: #3668ff;
    }

    li.home-hero-under-item-li.things {
        background-color: rgba(194, 80, 255, 0.35);
        border: 3px solid #c250ff;
        transform: translateX(-50px);
    }

    li.home-hero-under-item-li.things span.home-hero-under-icon-span {
        color: #c250ff;
    }

    li.home-hero-under-item-li.report {
        background-color: #fbd4f5;
        border: 3px solid #f689e6;
    }

    li.home-hero-under-item-li.report span.home-hero-under-icon-span {
        color: #f689e6;
    }

    section.home-briefing-section {
        width: 100%;
        min-height: 100vh;
        position: relative;
    }

    section.home-features-section {
        width: 100%;
        background-color: black;
        padding: 100px 0;
        margin: 100px 0;
        overflow: hidden;
    }

    div.inner-home-features-area {
        width: 80%;
        height: 100%;
        margin: 0 auto;
    }

    div.inner-home-features-text-area {
        width: 100%;
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1.home-features-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 30px;
        color: white;
        text-align: center;
    }

    div.inner-home-feature-bar-area {
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
    }

    h2.inner-home-feature-title-h2 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    p.inner-home-feature-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    div.inner-home-features-mockup-area {
        width: 100%;
        float: left;
    }

    section.home-partners-section {
        width: 100%;
        margin-top: 10px;
    }

    h1.home-partners-title-h1 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-partners-inner-div {
        display: grid;
        width: 50%;
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 50px;
    }

    div.home-partner-item-div {
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-partner-item-img {
        width: 150px;
        max-width: 90%;
    }

    section.home-pricing-section {
        width: 100%;
        min-height: 100vh;
    }

    h1.home-pricing-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 20px;
    }

    p.home-pricing-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        width: 80%;
        margin: 0 auto;
    }

    div.home-pricing-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.home-pricing-card {
        width: 85%;
        min-height: 400px;
        border-radius: 30px;
        padding: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background-color: white;
    }

    div.home-pricing-top-area {
        width: 100%;
    }

    h2.home-pricing-title-h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-pricing-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    hr.home-pricing-divider {
        width: 100%;
        margin: 20px 0;
        border-color: rgb(182, 182, 182);
    }

    div.home-pricing-bottom-area {
        width: 100%;
    }

    div.home-pricing-price-area {
        height: 50px;
        width: 100%;
    }

    p.home-pricing-price-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    span.home-pricing-price-span {
        font-size: 35px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    div.home-pricing-product-percs {
        width: 100%;
        margin-top: 20px;
    }

    ul.home-pricing-percs-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }

    li.home-pricing-percs-item-li {}

    span.home-pricing-percs-icon-span {
        font-size: 30px;
        margin-right: 10px;
        float: left;
        color: rgb(0, 81, 255);
        line-height: 30px;
    }

    p.home-pricing-percs-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
        line-height: 30px;
    }

    button.home-pricing-cta-btn {
        width: 100%;
        height: 45px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
        padding: 0 20px;
    }

    button.home-pricing-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    .subscription-type-tabs {
        margin-top: 50px;
    }

    section.home-things-section {
        width: 100%;
        min-height: 300px;
    }

    div.home-things-stack-container {
        margin: 0 auto;
        padding: 40px 0;
        position: relative;
    }

    div.home-things-title-container {}

    h1.home-things-title-h1 {
        font-size: 25px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-things-stack-card-container {
        width: 100%;
        height: 330px;
    }

    h1.home-things-stack-card-inner-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 20px;
    }

    div.home-things-stack-card-inner-area {
        width: 90%;
        height: 500px;
        margin: 0 auto;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 50px;
        box-sizing: border-box;
    }

    div.home-things-stack-card-inner-area.signature {
        background-color: #3737ff8d;
    }

    div.home-things-stack-card-inner-area.suggestion {
        background-color: #ba18fa8d;
    }

    div.home-things-stack-card-inner-area.task {
        background-color: #00d02a8d;
    }

    div.home-things-stack-card-inner-area.process {
        background-color: #d000008d;
    }

    div.home-things-stack-card-inner-area.thing {
        background-color: #e764008d;
    }

    div.home-things-stack-card-inner-thing-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-thing-div {
        width: 100%;
        min-height: 100px;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
    }

    div.home-things-thing-div.signture-card {
        background-color: #3737ff;
    }

    div.home-things-thing-div.suggestion-card {
        background-color: #ba18fa;
    }

    div.home-things-thing-div.task-card {
        background-color: #00d02a;
    }

    div.home-things-thing-div.process-card {
        background-color: #d00000;
    }

    div.home-things-thing-div.thing-card {
        background-color: #e76400;
    }

    div.home-things-thing-div-title-area {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid lightgray;
    }

    div.home-things-thing-div-fake-title-div {
        width: 40%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-txt-area {
        width: 100%;
        margin-top: 10px;
    }

    div.home-things-thing-div-fake-txt-long-div {
        width: 90%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-fake-txt-short-div {
        width: 60%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
        margin-top: 10px;
    }

    div.home-things-stack-card-inner-text-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-stack-card-inner-text-area.right {}

    div.home-things-stack-card-inner-text-area.left {}

    p.home-things-stack-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .review-section {
        width: 100%;
        padding: 20px 0;
        /* background-color: #f9f9f9;  */
    }
    
    .review-inner-div {
        width: 90%; 
        margin: 0 auto; 
        padding: 0px;
        display: flex;
    }
    
    .review-item-div {
        background-color: #fff; 
        border-radius: 10px; 
        padding: 40px; 
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        width: 100%;
        height: inherit;
    }
    
    .review-item-div .left-quarter {
        width: 25%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
    }
    
    section.home-10things-section {
        width: 100%;
        min-height: 200px;
        margin-top: 50px;
    }

    img.home-10things-focus-img {
        width: 90%;
        margin: 0 auto;
    }

    p.home-10things-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    div.home-10things-content-div {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    ul.home-10things-content-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    li.home-10things-content-item-li {
        padding: 30px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 30px;
    }

    img.home-10things-icon-img {
        height: 100px;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    h5.home-10things-title-h5 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-10things-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    
}

@media (min-width: 576px) and (max-width: 767.98px) {

    html,
    body {
        margin: 0;
        background-color: #eee;
        scroll-behavior: smooth;
    }

    section.home-hero-section {
        width: 100%;
        min-height: calc(100vh - 120px);
        padding: 0 40px;
    }

    div.home-hero-container {
        width: 100%;
    }

    img.home-hero-img {
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        transform: translateX(-50%) translateY(-50%);
    }


    div.home-hero-text-sections-area {
        width: 100%;
    }

    div.home-hero-top-section {
        width: 100%;
        margin-top: 20px;
    }

    div.home-hero-left-text-section {
        width: 100%;
    }

    div.home-hero-model-area {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    
    div.home-hero-model-wrapper {
        min-height: 100px;
        margin: 0 auto;
        width: 75%;
    }
    
    div.home-hero-display-mac {
        position: relative;
        width: 100%;
    }
    
    img.home-hero-display-mac-canvas-img {
        width: 100%;
        height: auto;
        top: 0;
        z-index: 10;
    }
    
    video.home-hero-display-mac-video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        padding: 4.3%;
    }

    div.home-hero-left-inner-text-container {}

    div.home-hero-middle-text-section {}

    h1.home-hero-title-h1 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    p.home-hero-txt-p {
        font-size: 15px;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .home-hero-cta-link {
        width: 100%;
        margin-top: 50px;
    }

    button.home-hero-cta-btn {
        height: 45px;
        width: 100%;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
    }

    button.home-hero-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    div.home-hero-right-text-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.home-hero-right-content {
        width: 375px;
    }

    div.home-hero-right-inner-text-container {
        width: 70%;
    }

    h3.home-hero-title-h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    img.home-hero-right-img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    div.home-hero-under-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2.home-hero-right-title-h2 {
        font-size: 1.1em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.home-hero-under-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }

    li.home-hero-under-item-li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 20px;
        position: relative;
    }

    h4.home-hero-under-item-txt-h4 {
        font-size: 0.9em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    span.home-hero-under-icon-span {
        font-size: 30px;
        margin-left: 10px;
        position: absolute;
        right: 10px;
        display: none;
    }

    li.home-hero-under-item-li.briefing {
        background-color: rgba(54, 104, 255, 0.35);
        border: 3px solid #3668ff;
        transform: translateX(-20px);
    }

    li.home-hero-under-item-li.briefing span.home-hero-under-icon-span {
        color: #3668ff;
    }

    li.home-hero-under-item-li.things {
        background-color: rgba(194, 80, 255, 0.35);
        border: 3px solid #c250ff;
        transform: translateX(-50px);
    }

    li.home-hero-under-item-li.things span.home-hero-under-icon-span {
        color: #c250ff;
    }

    li.home-hero-under-item-li.report {
        background-color: #fbd4f5;
        border: 3px solid #f689e6;
    }

    li.home-hero-under-item-li.report span.home-hero-under-icon-span {
        color: #f689e6;
    }

    section.home-briefing-section {
        width: 100%;
        min-height: 100vh;
        position: relative;
    }

    section.home-features-section {
        width: 100%;
        background-color: black;
        padding: 100px 0;
        margin: 100px 0;
        overflow: hidden;
    }

    div.inner-home-features-area {
        width: 80%;
        height: 100%;
        margin: 0 auto;
    }

    div.inner-home-features-text-area {
        width: 100%;
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1.home-features-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 30px;
        color: white;
        text-align: center;
    }

    div.inner-home-feature-bar-area {
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
    }

    h2.inner-home-feature-title-h2 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    p.inner-home-feature-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    div.inner-home-features-mockup-area {
        width: 100%;
        float: left;
    }

    section.home-partners-section {
        width: 100%;
    }

    h1.home-partners-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        padding: 10px;
    }

    div.home-partners-inner-div {
        display: grid;
        width: 80%;
        margin: 0 auto;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-top: 50px;
    }

    div.home-partner-item-div {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-partner-item-img {
        width: auto;

    }

    section.home-pricing-section {
        width: 100%;
        min-height: 100vh;
    }

    h1.home-pricing-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 20px;
    }

    p.home-pricing-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-pricing-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.home-pricing-card {
        width: 85%;
        min-height: 400px;
        border-radius: 30px;
        padding: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background-color: white;
    }

    div.home-pricing-top-area {
        width: 100%;
    }

    h2.home-pricing-title-h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-pricing-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    hr.home-pricing-divider {
        width: 100%;
        margin: 20px 0;
        border-color: rgb(182, 182, 182);
    }

    div.home-pricing-bottom-area {
        width: 100%;
    }

    div.home-pricing-price-area {
        height: 50px;
        width: 100%;
    }

    p.home-pricing-price-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    span.home-pricing-price-span {
        font-size: 35px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    div.home-pricing-product-percs {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    ul.home-pricing-percs-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }

    li.home-pricing-percs-item-li {}

    span.home-pricing-percs-icon-span {
        font-size: 30px;
        margin-right: 10px;
        float: left;
        color: rgb(0, 81, 255);
        line-height: 30px;
    }

    p.home-pricing-percs-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
        line-height: 30px;
    }

    button.home-pricing-cta-btn {
        width: 100%;
        height: 45px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
        padding: 0 20px;
    }

    button.home-pricing-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    .subscription-type-tabs {
        margin-top: 50px;
    }

    section.home-things-section {
        width: 100%;
        min-height: 300px;
    }

    div.home-things-stack-container {
        margin: 0 auto;
        padding: 40px 0;
        position: relative;
    }

    div.home-things-title-container {}

    h1.home-things-title-h1 {
        font-size: 25px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-things-stack-card-container {
        width: 100%;
        height: 330px;
    }

    h1.home-things-stack-card-inner-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 20px;
    }

    div.home-things-stack-card-inner-area {
        width: 90%;
        height: 500px;
        margin: 0 auto;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 50px;
        box-sizing: border-box;
    }

    div.home-things-stack-card-inner-area.signature {
        background-color: #3737ff8d;
    }

    div.home-things-stack-card-inner-area.suggestion {
        background-color: #ba18fa8d;
    }

    div.home-things-stack-card-inner-area.task {
        background-color: #00d02a8d;
    }

    div.home-things-stack-card-inner-area.process {
        background-color: #d000008d;
    }

    div.home-things-stack-card-inner-area.thing {
        background-color: #e764008d;
    }

    div.home-things-stack-card-inner-thing-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-thing-div {
        width: 100%;
        min-height: 100px;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
    }

    div.home-things-thing-div.signture-card {
        background-color: #3737ff;
    }

    div.home-things-thing-div.suggestion-card {
        background-color: #ba18fa;
    }

    div.home-things-thing-div.task-card {
        background-color: #00d02a;
    }

    div.home-things-thing-div.process-card {
        background-color: #d00000;
    }

    div.home-things-thing-div.thing-card {
        background-color: #e76400;
    }

    div.home-things-thing-div-title-area {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid lightgray;
    }

    div.home-things-thing-div-fake-title-div {
        width: 40%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-txt-area {
        width: 100%;
        margin-top: 10px;
    }

    div.home-things-thing-div-fake-txt-long-div {
        width: 90%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-fake-txt-short-div {
        width: 60%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
        margin-top: 10px;
    }

    div.home-things-stack-card-inner-text-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-stack-card-inner-text-area.right {}

    div.home-things-stack-card-inner-text-area.left {}

    p.home-things-stack-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .review-section {
        width: 100%;
        padding: 20px 0;
        /* background-color: #f9f9f9;  */
    }
    
    .review-inner-div {
        width: 90%; 
        margin: 0 auto; 
        padding: 0px;
        display: flex;
    }
    
    .review-item-div {
        background-color: #fff; 
        border-radius: 10px; 
        padding: 40px; 
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        height: inherit;
    }
    
    .review-item-div .left-quarter {
        width: 25%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
    }
    

    section.home-10things-section {
        width: 100%;
        min-height: 200px;
        margin-top: 50px;
    }

    img.home-10things-focus-img {
        width: 90%;
        margin: 0 auto;
    }

    p.home-10things-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    div.home-10things-content-div {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    ul.home-10things-content-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    li.home-10things-content-item-li {
        padding: 30px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 30px;
    }

    img.home-10things-icon-img {
        height: 100px;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    h5.home-10things-title-h5 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-10things-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .review-section {
        width: 100%;
        padding: 20px 0;
        /* background-color: #f9f9f9;  */
    }
    
    .review-inner-div {
        width: 90%; 
        margin: 0 auto; 
        padding: 0px;
        display: flex;
    }
    
    .review-item-div {
        background-color: #fff; 
        border-radius: 10px; 
        padding: 40px; 
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        width: 100%;
        height: inherit;
    }
    
    .review-item-div .left-quarter {
        width: 100%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    html,
    body {
        margin: 0;
        background-color: #eee;
        scroll-behavior: smooth;
    }

    section.home-hero-section {
        width: 100%;
        min-height: calc(100vh - 100px);
        padding: 0 40px;
    }

    div.home-hero-container {
        width: 100%;
    }

    img.home-hero-img {
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        transform: translateX(-50%) translateY(-50%);
    }


    div.home-hero-text-sections-area {
        width: 100%;
    }

    div.home-hero-top-section {
        width: 100%;
    }

    div.home-hero-left-text-section {
        width: 100%;
        margin-bottom: 50px;
    }

    div.home-hero-model-area {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    
    div.home-hero-model-wrapper {
        min-height: 100px;
        margin: 0 auto;
        width: 60%;
    }
    
    div.home-hero-display-mac {
        position: relative;
        width: 100%;
    }
    
    img.home-hero-display-mac-canvas-img {
        width: 100%;
        height: auto;
        top: 0;
        z-index: 10;
    }
    
    video.home-hero-display-mac-video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        padding: 4.3%;
    }

    div.home-hero-left-inner-text-container {
        width: 100%;
        margin: 0 auto;
    }

    div.home-hero-middle-text-section {
        width: 60%;
        float: left;
    }

    h1.home-hero-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    p.home-hero-txt-p {
        font-size: 1.2em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .home-hero-cta-link {
        margin-top: 50px;
    }

    button.home-hero-cta-btn {
        height: 50px;
        padding: 0 30px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 1.4em;
    }

    button.home-hero-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    div.home-hero-right-text-section {
        width: 40%;
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: 30px;
        float: left;
    }

    div.home-hero-right-content {
        width: 375px;
        max-width: 90%;
    }

    div.home-hero-right-inner-text-container {
        width: 70%;
    }

    h3.home-hero-title-h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    img.home-hero-right-img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    div.home-hero-under-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2.home-hero-right-title-h2 {
        font-size: 1.1em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.home-hero-under-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }

    li.home-hero-under-item-li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 20px;
        position: relative;
    }

    h4.home-hero-under-item-txt-h4 {
        font-size: 0.9em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    span.home-hero-under-icon-span {
        font-size: 30px;
        margin-left: 10px;
        position: absolute;
        right: 10px;
        display: none;
    }

    li.home-hero-under-item-li.briefing {
        background-color: rgba(54, 104, 255, 0.35);
        border: 3px solid #3668ff;
        transform: translateX(-20px);
    }

    li.home-hero-under-item-li.briefing span.home-hero-under-icon-span {
        color: #3668ff;
    }

    li.home-hero-under-item-li.things {
        background-color: rgba(194, 80, 255, 0.35);
        border: 3px solid #c250ff;
        transform: translateX(-50px);
    }

    li.home-hero-under-item-li.things span.home-hero-under-icon-span {
        color: #c250ff;
    }

    li.home-hero-under-item-li.report {
        background-color: #fbd4f5;
        border: 3px solid #f689e6;
    }

    li.home-hero-under-item-li.report span.home-hero-under-icon-span {
        color: #f689e6;
    }

    section.home-briefing-section {
        width: 100%;
        min-height: 100vh;
        position: relative;
    }

    section.home-features-section {
        width: 100%;
        background-color: black;
        padding: 100px 0;
        margin: 50px 0;
        overflow: hidden;
    }

    div.inner-home-features-area {
        width: 80%;
        height: 100%;
        margin: 0 auto;
    }

    div.inner-home-features-text-area {
        width: 40%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1.home-features-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 50px;
        color: white;
        text-align: center;
    }

    div.inner-home-feature-bar-area {
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
    }

    h2.inner-home-feature-title-h2 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    p.inner-home-feature-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    div.inner-home-features-mockup-area {
        width: 60%;
        float: left;
    }

    img.inner-home-features-mockup-img {
        width: 70%;
        margin: 0 auto;
    }

    section.home-partners-section {
        width: 100%;
    }

    h1.home-partners-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        padding: 10px;
    }

    div.home-partners-inner-div {
        display: grid;
        width: 80%;
        margin: 0 auto;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        margin-top: 50px;
    }

    div.home-partner-item-div {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-partner-item-img {
        width: auto;

    }

    section.home-pricing-section {
        width: 100%;
        min-height: 100vh;
    }

    h1.home-pricing-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 20px;
    }

    p.home-pricing-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-pricing-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.home-pricing-card {
        width: 400px;
        max-width: 85%;
        min-height: 400px;
        border-radius: 30px;
        padding: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background-color: white;
    }

    div.home-pricing-top-area {
        width: 100%;
    }

    h2.home-pricing-title-h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-pricing-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    hr.home-pricing-divider {
        width: 100%;
        margin: 20px 0;
        border-color: rgb(182, 182, 182);
    }

    div.home-pricing-bottom-area {
        width: 100%;
    }

    div.home-pricing-price-area {
        height: 50px;
        width: 100%;
    }

    p.home-pricing-price-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    span.home-pricing-price-span {
        font-size: 35px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    div.home-pricing-product-percs {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    ul.home-pricing-percs-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }

    li.home-pricing-percs-item-li {}

    span.home-pricing-percs-icon-span {
        font-size: 30px;
        margin-right: 10px;
        float: left;
        color: rgb(0, 81, 255);
        line-height: 30px;
    }

    p.home-pricing-percs-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
        line-height: 30px;
    }

    button.home-pricing-cta-btn {
        width: 100%;
        height: 45px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
        padding: 0 20px;
    }

    button.home-pricing-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    .subscription-type-tabs {
        margin-top: 50px;
    }

    section.home-things-section {
        width: 100%;
        min-height: 300px;
    }

    div.home-things-stack-container {
        margin: 0 auto;
        padding: 40px 0;
        position: relative;
    }

    div.home-things-title-container {}

    h1.home-things-title-h1 {
        font-size: 25px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-things-stack-card-container {
        width: 60%;
        height: 380px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    h1.home-things-stack-card-inner-title-h1 {
        font-size: 2em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-things-stack-card-inner-area {
        width: 90%;
        height: 500px;
        margin: 0 auto;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 50px;
        box-sizing: border-box;
    }

    div.home-things-stack-card-inner-area.signature {
        background-color: #3737ff8d;
    }

    div.home-things-stack-card-inner-area.suggestion {
        background-color: #ba18fa8d;
    }

    div.home-things-stack-card-inner-area.task {
        background-color: #00d02a8d;
    }

    div.home-things-stack-card-inner-area.process {
        background-color: #d000008d;
    }

    div.home-things-stack-card-inner-area.thing {
        background-color: #e764008d;
    }

    div.home-things-stack-card-inner-thing-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-thing-div {
        width: 100%;
        min-height: 100px;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
    }

    div.home-things-thing-div.signture-card {
        background-color: #3737ff;
    }

    div.home-things-thing-div.suggestion-card {
        background-color: #ba18fa;
    }

    div.home-things-thing-div.task-card {
        background-color: #00d02a;
    }

    div.home-things-thing-div.process-card {
        background-color: #d00000;
    }

    div.home-things-thing-div.thing-card {
        background-color: #e76400;
    }

    div.home-things-thing-div-title-area {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid lightgray;
    }

    div.home-things-thing-div-fake-title-div {
        width: 40%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-txt-area {
        width: 100%;
        margin-top: 10px;
    }

    div.home-things-thing-div-fake-txt-long-div {
        width: 90%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-fake-txt-short-div {
        width: 60%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
        margin-top: 10px;
    }

    div.home-things-stack-card-inner-text-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-stack-card-inner-text-area.right {}

    div.home-things-stack-card-inner-text-area.left {}

    p.home-things-stack-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .review-section {
        width: 100%;
        padding: 20px 0;
        /* background-color: #f9f9f9;  */
    }
    
    .review-inner-div {
        width: 90%; 
        margin: 0 auto; 
        padding: 0px;
        display: flex;
    }
    
    .review-item-div {
        background-color: #fff; 
        border-radius: 10px; 
        padding: 40px; 
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        height: inherit;
    }
    
    .review-item-div .left-quarter {
        width: 25%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
    }
    

    section.home-10things-section {
        width: 100%;
        min-height: 200px;
        padding: 100px 0;
        margin-top: 50px;
    }

    img.home-10things-focus-img {
        width: 500px;
        margin: 0 auto;
    }

    p.home-10things-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    div.home-10things-content-div {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    ul.home-10things-content-listing-ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    li.home-10things-content-item-li {
        padding: 30px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 30px;
    }

    img.home-10things-icon-img {
        height: 100px;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    h5.home-10things-title-h5 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-10things-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    html,
    body {
        margin: 0;
        background-color: #eee;
        scroll-behavior: smooth;
    }

    section.home-hero-section {
        width: 100%;
        min-height: calc(100vh - 100px);
        padding: 0 40px;
        min-height: 700px;
    }

    div.home-hero-container {
        width: 100%;
    }

    img.home-hero-img {
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        transform: translateX(-50%) translateY(-50%);
    }


    div.home-hero-text-sections-area {
        width: 100%;
    }

    div.home-hero-top-section {
        width: 100%;
    }

    div.home-hero-left-text-section {
        width: 100%;
    }

    div.home-hero-model-area {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
    
    div.home-hero-model-wrapper {
        min-height: 100px;
        margin: 0 auto;
        width: 50%;
    }
    
    div.home-hero-display-mac {
        position: relative;
        width: 100%;
    }
    
    img.home-hero-display-mac-canvas-img {
        width: 100%;
        height: auto;
        top: 0;
        z-index: 10;
    }
    
    video.home-hero-display-mac-video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        padding: 4.3%;
    }

    div.home-hero-left-inner-text-container {
        width: 60%;
        margin: 0 auto;
    }

    div.home-hero-middle-text-section {
        width: 60%;
        float: left;
    }

    h1.home-hero-title-h1 {
        font-size: 1.8em;
        font-weight: 700;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    p.home-hero-txt-p {
        font-size: 1.2em;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .home-hero-cta-link {
        margin-top: 50px;
    }

    button.home-hero-cta-btn {
        height: 50px;
        padding: 0 30px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 1.4em;
    }

    button.home-hero-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    div.home-hero-right-text-section {
        width: 40%;
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: 70px;
        float: left;
    }

    div.home-hero-right-content {
        width: 375px;
        max-width: 90%;
    }

    div.home-hero-right-inner-text-container {
        width: 70%;
    }

    h3.home-hero-title-h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    img.home-hero-right-img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    div.home-hero-under-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2.home-hero-right-title-h2 {
        font-size: 25px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.home-hero-under-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    li.home-hero-under-item-li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 20px;
        position: relative;
    }

    h4.home-hero-under-item-txt-h4 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    span.home-hero-under-icon-span {
        font-size: 30px;
        margin-left: 10px;
        position: absolute;
        right: 10px;
        display: none;
    }

    li.home-hero-under-item-li.briefing {
        background-color: rgba(54, 104, 255, 0.35);
        border: 3px solid #3668ff;
        transform: translateX(-20px);
    }

    li.home-hero-under-item-li.briefing span.home-hero-under-icon-span {
        color: #3668ff;
    }

    li.home-hero-under-item-li.things {
        background-color: rgba(194, 80, 255, 0.35);
        border: 3px solid #c250ff;
        transform: translateX(-50px);
    }

    li.home-hero-under-item-li.things span.home-hero-under-icon-span {
        color: #c250ff;
    }

    li.home-hero-under-item-li.report {
        background-color: #fbd4f5;
        border: 3px solid #f689e6;
    }

    li.home-hero-under-item-li.report span.home-hero-under-icon-span {
        color: #f689e6;
    }

    section.home-briefing-section {
        width: 100%;
        min-height: 100vh;
        position: relative;
    }

    section.home-features-section {
        width: 100%;
        background-color: black;
        padding: 100px 0;
        margin: 100px 0;
        overflow: hidden;
    }

    div.inner-home-features-area {
        width: 80%;
        height: 100%;
        margin: 0 auto;
    }

    div.inner-home-features-text-area {
        width: 40%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1.home-features-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 50px;
        color: white;
        text-align: center;
    }

    div.inner-home-feature-bar-area {
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
    }

    h2.inner-home-feature-title-h2 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    p.inner-home-feature-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    div.inner-home-features-mockup-area {
        width: 60%;
        float: left;
    }

    img.inner-home-features-mockup-img {
        width: 60%;
        margin: 0 auto;
    }

    section.home-partners-section {
        width: 100%;
    }

    h1.home-partners-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        padding: 10px;
    }

    div.home-partners-inner-div {
        display: grid;
        width: 80%;
        margin: 0 auto;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        margin-top: 50px;
    }

    div.home-partner-item-div {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.home-partner-item-img {
        width: auto;

    }

    section.home-pricing-section {
        width: 100%;
        min-height: 100vh;
    }

    h1.home-pricing-title-h1 {
        font-size: 28px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 20px;
    }

    p.home-pricing-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-pricing-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.home-pricing-card {
        width: 400px;
        max-width: 85%;
        min-height: 400px;
        border-radius: 30px;
        padding: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background-color: white;
    }

    div.home-pricing-top-area {
        width: 100%;
    }

    h2.home-pricing-title-h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-pricing-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    hr.home-pricing-divider {
        width: 100%;
        margin: 20px 0;
        border-color: rgb(182, 182, 182);
    }

    div.home-pricing-bottom-area {
        width: 100%;
    }

    div.home-pricing-price-area {
        height: 50px;
        width: 100%;
    }

    p.home-pricing-price-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    span.home-pricing-price-span {
        font-size: 35px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    div.home-pricing-product-percs {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    ul.home-pricing-percs-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }

    li.home-pricing-percs-item-li {}

    span.home-pricing-percs-icon-span {
        font-size: 30px;
        margin-right: 10px;
        float: left;
        color: rgb(0, 81, 255);
        line-height: 30px;
    }

    p.home-pricing-percs-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
        line-height: 30px;
    }

    button.home-pricing-cta-btn {
        width: 100%;
        height: 45px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
        padding: 0 20px;
    }

    button.home-pricing-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    .subscription-type-tabs {
        margin-top: 50px;
    }

    section.home-things-section {
        width: 100%;
        min-height: 300px;
    }

    div.home-things-stack-container {
        margin: 0 auto;
        padding: 40px 0;
        position: relative;
    }

    div.home-things-title-container {}

    h1.home-things-title-h1 {
        font-size: 25px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-things-stack-card-container {
        width: 60%;
        height: 380px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    h1.home-things-stack-card-inner-title-h1 {
        font-size: 2em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-things-stack-card-inner-area {
        width: 90%;
        height: 500px;
        margin: 0 auto;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 50px;
        box-sizing: border-box;
    }

    div.home-things-stack-card-inner-area.signature {
        background-color: #3737ff8d;
    }

    div.home-things-stack-card-inner-area.suggestion {
        background-color: #ba18fa8d;
    }

    div.home-things-stack-card-inner-area.task {
        background-color: #00d02a8d;
    }

    div.home-things-stack-card-inner-area.process {
        background-color: #d000008d;
    }

    div.home-things-stack-card-inner-area.thing {
        background-color: #e764008d;
    }

    div.home-things-stack-card-inner-thing-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-thing-div {
        width: 100%;
        min-height: 100px;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
    }

    div.home-things-thing-div.signture-card {
        background-color: #3737ff;
    }

    div.home-things-thing-div.suggestion-card {
        background-color: #ba18fa;
    }

    div.home-things-thing-div.task-card {
        background-color: #00d02a;
    }

    div.home-things-thing-div.process-card {
        background-color: #d00000;
    }

    div.home-things-thing-div.thing-card {
        background-color: #e76400;
    }

    div.home-things-thing-div-title-area {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid lightgray;
    }

    div.home-things-thing-div-fake-title-div {
        width: 40%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-txt-area {
        width: 100%;
        margin-top: 10px;
    }

    div.home-things-thing-div-fake-txt-long-div {
        width: 90%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-fake-txt-short-div {
        width: 60%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
        margin-top: 10px;
    }

    div.home-things-stack-card-inner-text-area {
        width: 100%;
        min-height: inherit;
        float: left;
    }

    div.home-things-stack-card-inner-text-area.right {}

    div.home-things-stack-card-inner-text-area.left {}

    p.home-things-stack-card-txt-p {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .review-section {
        width: 100%;
        padding: 20px 0;
        /* background-color: #f9f9f9;  */
    }
    
    .review-inner-div {
        width: 90%; 
        margin: 0 auto; 
        padding: 0px;
        display: flex;
    }
    
    .review-item-div {
        background-color: #fff; 
        border-radius: 10px; 
        padding: 40px; 
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        width: 100%;
        height: inherit;
    }
    
    .review-item-div .left-quarter {
        width: 25%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
    }    

    section.home-10things-section {
        width: 100%;
        min-height: 200px;
        padding: 100px 0;
        margin-top: 50px;
    }

    img.home-10things-focus-img {
        width: 500px;
        margin: 0 auto;
    }

    p.home-10things-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    div.home-10things-content-div {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    ul.home-10things-content-listing-ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    li.home-10things-content-item-li {
        padding: 30px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 30px;
    }

    img.home-10things-icon-img {
        height: 100px;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    h5.home-10things-title-h5 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-10things-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }
}

@media (min-width: 1200px) {

    html,
    body {
        margin: 0;
        background-color: #eee;
        scroll-behavior: smooth;
    }

    section.home-hero-section {
        width: 100%;
        height: calc(100vh - 100px);
        padding: 0 40px;
        min-height: 700px;
    }

    div.home-hero-container {
        width: 100%;
        height: 100%;
        position: relative;
        scroll-snap-align: start;
    }

    img.home-hero-img {
        width: 650px;
        height: auto;
    }

    img.home-hero-img-mobile {
        /* display: none; */
    }

    div.home-hero-text-sections-area {
        width: 100%;
        height: 100%;
    }

    div.home-hero-top-section {
        width: 100%;
        height: 100%;
        display: flex;
    }

    div.home-hero-left-text-section {
        width: 27.5%;
        height: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    div.home-hero-model-area {
        width: 72.5%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.home-hero-model-wrapper {
        width: 70%;
        min-height: 100px;
        position: absolute;
        margin: 0 auto;
    }
    
    div.home-hero-display-mac {
        position: relative;
        width: 100%;
    }
    
    img.home-hero-display-mac-canvas-img {
        width: 100%;
        height: auto;
        top: 0;
        z-index: 10;
    }
    
    video.home-hero-display-mac-video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        padding: 4.3%;
    }

    div.home-hero-left-inner-text-container {
        width: 90%;
    }

    div.home-hero-middle-text-section {
        width: 45%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h1.home-hero-title-h1 {
        margin: 0;
        padding: 0;
        font-size: 3em;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
    }

    p.home-hero-txt-p {
        font-size: 20px;
        font-weight: 400;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    button.home-hero-cta-btn {
        height: 60px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        margin-top: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 23px;
        padding: 0 20px;
    }

    button.home-hero-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    button.home-hero-cta-mobile-btn {
        display: none;
    }

    div.home-hero-right-text-section {
        width: 27.5%;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    div.home-hero-right-content {
        width: 375px;
        max-width: 90%;
    }

    div.home-hero-right-inner-text-container {
        width: 70%;
    }

    h3.home-hero-title-h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    img.home-hero-right-img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    div.home-hero-under-section {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2.home-hero-right-title-h2 {
        font-size: 1.5em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    ul.home-hero-under-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    li.home-hero-under-item-li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 20px;
        position: relative;
    }

    h4.home-hero-under-item-txt-h4 {
        font-size: 1em;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }

    span.home-hero-under-icon-span {
        font-size: 30px;
        margin-left: 10px;
        position: absolute;
        right: 10px;
    }

    li.home-hero-under-item-li.briefing {
        background-color: rgba(54, 104, 255, 0.35);
        border: 3px solid #3668ff;
        transform: translateX(-20px);
    }

    li.home-hero-under-item-li.briefing span.home-hero-under-icon-span {
        color: #3668ff;
    }

    li.home-hero-under-item-li.things {
        background-color: rgba(194, 80, 255, 0.35);
        border: 3px solid #c250ff;
        transform: translateX(-50px);
    }

    li.home-hero-under-item-li.things span.home-hero-under-icon-span {
        color: #c250ff;
    }

    li.home-hero-under-item-li.report {
        background-color: #fbd4f5;
        border: 3px solid #f689e6;
    }

    li.home-hero-under-item-li.report span.home-hero-under-icon-span {
        color: #f689e6;
    }

    section.home-briefing-section {
        width: 100%;
        min-height: 100vh;
        position: relative;
    }

    section.home-features-section {
        width: 100%;
        background-color: black;
        padding: 100px 0;
        margin: 100px 0;
        overflow: hidden;
    }

    div.inner-home-features-area {
        width: 800px;
        height: 100%;
        margin: 0 auto;
        max-width: 90%;
    }

    div.inner-home-features-text-area {
        width: 40%;
        height: 100%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1.home-features-title-h1 {
        font-size: 50px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 70px;
        color: white;
        text-align: center;
    }

    div.inner-home-feature-bar-area {
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
    }

    h2.inner-home-feature-title-h2 {
        font-size: 33px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    p.inner-home-feature-txt-p {
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        color: white;
    }

    div.inner-home-features-mockup-area {
        width: 60%;
        height: 100%;
        float: left;
        padding-left: 100px
    }

    img.inner-home-features-mockup-img {
        width: 100%;
    }

    section.home-partners-section {
        width: 100%;
    }

    h1.home-partners-title-h1 {
        font-size: 22px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
        padding: 10px;
    }

    div.home-partners-inner-div {
        height: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.home-partner-item-div {
        height: 50px;
        float: left;
        margin-left: 50px;
    }

    img.home-partner-item-img {
        width: auto;
        height: 100%;
    }

    section.home-pricing-section {
        width: 100%;
        min-height: 100vh;
        padding: 100px 0;
    }

    h1.home-pricing-title-h1 {
        font-size: 40px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-top: 50px;
        text-align: center;
        margin-bottom: 20px;
    }

    p.home-pricing-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        text-align: center;
    }

    div.home-pricing-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    div.home-pricing-card {
        width: 380px;
        min-height: 400px;
        border-radius: 30px;
        padding: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background-color: white;
    }

    div.home-pricing-top-area {
        width: 100%;
    }

    h2.home-pricing-title-h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-pricing-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    hr.home-pricing-divider {
        width: 100%;
        margin: 20px 0;
        border-color: rgb(182, 182, 182);
    }

    div.home-pricing-bottom-area {
        width: 100%;
    }

    div.home-pricing-price-area {
        height: 50px;
        width: 100%;
    }

    p.home-pricing-price-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    span.home-pricing-price-span {
        font-size: 35px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    div.home-pricing-product-percs {
        width: 100%;
        margin-top: 20px;
    }

    ul.home-pricing-percs-listing-ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }

    li.home-pricing-percs-item-li {}

    span.home-pricing-percs-icon-span {
        font-size: 30px;
        margin-right: 10px;
        float: left;
        color: rgb(0, 81, 255);
        line-height: 30px;
    }

    p.home-pricing-percs-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
        line-height: 30px;
    }

    button.home-pricing-cta-btn {
        width: 100%;
        height: 45px;
        border-radius: 50px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        font-size: 18px;
        padding: 0 20px;
    }

    button.home-pricing-cta-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    .subscription-type-tabs {
        margin-top: 50px;
    }

    section.home-things-section {
        width: 100%;
        min-height: 300px;
        padding: 100px 0;
    }

    div.home-things-stack-container {
        margin: 0 auto;
        padding: 40px 0;
        position: relative;
    }

    div.home-things-title-container {
        height: 100px;
    }

    h1.home-things-title-h1 {
        font-size: 40px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 20px;
        text-align: center;
    }

    div.home-things-stack-card-container {
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h1.home-things-stack-card-inner-title-h1 {
        font-size: 40px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        text-align: center;
    }

    div.home-things-stack-card-inner-area {
        width: 1200px;
        height: 500px;
        margin: 0 auto;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 50px;
        box-sizing: border-box;
        max-width: 90%;
    }

    div.home-things-stack-card-inner-area.signature {
        background-color: #3737ff8d;
    }

    div.home-things-stack-card-inner-area.suggestion {
        background-color: #ba18fa8d;
    }

    div.home-things-stack-card-inner-area.task {
        background-color: #00d02a8d;
    }

    div.home-things-stack-card-inner-area.process {
        background-color: #d000008d;
    }

    div.home-things-stack-card-inner-area.thing {
        background-color: #e764008d;
    }

    div.home-things-stack-card-inner-thing-area {
        width: 400px;
        min-height: inherit;
        float: left;
    }

    div.home-things-thing-div {
        width: 100%;
        min-height: 100px;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;
        float: left;
    }

    div.home-things-thing-div.signture-card {
        background-color: #3737ff;
    }

    div.home-things-thing-div.suggestion-card {
        background-color: #ba18fa;
    }

    div.home-things-thing-div.task-card {
        background-color: #00d02a;
    }

    div.home-things-thing-div.process-card {
        background-color: #d00000;
    }

    div.home-things-thing-div.thing-card {
        background-color: #e76400;
    }

    div.home-things-thing-div-title-area {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid lightgray;
    }

    div.home-things-thing-div-fake-title-div {
        width: 40%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-txt-area {
        width: 100%;
        margin-top: 10px;
    }

    div.home-things-thing-div-fake-txt-long-div {
        width: 90%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
    }

    div.home-things-thing-div-fake-txt-short-div {
        width: 60%;
        height: 16px;
        background-color: #ffffff7a;
        border-radius: 10px;
        margin-top: 10px;
    }

    div.home-things-stack-card-inner-text-area {
        width: calc(100% - 400px);
        min-height: inherit;
        float: left;
    }

    div.home-things-stack-card-inner-text-area.right {
        padding-left: 100px;
    }

    div.home-things-stack-card-inner-text-area.left {
        padding-right: 100px;
    }

    p.home-things-stack-card-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .review-section {
        width: 100%;
        padding: 20px 0;
        /* background-color: #f9f9f9;  */
    }
    
    .review-inner-div {
        width: 50%;
        margin: 0 auto;
        padding: 0px;
        display: flex;
        margin-top: 20px;
    }
    
    .review-item-div {
        background-color: #fff; 
        border-radius: 10px; 
        padding: 40px; 
        border-radius: 30px;
        width: 100%;
        height: inherit;
    }
    
    .review-item-div .left-quarter {
        width: 25%; 
        background-color: #f2f2f2; 
        padding: 0px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .review-text-div p {
        font-size: 16px; 
        line-height: 1.5; 
        color: #333; 
        font-weight: bold;
        font-style: oblique;
        margin: 0px;
    }    

    section.home-10things-section {
        width: 100%;
        min-height: 200px;
        padding: 100px 0;
        margin-top: 50px;
    }

    img.home-10things-focus-img {
        width: 550px;
        margin: 0 auto;
    }

    p.home-10things-txt-p {
        font-size: 22px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
        margin-top: 50px;
        text-align: center;
    }

    div.home-10things-content-div {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 100px;
    }

    ul.home-10things-content-listing-ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }

    li.home-10things-content-item-li {
        padding: 30px;
        cursor: pointer;
        background-color: rgb(214, 224, 235);
        border-radius: 30px;
    }

    img.home-10things-icon-img {
        height: 100px;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    h5.home-10things-title-h5 {
        font-size: 20px;
        font-weight: 700;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    p.home-10things-inner-txt-p {
        font-size: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
        margin-bottom: 10px;
    }
}

a.link-to-wouter {
    color: black;
    font-weight: bold;
}

a.link-to-wouter:hover {
    color: rgb(0, 136, 255);
}

.home-pricing-original-price-span {
    color: #a9a9a9;
    font-size: 1.2em;
    margin-right: 8px;
    display: flex;
}

div.home-pricing-credit-area {
    margin-top: 50px;
}

h1.home-pricing-credit-title-h1 {
    font-size: 2em;
    font-weight: 700;
    font-family: 'SF Pro Display', sans-serif;
    line-height: 1.2;
    margin-bottom: 10px;
    text-align: center;
}