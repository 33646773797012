div.created-thing-item {
    width: 100%;
    min-height: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

div.created-thing-item:hover {
    opacity: 0.85;
}

h2.created-thing-title-h2 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: 'SF Pro Display', sans-serif;
}

hr.created-thing-divder {
    width: 100%;
    margin: 10px 0;
}

p.created-thing-txt-p {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: 'SF Pro Display', sans-serif;
    color: white;
}

div.created-thing-item-options {
    width: 100%;
    min-height: 0px;
    margin-top: 10px;
    overflow: hidden;
}

button.created-thing-item-options-btn {
    border: none;
    border-radius: 50px;
    padding: 7.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    float: right;
    color: white;
}

button.created-thing-item-options-btn:hover {
    background-color: #e6e6e63e;
}