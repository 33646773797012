@media (max-width: 575.98px) {
    div.viewcreated-things-wrapper {
        width: 100%;
        display: block;
        margin: auto;
        padding: 0 10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.viewcreated-things-wrapper {
        width: 100%;
        display: block;
        margin: auto;
        padding: 0 10px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.viewcreated-things-wrapper {
        width: 100%;
        display: block;
        margin: auto;
        padding: 0 10px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.viewcreated-things-wrapper {
        width: calc(100% - 120px);
        display: block;
        margin: auto;
        padding: 0 20px;
    }
}

@media (min-width: 1200px) {
    div.viewcreated-things-wrapper {
        width: calc(100% - 800px);
        display: block;
        margin: auto;
        padding: 0 20px;
    }
}
