div.unsubscribe-popup {
    width: 100%;
    min-height: 100%;
    align-items: center;
}

p.unsubscribe-txt-p {
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-family: 'SF Pro Display', sans-serif;
}

div.unsubscribe-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
}

button.unsubscribe-btn {
    width: 100%;
    height: 50px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'SF Pro Display', sans-serif;
    border-radius: 10px;
}

button.unsubscribe-btn.cancel {
    background-color: #ebebeb;
}

button.unsubscribe-btn.cancel:hover {
    background-color: #d9d9d9;
}

button.unsubscribe-btn.unsubscribe {
    background-color: #e70000;
    color: white;
    font-weight: bold;
}

button.unsubscribe-btn.unsubscribe:hover {
    background-color: #c20000;
}