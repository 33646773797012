@media (max-width: 575.98px) {
    div.noshift-wrapper {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    
    h1.noshift-title-h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    .start-shift-button {
        padding: 10px 40px; /* Ruimte binnen de knop */
        font-size: 1.5em; /* Tekstgrootte */
        color: #000; /* Zwarte tekstkleur */
        background-color: #0077ff; /* Blauwe achtergrondkleur */
        border: none; /* Geen rand */
        border-radius: 50px; /* Maak de knop ovaal */
        cursor: pointer; /* Cursor veranderen naar een pointer */
        text-align: center; /* Tekst centreren */
        display: inline-block; /* Inline-block zorgt ervoor dat padding werkt */
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold; /* Vetgedrukte tekst */
        margin-bottom: 50px;
        
    }
    
    .start-shift-button:hover {
        background-color: #0055cc; /* Donkerblauwe achtergrondkleur */
    }
    
    ul.noshift-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: grid;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    li.no-shift-list-item-li {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.no-shift-list-item-span {
        font-weight: bold;
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
    }
    
    p.no-shift-list-item-p {
        display: inline-block;
        font-family: 'SF Pro Display', sans-serif;
        vertical-align: middle;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.noshift-wrapper {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    
    h1.noshift-title-h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    .start-shift-button {
        padding: 10px 40px; /* Ruimte binnen de knop */
        font-size: 1.5em; /* Tekstgrootte */
        color: #000; /* Zwarte tekstkleur */
        background-color: #0077ff; /* Blauwe achtergrondkleur */
        border: none; /* Geen rand */
        border-radius: 50px; /* Maak de knop ovaal */
        cursor: pointer; /* Cursor veranderen naar een pointer */
        text-align: center; /* Tekst centreren */
        display: inline-block; /* Inline-block zorgt ervoor dat padding werkt */
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold; /* Vetgedrukte tekst */
        margin-bottom: 50px;
        
    }
    
    .start-shift-button:hover {
        background-color: #0055cc; /* Donkerblauwe achtergrondkleur */
    }
    
    ul.noshift-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: grid;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    li.no-shift-list-item-li {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.no-shift-list-item-span {
        font-weight: bold;
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
    }
    
    p.no-shift-list-item-p {
        display: inline-block;
        font-family: 'SF Pro Display', sans-serif;
        vertical-align: middle;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.noshift-wrapper {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    
    h1.noshift-title-h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    .start-shift-button {
        padding: 10px 40px; /* Ruimte binnen de knop */
        font-size: 1.5em; /* Tekstgrootte */
        color: #000; /* Zwarte tekstkleur */
        background-color: #0077ff; /* Blauwe achtergrondkleur */
        border: none; /* Geen rand */
        border-radius: 50px; /* Maak de knop ovaal */
        cursor: pointer; /* Cursor veranderen naar een pointer */
        text-align: center; /* Tekst centreren */
        display: inline-block; /* Inline-block zorgt ervoor dat padding werkt */
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold; /* Vetgedrukte tekst */
        margin-bottom: 50px;
        
    }
    
    .start-shift-button:hover {
        background-color: #0055cc; /* Donkerblauwe achtergrondkleur */
    }
    
    ul.noshift-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    li.no-shift-list-item-li {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    span.no-shift-list-item-span {
        font-weight: bold;
        display: inline-block;
        float: left;
        margin-right: 20px;
    }
    
    p.no-shift-list-item-p {
        display: inline-block;
        float: right;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.noshift-wrapper {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    
    h1.noshift-title-h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    .start-shift-button {
        padding: 10px 40px; /* Ruimte binnen de knop */
        font-size: 1.5em; /* Tekstgrootte */
        color: #000; /* Zwarte tekstkleur */
        background-color: #0077ff; /* Blauwe achtergrondkleur */
        border: none; /* Geen rand */
        border-radius: 50px; /* Maak de knop ovaal */
        cursor: pointer; /* Cursor veranderen naar een pointer */
        text-align: center; /* Tekst centreren */
        display: inline-block; /* Inline-block zorgt ervoor dat padding werkt */
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold; /* Vetgedrukte tekst */
        margin-bottom: 50px;
        
    }
    
    .start-shift-button:hover {
        background-color: #0055cc; /* Donkerblauwe achtergrondkleur */
    }
    
    ul.noshift-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    li.no-shift-list-item-li {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    span.no-shift-list-item-span {
        font-weight: bold;
        display: inline-block;
        float: left;
        margin-right: 20px;
    }
    
    p.no-shift-list-item-p {
        display: inline-block;
        float: right;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.noshift-wrapper {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    
    h1.noshift-title-h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    .start-shift-button {
        padding: 10px 40px; /* Ruimte binnen de knop */
        font-size: 1.5em; /* Tekstgrootte */
        color: #000; /* Zwarte tekstkleur */
        background-color: #0077ff; /* Blauwe achtergrondkleur */
        border: none; /* Geen rand */
        border-radius: 50px; /* Maak de knop ovaal */
        cursor: pointer; /* Cursor veranderen naar een pointer */
        text-align: center; /* Tekst centreren */
        display: inline-block; /* Inline-block zorgt ervoor dat padding werkt */
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold; /* Vetgedrukte tekst */
        margin-bottom: 50px;
        
    }
    
    .start-shift-button:hover {
        background-color: #0055cc; /* Donkerblauwe achtergrondkleur */
    }
    
    ul.noshift-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    li.no-shift-list-item-li {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    span.no-shift-list-item-span {
        font-weight: bold;
        display: inline-block;
        float: left;
        margin-right: 20px;
    }
    
    p.no-shift-list-item-p {
        display: inline-block;
        float: right;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 1450px) {
    div.noshift-wrapper {
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }
    
    h1.noshift-title-h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 20px;
    }
    
    .start-shift-button {
        padding: 10px 40px; /* Ruimte binnen de knop */
        font-size: 1.5em; /* Tekstgrootte */
        color: #000; /* Zwarte tekstkleur */
        background-color: #0077ff; /* Blauwe achtergrondkleur */
        border: none; /* Geen rand */
        border-radius: 50px; /* Maak de knop ovaal */
        cursor: pointer; /* Cursor veranderen naar een pointer */
        text-align: center; /* Tekst centreren */
        display: inline-block; /* Inline-block zorgt ervoor dat padding werkt */
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold; /* Vetgedrukte tekst */
        margin-bottom: 50px;
        
    }
    
    .start-shift-button:hover {
        background-color: #0055cc; /* Donkerblauwe achtergrondkleur */
    }
    
    ul.noshift-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    li.no-shift-list-item-li {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    span.no-shift-list-item-span {
        font-weight: bold;
        display: inline-block;
        float: left;
        margin-right: 20px;
    }
    
    p.no-shift-list-item-p {
        display: inline-block;
        float: right;
        font-family: 'SF Pro Display', sans-serif;
    }
}