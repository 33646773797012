div.calendar-view-container {
    width: 100%;
    height: calc(100% - 110px);
}

div.calendar-side-view-area {
    width: 300px;
    min-height: 100%;
    height: 100%;
    float: left;
    background-color: white;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

div.calendar-full-view-area {
    width: calc(100% - 300px);
    min-height: 100%;
    height: 100%;
    float: left;
}

div.calendar-month-area-div {
    width: 100%;
    height: 100%;
}

div.calendar-day-labels {
    width: 100%;
    float: left;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px; /* Add a gap between the grid items */
    background-color: lightgray; /* Set the background color to the border color */
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    height: 50px;
}

div.calendar-day-labels span {
    background-color: white;
    font-weight: bold;
    line-height: 50px;
    padding-left: 10px;
}

div.calendar-month-inner-content {
    width: 100%;
    height: calc(100% - 50px);
    float: left;
}

div.calendarview-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr;
    gap: 1px; /* Add a gap between the grid items */
    background-color: lightgray; /* Set the background color to the border color */
    height: 100%;
    border: 1px solid lightgray;
    overflow: hidden;
}

div.calendar-date-item {
    background-color: white;
    position: relative;
    padding: 0 7.5px;
    padding-top: 40px;
}

h1.calendar-day-txt-h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 7.5px;
    right: 10px;
}

ul.calendar-content-listing-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li.calendar-content-listing-item {
    width: 100%;
}


li.calendar-content-listing-item.holidays button.calendar-content-listing-btn  {
    border-color: red;
    color: red;
}

li.calendar-content-listing-item.wouter_events button.calendar-content-listing-btn  {
    border-color: rgb(0, 94, 255);
    color: rgb(0, 94, 255);
}

li.calendar-content-listing-item.vacations button.calendar-content-listing-btn  {
    border-color: orange;
    color: orange;
}

li.calendar-content-listing-item.sports button.calendar-content-listing-btn  {
    border-color: green;
    color: green;
}

button.calendar-content-listing-btn {
    width: 100%;
    border: none;
    padding: 0 5px;
    text-align: left;
    border-radius: 7.5px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 14px;
    line-height: 1;
    padding: 10px;
}

button.calendar-content-listing-btn:hover {
    background-color: rgb(234, 234, 234);
}

div.date-placeholder {
    background-color: white;
}