@media (max-width: 575.98px) {
    div.credit-converter-div {
        width: 380px;
        padding: 30px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    
    h2.credit-converter-title-h2 {
        font-size: 1.4em;
        line-height: 1;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    p.credit-converter-subtitle-txt-p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.credit-converter-top-area {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }
    
    img.credit-converter-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    
    img.credit-converter-img.tenthings {
        object-fit: contain;
        border-radius: 0;
    }
    
    p.credit-converter-type-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.credit-converter-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    button.credit-converter-counter {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
    }
    
    input.credit-converter-input {
        width: 100%;
        height: 60px;
        border: none;
        font-size: 1.5em;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        /* Remove the up and down arrows */
        -moz-appearance: textfield;
    }
    
    input.credit-converter-input::-webkit-outer-spin-button,
    input.credit-converter-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input.credit-converter-input:focus {
        outline: none;
    }
    
    hr.credit-converter-hr {
        margin-bottom: 20px;
    }
    
    label.credits-label {
        margin-bottom: 20px;
        display: block;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.credit-converter-div {
        width: 380px;
        padding: 30px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    
    h2.credit-converter-title-h2 {
        font-size: 2em;
        line-height: 1;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    p.credit-converter-subtitle-txt-p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.credit-converter-top-area {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }
    
    img.credit-converter-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    
    img.credit-converter-img.tenthings {
        object-fit: contain;
        border-radius: 0;
    }
    
    p.credit-converter-type-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.credit-converter-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    button.credit-converter-counter {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
    }
    
    input.credit-converter-input {
        width: 100%;
        height: 60px;
        border: none;
        font-size: 1.5em;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        /* Remove the up and down arrows */
        -moz-appearance: textfield;
    }
    
    input.credit-converter-input::-webkit-outer-spin-button,
    input.credit-converter-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input.credit-converter-input:focus {
        outline: none;
    }
    
    hr.credit-converter-hr {
        margin-bottom: 20px;
    }
    
    label.credits-label {
        margin-bottom: 20px;
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.credit-converter-div {
        width: 380px;
        padding: 30px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    
    h2.credit-converter-title-h2 {
        font-size: 2em;
        line-height: 1;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    p.credit-converter-subtitle-txt-p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.credit-converter-top-area {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }
    
    img.credit-converter-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    
    img.credit-converter-img.tenthings {
        object-fit: contain;
        border-radius: 0;
    }
    
    p.credit-converter-type-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.credit-converter-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    button.credit-converter-counter {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
    }
    
    input.credit-converter-input {
        width: 100%;
        height: 60px;
        border: none;
        font-size: 1.5em;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        /* Remove the up and down arrows */
        -moz-appearance: textfield;
    }
    
    input.credit-converter-input::-webkit-outer-spin-button,
    input.credit-converter-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input.credit-converter-input:focus {
        outline: none;
    }
    
    hr.credit-converter-hr {
        margin-bottom: 20px;
    }
    
    label.credits-label {
        margin-bottom: 20px;
        display: block;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.credit-converter-div {
        width: 380px;
        padding: 30px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    
    h2.credit-converter-title-h2 {
        font-size: 2em;
        line-height: 1;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    p.credit-converter-subtitle-txt-p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.credit-converter-top-area {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }
    
    img.credit-converter-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    
    img.credit-converter-img.tenthings {
        object-fit: contain;
        border-radius: 0;
    }
    
    p.credit-converter-type-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.credit-converter-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    button.credit-converter-counter {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
    }
    
    input.credit-converter-input {
        width: 100%;
        height: 60px;
        border: none;
        font-size: 1.5em;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        /* Remove the up and down arrows */
        -moz-appearance: textfield;
    }
    
    input.credit-converter-input::-webkit-outer-spin-button,
    input.credit-converter-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input.credit-converter-input:focus {
        outline: none;
    }
    
    hr.credit-converter-hr {
        margin-bottom: 20px;
    }
    
    label.credits-label {
        margin-bottom: 20px;
        display: block;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.credit-converter-div {
        width: 380px;
        padding: 30px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    
    h2.credit-converter-title-h2 {
        font-size: 2em;
        line-height: 1;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    p.credit-converter-subtitle-txt-p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.credit-converter-top-area {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }
    
    img.credit-converter-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    
    img.credit-converter-img.tenthings {
        object-fit: contain;
        border-radius: 0;
    }
    
    p.credit-converter-type-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.credit-converter-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    button.credit-converter-counter {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
    }
    
    input.credit-converter-input {
        width: 100%;
        height: 60px;
        border: none;
        font-size: 1.5em;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        /* Remove the up and down arrows */
        -moz-appearance: textfield;
    }
    
    input.credit-converter-input::-webkit-outer-spin-button,
    input.credit-converter-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input.credit-converter-input:focus {
        outline: none;
    }
    
    hr.credit-converter-hr {
        margin-bottom: 20px;
    }
    
    label.credits-label {
        margin-bottom: 20px;
        display: block;
    }
}

@media (min-width: 1450px) {
    div.credit-converter-div {
        width: 380px;
        padding: 30px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    
    h2.credit-converter-title-h2 {
        font-size: 2em;
        line-height: 1;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        margin-bottom: 10px;
    }
    
    p.credit-converter-subtitle-txt-p {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.credit-converter-top-area {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }
    
    img.credit-converter-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    
    img.credit-converter-img.tenthings {
        object-fit: contain;
        border-radius: 0;
    }
    
    p.credit-converter-type-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.credit-converter-input-container {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    button.credit-converter-counter {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        font-size: 1.2em;
        font-weight: 600;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
    }
    
    input.credit-converter-input {
        width: 100%;
        height: 60px;
        border: none;
        font-size: 1.5em;
        font-family: 'SF Pro Display', sans-serif;
        text-align: center;
        /* Remove the up and down arrows */
        -moz-appearance: textfield;
    }
    
    input.credit-converter-input::-webkit-outer-spin-button,
    input.credit-converter-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input.credit-converter-input:focus {
        outline: none;
    }
    
    hr.credit-converter-hr {
        margin-bottom: 20px;
    }
    
    label.credits-label {
        margin-bottom: 20px;
        display: block;
    }
}