@media (max-width: 575.98px) {
    div.pre-briefing-things-step {
        width: 100%;
    }
    
    div.pre-briefing-things-list-area {
        width: 100%;
        min-height: 500px;
        background-color: rgb(233, 233, 233);
        border-radius: 20px;
    }
    
    div.pre-briefing-selection-area {
        width: 90%;
        min-height: 100px;
        margin: 0 auto;
        margin-top: 20px;
    }
    
    h2.pre-briefing-things-select-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    p.pre-briefing-things-select-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-selection-types-area {
        width: 100%;
        margin-top: 20px;
    }
    
    div.pre-briefing-selection-recommended-area {
        width: 100%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
        max-height: 600px;
        margin-bottom: 20px;
    }
    
    div.pre-briefing-selection-custom-area {
        width: 100%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
        max-height: 600px;
    }
    
    h4.pre-briefing-selection-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.pre-briefing-things-step {
        width: 100%;
    }
    
    div.pre-briefing-things-list-area {
        width: 100%;
        min-height: 500px;
        background-color: rgb(233, 233, 233);
        border-radius: 20px;
    }
    
    div.pre-briefing-selection-area {
        width: 90%;
        min-height: 100px;
        margin: 0 auto;
        margin-top: 20px;
    }
    
    h2.pre-briefing-things-select-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    p.pre-briefing-things-select-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-selection-types-area {
        width: 100%;
        margin-top: 20px;
    }
    
    div.pre-briefing-selection-recommended-area {
        width: 100%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
        max-height: 600px;
        margin-bottom: 20px;
    }
    
    div.pre-briefing-selection-custom-area {
        width: 100%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
        max-height: 600px;
    }
    
    h4.pre-briefing-selection-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.pre-briefing-things-step {
        width: 100%;
    }
    
    div.pre-briefing-things-list-area {
        width: 100%;
        min-height: 500px;
        background-color: rgb(233, 233, 233);
        border-radius: 20px;
    }
    
    div.pre-briefing-selection-area {
        width: 90%;
        min-height: 100px;
        margin: 0 auto;
        margin-top: 20px;
    }
    
    h2.pre-briefing-things-select-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    p.pre-briefing-things-select-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-selection-types-area {
        width: 100%;
        margin-top: 20px;
    }
    
    div.pre-briefing-selection-recommended-area {
        width: 100%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
        max-height: 600px;
        margin-bottom: 20px;
    }
    
    div.pre-briefing-selection-custom-area {
        width: 100%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
        max-height: 600px;
    }
    
    h4.pre-briefing-selection-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.pre-briefing-things-step {
        width: 100%;
    }
    
    div.pre-briefing-things-list-area {
        width: 100%;
        min-height: 500px;
        background-color: rgb(233, 233, 233);
        border-radius: 20px;
    }
    
    div.pre-briefing-selection-area {
        width: 90%;
        min-height: 100px;
        margin: 0 auto;
        margin-top: 20px;
    }
    
    h2.pre-briefing-things-select-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    p.pre-briefing-things-select-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-selection-types-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        max-height: 600px;
    }
    
    div.pre-briefing-selection-recommended-area {
        width: calc(50% - 20px);
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
    }
    
    div.pre-briefing-selection-custom-area {
        width: calc(50% - 20px);
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
    }
    
    h4.pre-briefing-selection-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.pre-briefing-things-step {
        width: 100%;
    }
    
    div.pre-briefing-things-list-area {
        width: 100%;
        min-height: 500px;
        background-color: rgb(233, 233, 233);
        border-radius: 20px;
    }
    
    div.pre-briefing-selection-area {
        width: 90%;
        min-height: 100px;
        margin: 0 auto;
        margin-top: 20px;
    }
    
    h2.pre-briefing-things-select-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    p.pre-briefing-things-select-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-selection-types-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        max-height: 600px;
    }
    
    div.pre-briefing-selection-recommended-area {
        width: calc(50% - 20px);
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
    }
    
    div.pre-briefing-selection-custom-area {
        width: calc(50% - 20px);
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
    }
    
    h4.pre-briefing-selection-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}

@media (min-width: 1450px) {
    div.pre-briefing-things-step {
        width: 100%;
    }
    
    div.pre-briefing-things-list-area {
        width: 100%;
        min-height: 500px;
        background-color: rgb(233, 233, 233);
        border-radius: 20px;
    }
    
    div.pre-briefing-selection-area {
        width: 90%;
        min-height: 100px;
        margin: 0 auto;
        margin-top: 20px;
    }
    
    h2.pre-briefing-things-select-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    p.pre-briefing-things-select-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-selection-types-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        max-height: 600px;
    }
    
    div.pre-briefing-selection-recommended-area {
        width: calc(50% - 20px);
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
    }
    
    div.pre-briefing-selection-custom-area {
        width: calc(50% - 20px);
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        overflow: auto;
    }
    
    h4.pre-briefing-selection-title-h4 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }
}