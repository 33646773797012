@media (max-width: 575.98px) {
    section.faq-section {
        width: 100%;
        min-height: 100vh;
    }
    
    div.faq-container-div {
        width: 80%;
        display: block;
        margin: auto;
        overflow: hidden;
    }
    
    div.faq-title-area-div {
        width: 100%;
        float: left;
    }
    
    h1.faq-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 50px;
        font-size: 2em;
        text-align: center;
    }
    
    div.faq-questions-area-div {
        width: 100%;
        float: left;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    section.faq-section {
        width: 100%;
        min-height: 100vh;
    }
    
    div.faq-container-div {
        width: 80%;
        display: block;
        margin: auto;
        overflow: hidden;
    }
    
    div.faq-title-area-div {
        width: 100%;
        float: left;
    }
    
    h1.faq-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 50px;
        font-size: 2em;
        text-align: center;
    }
    
    div.faq-questions-area-div {
        width: 100%;
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    section.faq-section {
        width: 100%;
        min-height: 100vh;
    }
    
    div.faq-container-div {
        width: 80%;
        display: block;
        margin: auto;
        overflow: hidden;
    }
    
    div.faq-title-area-div {
        width: 100%;
        float: left;
    }
    
    h1.faq-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 50px;
        font-size: 2em;
        text-align: center;
    }
    
    div.faq-questions-area-div {
        width: 100%;
        float: left;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    section.faq-section {
        width: 100%;
        min-height: 100vh;
    }
    
    div.faq-container-div {
        width: 90%;
        display: block;
        margin: auto;
        overflow: hidden;
    }
    
    div.faq-title-area-div {
        width: 30%;
        float: left;
    }
    
    h1.faq-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 50px;
        font-size: 2.5em;
    }
    
    div.faq-questions-area-div {
        width: 70%;
        float: left;
    }
}

@media (min-width: 1200px) {
    section.faq-section {
        width: 100%;
        min-height: 100vh;
    }
    
    div.faq-container-div {
        width: 80%;
        display: block;
        margin: auto;
        overflow: hidden;
    }
    
    div.faq-title-area-div {
        width: 30%;
        float: left;
    }
    
    h1.faq-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 50px;
        font-size: 2.5em;
    }
    
    div.faq-questions-area-div {
        width: 70%;
        float: left;
    }
}