@media (max-width: 575.98px) {
    p.terraswidget-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 11px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.terraswidget-grade-txt-p {
        padding: 0;
        margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-weight: bold;
    }
    
    div.terraswidget-svg-wrapper {
        width: 100%;
        height: calc(100% - 75px);
        display: flex;
        justify-content: center;
    }
    
    svg.terraswidget-svg {
        width: 100%;
        height: 70px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    p.terraswidget-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.terraswidget-grade-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        font-weight: bold;
    }
    
    div.terraswidget-svg-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    svg.terraswidget-svg {
        width: 100%;
        height: 100px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    p.terraswidget-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.terraswidget-grade-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        font-weight: bold;
    }
    
    div.terraswidget-svg-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    svg.terraswidget-svg {
        width: 100%;
        height: 165px;
        margin-top: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    p.terraswidget-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.terraswidget-grade-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        font-weight: bold;
    }
    
    div.terraswidget-svg-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    svg.terraswidget-svg {
        width: 100%;
        height: 150px;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    p.terraswidget-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.terraswidget-grade-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        font-weight: bold;
    }
    
    div.terraswidget-svg-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    svg.terraswidget-svg {
        width: 100%;
        height: 70px;
        margin-top: 30px;
    }
}


@media (min-width: 1600px) {
    p.terraswidget-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
    
    p.terraswidget-grade-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        padding: 0;
        color: rgb(117, 117, 117);
        font-weight: bold;
    }
    
    div.terraswidget-svg-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    
    svg.terraswidget-svg {
        width: 100%;
        height: 70px;
        margin-top: 30px;
    }
}