div.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: auto; /* This makes the overlay scrollable */
}

div.popup-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
}

div.popup-top-bar {
    width: 100%;
    height: 50px;
}

p.popup-title-txt-p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    float: left;
}

button.popup-close-btn {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    float: left;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.popup-close-btn:hover {
    background-color: #f1f1f1;
}

button.popup-close-btn span {
    font-size: 30px;
}