div.invoices-pagination-container {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.downloadable-a-href {
    text-decoration: underline ;
    text-decoration-style: dotted;
    color: rgb(0, 102, 255);
}

.downloadable-li-a-href {
    width: 100%;
    height: 100%;
}

span.invoice-status-bubble {
    padding: 5px 20px;
    border-radius: 50px;
    display: inline-block;
    margin-right: 10px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: bold;
}

span.invoice-status-bubble.open {
    background-color: rgb(216, 245, 248);
    color: rgb(0, 102, 255);
}

span.invoice-status-bubble.payed {
    background-color: rgb(155, 240, 155);
    color: green;
}

span.invoice-status-bubble.due {
    background-color: rgb(255, 204, 204);
    color: red;
}

span.invoice-status-bubble.unkown {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}