li.vip-item-li{
    width: 100%;
    padding: 20px 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    list-style-type: none;
}

div.vip-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:  space-between;
    margin-bottom: 20px;
}

p.vip-tablenumber-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
    margin: 0;
    padding: 0;
    float: right;
}

p.vip-guests-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    color: black;
    margin: 0;
    padding: 0;
}

h4.vip-name-title-h4 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    color: black;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}

button.vip-options-btn {
    float: right;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px
}

button.vip-options-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
}