@media (max-width: 575.98px) {
    div.nocredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.nocredits-wrapper-div { 
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.nocredits-inner-div {
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        height: 100%;
    }
    
    div.nocredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.nocredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.nocredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.nocredits-close-btn span {
        font-size: 2em;
    }
    
    div.nocredits-content-area {
        width: 100%;
        height: calc(100% - 90px);
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .nocredits-icon {
        font-size: 10em;
        color: #f2d700;
        display: block;
        margin: 0 auto;
    }
    
    h2.nocredits-title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.nocredits-text {
        font-size: 1.1em;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
    
    button.nocredits-buy-credits-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.nocredits-buy-credits-btn:hover {
        background-color: #06f;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.nocredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.nocredits-wrapper-div { 
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.nocredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
    }
    
    div.nocredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.nocredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.nocredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.nocredits-close-btn span {
        font-size: 2em;
    }
    
    div.nocredits-content-area {
        width: 100%;
        margin-bottom: 50px;
    }
    
    .nocredits-icon {
        font-size: 10em;
        color: #f2d700;
        display: block;
        margin: 0 auto;
    }
    
    h2.nocredits-title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.nocredits-text {
        font-size: 1.1em;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
    
    button.nocredits-buy-credits-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.nocredits-buy-credits-btn:hover {
        background-color: #06f;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.nocredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.nocredits-wrapper-div { 
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.nocredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
    }
    
    div.nocredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.nocredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.nocredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.nocredits-close-btn span {
        font-size: 2em;
    }
    
    div.nocredits-content-area {
        width: 100%;
        margin-bottom: 50px;
    }
    
    .nocredits-icon {
        font-size: 10em;
        color: #f2d700;
        display: block;
        margin: 0 auto;
    }
    
    h2.nocredits-title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.nocredits-text {
        font-size: 1.1em;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
    
    button.nocredits-buy-credits-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.nocredits-buy-credits-btn:hover {
        background-color: #06f;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.nocredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.nocredits-wrapper-div { 
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.nocredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
    }
    
    div.nocredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.nocredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.nocredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.nocredits-close-btn span {
        font-size: 2em;
    }
    
    div.nocredits-content-area {
        width: 100%;
        margin-bottom: 50px;
    }
    
    .nocredits-icon {
        font-size: 10em;
        color: #f2d700;
        display: block;
        margin: 0 auto;
    }
    
    h2.nocredits-title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.nocredits-text {
        font-size: 1.1em;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
    
    button.nocredits-buy-credits-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.nocredits-buy-credits-btn:hover {
        background-color: #06f;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.nocredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.nocredits-wrapper-div { 
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.nocredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
    }
    
    div.nocredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.nocredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.nocredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.nocredits-close-btn span {
        font-size: 2em;
    }
    
    div.nocredits-content-area {
        width: 100%;
        margin-bottom: 50px;
    }
    
    .nocredits-icon {
        font-size: 10em;
        color: #f2d700;
        display: block;
        margin: 0 auto;
    }
    
    h2.nocredits-title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.nocredits-text {
        font-size: 1.1em;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
    
    button.nocredits-buy-credits-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.nocredits-buy-credits-btn:hover {
        background-color: #06f;
    }
}

@media (min-width: 1450px) {
    div.nocredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.nocredits-wrapper-div { 
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.nocredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
    }
    
    div.nocredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.nocredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.nocredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.nocredits-close-btn span {
        font-size: 2em;
    }
    
    div.nocredits-content-area {
        width: 100%;
        margin-bottom: 50px;
    }
    
    .nocredits-icon {
        font-size: 10em;
        color: #f2d700;
        display: block;
        margin: 0 auto;
    }
    
    h2.nocredits-title {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.nocredits-text {
        font-size: 1.1em;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
    
    button.nocredits-buy-credits-btn {
        background-color: #005eff;
        border: none;
        border-radius: 50px;
        box-shadow: 0 0 10px 0 #0003;
        color: #fff;
        cursor: pointer;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: 700;
        height: 45px;
        padding: 0 20px;
        width: 100%;
    }
    
    button.nocredits-buy-credits-btn:hover {
        background-color: #06f;
    }
}

