div.delete-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.delete-popup-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.delete-popup {
    width: 90%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2.delete-popup-title-h2 {
    font-size: 1.3rem;
    margin-bottom: 20px;
    line-height: 1;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: bold;
    text-align: center;
}

p.delete-popup-txt-p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    line-height: 1;
    font-family: 'SF Pro Display', sans-serif;
    text-align: center;
}

div.delete-popup-btns-area {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

button.delete-popup-btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-family: 'SF Pro Display', sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

button.delete-popup-btn:not(.delete):hover {
    background-color: #e1e1e1;
}

button.delete-popup-btn.delete {
    background-color: #ff0000;
    color: #fff;
}

button.delete-popup-btn.delete:hover {
    background-color: #ff3333;
}