@media (max-width: 575.98px) {
    nav.mainsite-nav {
        width: 100%;
        padding: 20px 40px;
        height: 100px;
        z-index: 1000;
        top: 0;
        position: -webkit-sticky;
        position: sticky;
    }
    
    ul.main-site-listing-ul {
        width: 100%;
        height: 100%;
    }
    
    li.main-site-logo-li {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    .main-site-logo-link {
        height: 100%;
    }
    
    img.main-site-logo-img {
        height: 100%;
        width: auto;
    }
    
    li.main-site-inner-nav-li {
        display: none;
    }
    
    nav.main-site-inner-nav {
        min-width: 80px;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        height: 60px;
        position: fixed;
        overflow: hidden;
        background-color: white;
        z-index: 1000;
    }
    
    ul.main-site-inner-nav-listing-ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    li.main-site-inner-nav-item-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 30px;
    }

    li.main-site-inner-nav-item-li:not(.nohover) {
        cursor: pointer;
    }
    
    li.main-site-inner-nav-item-li:not(.nohover):hover {
        background-color: rgb(231, 231, 231);
    }
    
    p.main-site-inner-nav-txt-p {
        font-family: 'SF Pro Display', sans-serif;
    }
    
    li.main-site-quick-actions-li { 
        display: none;
    }
    
    button.main-site-quick-action-btn {
        height: 45px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 30px;
        color: white;
        background-color: rgb(0, 94, 255);
        font-family: 'SF Pro Display', sans-serif;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.main-site-quick-action-btn:hover {
        background-color: rgb(0, 102, 255);
    }
    
    li.main-site-mobile-menu-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    nav.mainsite-nav {
        width: 100%;
        padding: 20px 40px;
        height: 100px;
        z-index: 1000;
        top: 0;
        position: -webkit-sticky;
        position: sticky;
    }
    
    ul.main-site-listing-ul {
        width: 100%;
        height: 100%;
    }
    
    li.main-site-logo-li {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    .main-site-logo-link {
        height: 100%;
    }
    
    img.main-site-logo-img {
        height: 100%;
        width: auto;
    }
    
    li.main-site-inner-nav-li {
        display: none;
    }
    
    nav.main-site-inner-nav {
        min-width: 80px;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        height: 60px;
        position: fixed;
        overflow: hidden;
        background-color: white;
        z-index: 1000;
    }
    
    ul.main-site-inner-nav-listing-ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    li.main-site-inner-nav-item-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 30px;
    }

    li.main-site-inner-nav-item-li:not(.nohover) {
        cursor: pointer;
    }
    
    li.main-site-inner-nav-item-li:not(.nohover):hover {
        background-color: rgb(231, 231, 231);
    }
    
    p.main-site-inner-nav-txt-p {
        font-family: 'SF Pro Display', sans-serif;
    }
    
    li.main-site-quick-actions-li { 
        display: none;
    }
    
    button.main-site-quick-action-btn {
        height: 45px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 30px;
        color: white;
        background-color: rgb(0, 94, 255);
        font-family: 'SF Pro Display', sans-serif;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.main-site-quick-action-btn:hover {
        background-color: rgb(0, 102, 255);
    }
    
    li.main-site-mobile-menu-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    nav.mainsite-nav {
        width: 100%;
        padding: 20px 40px;
        height: 100px;
        z-index: 1000;
        top: 0;
        position: -webkit-sticky;
        position: sticky;
    }
    
    ul.main-site-listing-ul {
        width: 100%;
        height: 100%;
    }
    
    li.main-site-logo-li {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    .main-site-logo-link {
        height: 100%;
    }
    
    img.main-site-logo-img {
        height: 100%;
        width: auto;
    }
    
    li.main-site-inner-nav-li {
        display: none;
    }
    
    nav.main-site-inner-nav {
        min-width: 80px;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        height: 60px;
        position: fixed;
        overflow: hidden;
        background-color: white;
        z-index: 1000;
    }
    
    ul.main-site-inner-nav-listing-ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    li.main-site-inner-nav-item-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 30px;
    }

    li.main-site-inner-nav-item-li:not(.nohover) {
        cursor: pointer;
    }
    
    li.main-site-inner-nav-item-li:not(.nohover):hover {
        background-color: rgb(231, 231, 231);
    }
    
    p.main-site-inner-nav-txt-p {
        font-family: 'SF Pro Display', sans-serif;
    }
    
    li.main-site-quick-actions-li { 
        display: none;
    }
    
    button.main-site-quick-action-btn {
        height: 45px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 30px;
        color: white;
        background-color: rgb(0, 94, 255);
        font-family: 'SF Pro Display', sans-serif;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.main-site-quick-action-btn:hover {
        background-color: rgb(0, 102, 255);
    }
    
    li.main-site-mobile-menu-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    nav.mainsite-nav {
        width: 100%;
        padding: 20px 40px;
        height: 100px;
        z-index: 1000;
        top: 0;
        position: -webkit-sticky;
        position: sticky;
    }
    
    ul.main-site-listing-ul {
        width: 100%;
        height: 100%;
    }
    
    li.main-site-logo-li {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    .main-site-logo-link {
        height: 100%;
    }
    
    img.main-site-logo-img {
        height: 100%;
        width: auto;
    }
    
    li.main-site-inner-nav-li {
        display: none;
    }
    
    nav.main-site-inner-nav {
        min-width: 80px;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        height: 60px;
        position: fixed;
        overflow: hidden;
        background-color: white;
        z-index: 1000;
    }
    
    ul.main-site-inner-nav-listing-ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    li.main-site-inner-nav-item-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 30px;
    }

    li.main-site-inner-nav-item-li:not(.nohover) {
        cursor: pointer;
    }
    
    li.main-site-inner-nav-item-li:not(.nohover):hover {
        background-color: rgb(231, 231, 231);
    }
    
    p.main-site-inner-nav-txt-p {
        font-family: 'SF Pro Display', sans-serif;
    }
    
    li.main-site-quick-actions-li { 
        display: none;
    }
    
    button.main-site-quick-action-btn {
        height: 45px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 30px;
        color: white;
        background-color: rgb(0, 94, 255);
        font-family: 'SF Pro Display', sans-serif;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.main-site-quick-action-btn:hover {
        background-color: rgb(0, 102, 255);
    }
    
    li.main-site-mobile-menu-li {
        width: calc(100% - 100px);
        height: 100%;
        float: left;
    }
}

@media (min-width: 1200px) {
    nav.mainsite-nav {
        width: 100%;
        padding: 20px 40px;
        height: 100px;
        z-index: 1000;
        top: 0;
    }
    
    ul.main-site-listing-ul {
        width: 100%;
        height: 100%;
    }
    
    li.main-site-logo-li {
        width: 300px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
    }
    
    .main-site-logo-link {
        height: 100%;
    }
    
    img.main-site-logo-img {
        height: 100%;
        width: auto;
    }
    
    li.main-site-inner-nav-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        float: left;
        width: calc(100% - 600px);
    }
    
    nav.main-site-inner-nav {
        min-width: 80px;
        border-radius: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        height: 60px;
        position: fixed;
        overflow: hidden;
        background-color: white;
        z-index: 1000;
    }
    
    ul.main-site-inner-nav-listing-ul {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    li.main-site-inner-nav-item-li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        padding: 0 30px;
    }

    li.main-site-inner-nav-item-li:not(.nohover) {
        cursor: pointer;
    }
    
    li.main-site-inner-nav-item-li:not(.nohover):hover {
        background-color: rgb(231, 231, 231);
    }
    
    p.main-site-inner-nav-txt-p {
        font-family: 'SF Pro Display', sans-serif;
    }
    
    li.main-site-quick-actions-li { 
        width: 300px;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    
    button.main-site-quick-action-btn {
        height: 45px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 30px;
        color: white;
        background-color: rgb(0, 94, 255);
        font-family: 'SF Pro Display', sans-serif;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    button.main-site-quick-action-btn:hover {
        background-color: rgb(0, 102, 255);
    }

    li.main-site-mobile-menu-li {
        display: none;
    }
}
