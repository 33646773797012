div.de-briefing-grade-step {
    width: 70%;
    margin: 0 auto;
}

div.de-briefing-grade-top-area {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
}

h2.de-briefing-title-h2 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    float: left;
}

div.de-briefing-total-grade {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background-color: black;
    float: right;
}

p.de-briefing-total-grade-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 70px;
}

.de-briefing-slider {
    margin-bottom: 20px;
}