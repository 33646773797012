@media (max-width: 575.98px) {
    h1.payment-info-title-h1 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-info-subtitle-p {
        font-size: 16px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-invoice-info-txt-p {
        
    }
    
    ul.payment-products-listing {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 50px;
    }
    
    li.payment-product-item-li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        overflow: hidden;
    }
    
    div.payment-product-img-area {
        width: 100%;
        float: left;
    }
    
    div.payment-app-icon-div {
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
    }
    
    img.payment-product-img {
        width: 60px;
    }
    
    div.payment-product-info-area {
        width: 100%;
        float: left;
        margin-top: 20px;
    }
    
    p.payment-product-item-name-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-info-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-renew-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-product-pricing-area {
        width: 300px;
        float: right;
        text-align: right;
    }
    
    p.payment-product-item-total-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    p.payment-product-item-btw-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.payment-product-item-btw-amount-span {
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-total-area {
        width: 50%;
        padding: 10px 0;
        float: right
    }
    
    div.payment-total-bar-area {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        float: right
    }
    
    div.payment-total-left-area {
        width: 50%;
        float: left;
    }
    
    div.payment-total-right-area {
        width: 50%;
        float: right;
        text-align: right;
    }
    
    p.total-txt {
        font-weight: bold;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    h1.payment-info-title-h1 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-info-subtitle-p {
        font-size: 16px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-invoice-info-txt-p {
        
    }
    
    ul.payment-products-listing {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 50px;
    }
    
    li.payment-product-item-li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        overflow: hidden;
    }
    
    div.payment-product-img-area {
        width: 100%;
        float: left;
    }
    
    div.payment-app-icon-div {
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
    }
    
    img.payment-product-img {
        width: 60px;
    }
    
    div.payment-product-info-area {
        width: 100%;
        float: left;
        margin-top: 20px;
    }
    
    p.payment-product-item-name-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-info-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-renew-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-product-pricing-area {
        width: 300px;
        float: right;
        text-align: right;
    }
    
    p.payment-product-item-total-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    p.payment-product-item-btw-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.payment-product-item-btw-amount-span {
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-total-area {
        width: 50%;
        padding: 10px 0;
        float: right
    }
    
    div.payment-total-bar-area {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        float: right
    }
    
    div.payment-total-left-area {
        width: 50%;
        float: left;
    }
    
    div.payment-total-right-area {
        width: 50%;
        float: right;
        text-align: right;
    }
    
    p.total-txt {
        font-weight: bold;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    h1.payment-info-title-h1 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-info-subtitle-p {
        font-size: 16px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-invoice-info-txt-p {
        
    }
    
    ul.payment-products-listing {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 50px;
    }
    
    li.payment-product-item-li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        overflow: hidden;
    }
    
    div.payment-product-img-area {
        width: 100px;
        float: left;
    }
    
    div.payment-app-icon-div {
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
    }
    
    img.payment-product-img {
        width: 60px;
    }
    
    div.payment-product-info-area {
        width: calc(100% - 400px);
        float: left;
        padding-left: 10px;
    }
    
    p.payment-product-item-name-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-info-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-renew-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-product-pricing-area {
        width: 300px;
        float: right;
        text-align: right;
    }
    
    p.payment-product-item-total-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    p.payment-product-item-btw-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.payment-product-item-btw-amount-span {
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-total-area {
        width: 50%;
        padding: 10px 0;
        float: right
    }
    
    div.payment-total-bar-area {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        float: right
    }
    
    div.payment-total-left-area {
        width: 50%;
        float: left;
    }
    
    div.payment-total-right-area {
        width: 50%;
        float: right;
        text-align: right;
    }
    
    p.total-txt {
        font-weight: bold;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    h1.payment-info-title-h1 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-info-subtitle-p {
        font-size: 16px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-invoice-info-txt-p {
        
    }
    
    ul.payment-products-listing {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 50px;
    }
    
    li.payment-product-item-li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        overflow: hidden;
    }
    
    div.payment-product-img-area {
        width: 100px;
        float: left;
    }
    
    div.payment-app-icon-div {
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
    }
    
    img.payment-product-img {
        width: 60px;
    }
    
    div.payment-product-info-area {
        width: calc(100% - 400px);
        float: left;
        padding-left: 10px;
    }
    
    p.payment-product-item-name-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-info-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-renew-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-product-pricing-area {
        width: 300px;
        float: right;
        text-align: right;
    }
    
    p.payment-product-item-total-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    p.payment-product-item-btw-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.payment-product-item-btw-amount-span {
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-total-area {
        width: 50%;
        padding: 10px 0;
        float: right
    }
    
    div.payment-total-bar-area {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        float: right
    }
    
    div.payment-total-left-area {
        width: 50%;
        float: left;
    }
    
    div.payment-total-right-area {
        width: 50%;
        float: right;
        text-align: right;
    }
    
    p.total-txt {
        font-weight: bold;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    h1.payment-info-title-h1 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-info-subtitle-p {
        font-size: 16px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-invoice-info-txt-p {
        
    }
    
    ul.payment-products-listing {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 50px;
    }
    
    li.payment-product-item-li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        overflow: hidden;
    }
    
    div.payment-product-img-area {
        width: 100px;
        float: left;
    }
    
    div.payment-app-icon-div {
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
    }
    
    img.payment-product-img {
        width: 60px;
    }
    
    div.payment-product-info-area {
        width: calc(100% - 400px);
        float: left;
        padding-left: 10px;
    }
    
    p.payment-product-item-name-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-info-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-renew-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-product-pricing-area {
        width: 300px;
        float: right;
        text-align: right;
    }
    
    p.payment-product-item-total-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    p.payment-product-item-btw-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.payment-product-item-btw-amount-span {
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-total-area {
        width: 50%;
        padding: 10px 0;
        float: right
    }
    
    div.payment-total-bar-area {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        float: right
    }
    
    div.payment-total-left-area {
        width: 50%;
        float: left;
    }
    
    div.payment-total-right-area {
        width: 50%;
        float: right;
        text-align: right;
    }
    
    p.total-txt {
        font-weight: bold;
    }
}

@media (min-width: 1450px) {
    h1.payment-info-title-h1 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-info-subtitle-p {
        font-size: 16px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p.payment-invoice-info-txt-p {
        
    }
    
    ul.payment-products-listing {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 50px;
    }
    
    li.payment-product-item-li {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;
        padding: 10px 0;
        overflow: hidden;
    }
    
    div.payment-product-img-area {
        width: 100px;
        float: left;
    }
    
    div.payment-app-icon-div {
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
    }
    
    img.payment-product-img {
        width: 60px;
    }
    
    div.payment-product-info-area {
        width: calc(100% - 400px);
        float: left;
        padding-left: 10px;
    }
    
    p.payment-product-item-name-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-info-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.payment-product-item-renew-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-product-pricing-area {
        width: 300px;
        float: right;
        text-align: right;
    }
    
    p.payment-product-item-total-p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin-top: 20px;
    }
    
    p.payment-product-item-btw-p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span.payment-product-item-btw-amount-span {
        font-size: 14px;
        color: #5d5d5d;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.payment-total-area {
        width: 50%;
        padding: 10px 0;
        float: right
    }
    
    div.payment-total-bar-area {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        float: right
    }
    
    div.payment-total-left-area {
        width: 50%;
        float: left;
    }
    
    div.payment-total-right-area {
        width: 50%;
        float: right;
        text-align: right;
    }
    
    p.total-txt {
        font-weight: bold;
    }
}

