@media (max-width: 575.98px) {
    div#thingslist-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.thinglist-type-column {
        width: 100%;
    }
    
    div.thinglist-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#thingslist-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.thinglist-type-column {
        width: 100%;
    }
    
    div.thinglist-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#thingslist-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.thinglist-type-column {
        width: 100%;
    }
    
    div.thinglist-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#thingslist-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.thinglist-type-column {
        width: 100%;
    }
    
    div.thinglist-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 1200px) {
    div#thingslist-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
    }
    
    div.thinglist-type-column {
        width: 100%;
    }
    
    div.thinglist-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}