@media (max-width: 575.98px) {
    h3.reportreceivers-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 20px;
        display: inline-block;
    }
    
    div.report-receivers-top-controls-area {
        width: 100%;
    }
    
    .styled-table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .styled-table th, .styled-table td {
        padding: 8px;
        border-bottom: 1px solid lightgray;
        padding: 15px 20px;
        position: relative;
    }
    
    div.dropdown-menu {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        padding: 10px;
        right: 0;
        top: 60px;
        border: 1px solid #d1d1d1;
    }
    
    .styled-table th {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        text-align: left;
        padding: 10px 20px;
    }
    
    button.table-options-btn {
        float: right;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.table-options-btn:hover {
        background-color: #efefef;
    }
    
    .profile-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #0077ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        line-height: 50px;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.table-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        float: left;
        line-height: 50px;
        margin-left: 10px;
    }
    
    button.reportreceivers-add-receiver-btn {
        height: 40px;
        background-color: #0073ff;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        margin: 20px 0;
        padding: 0 15px 0 10px;
        float: right;
    }
    
    button.reportreceivers-add-receiver-btn:hover {
        background-color: #0059e6;
    }
    
    span.reportreceivers-icon-span {
        font-size: 30px;
        vertical-align: middle;
        line-height: 40px;
    }
    
    span.reportreceivers-txt-span {
        font-size: 16px;
        line-height: 40px;
        vertical-align: middle;
        margin-left: 5px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    ul.dropdown-menu-listing {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li.dropdown-menu-item {
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 5px;
        border-radius: 10px;
    }
    
    li.dropdown-menu-item:hover {
        background-color: #eaeaea;
    }

    th.reportreceiver-email-th {
        display: none;
    }

    th.reportreceiver-phone-th {
        display: none;
    }

    td.reportreceiver-email-td {
        display: none;
    }

    td.reportreceiver-phone-td {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    h3.reportreceivers-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 20px;
        display: inline-block;
    }
    
    div.report-receivers-top-controls-area {
        width: 100%;
    }
    
    .styled-table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .styled-table th, .styled-table td {
        padding: 8px;
        border-bottom: 1px solid lightgray;
        padding: 15px 20px;
        position: relative;
    }
    
    div.dropdown-menu {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        padding: 10px;
        right: 0;
        top: 60px;
        border: 1px solid #d1d1d1;
    }
    
    .styled-table th {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        text-align: left;
        padding: 10px 20px;
    }
    
    button.table-options-btn {
        float: right;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.table-options-btn:hover {
        background-color: #efefef;
    }
    
    .profile-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #0077ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        line-height: 50px;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.table-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        float: left;
        line-height: 50px;
        margin-left: 10px;
    }
    
    button.reportreceivers-add-receiver-btn {
        height: 40px;
        background-color: #0073ff;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        margin: 20px 0;
        padding: 0 15px 0 10px;
        float: right;
    }
    
    button.reportreceivers-add-receiver-btn:hover {
        background-color: #0059e6;
    }
    
    span.reportreceivers-icon-span {
        font-size: 30px;
        vertical-align: middle;
        line-height: 40px;
    }
    
    span.reportreceivers-txt-span {
        font-size: 16px;
        line-height: 40px;
        vertical-align: middle;
        margin-left: 5px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    ul.dropdown-menu-listing {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li.dropdown-menu-item {
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 5px;
        border-radius: 10px;
    }
    
    li.dropdown-menu-item:hover {
        background-color: #eaeaea;
    }

    th.reportreceiver-email-th {
        display: none;
    }

    th.reportreceiver-phone-th {
        display: none;
    }

    td.reportreceiver-email-td {
        display: none;
    }

    td.reportreceiver-phone-td {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    h3.reportreceivers-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 20px;
        display: inline-block;
    }
    
    div.report-receivers-top-controls-area {
        width: 100%;
    }
    
    .styled-table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .styled-table th, .styled-table td {
        padding: 8px;
        border-bottom: 1px solid lightgray;
        padding: 15px 20px;
        position: relative;
    }
    
    div.dropdown-menu {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        padding: 10px;
        right: 0;
        top: 60px;
        border: 1px solid #d1d1d1;
    }
    
    .styled-table th {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        text-align: left;
        padding: 10px 20px;
    }
    
    button.table-options-btn {
        float: right;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.table-options-btn:hover {
        background-color: #efefef;
    }
    
    .profile-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #0077ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        line-height: 50px;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.table-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        float: left;
        line-height: 50px;
        margin-left: 10px;
    }
    
    button.reportreceivers-add-receiver-btn {
        height: 40px;
        background-color: #0073ff;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        margin: 20px 0;
        padding: 0 15px 0 10px;
        float: right;
    }
    
    button.reportreceivers-add-receiver-btn:hover {
        background-color: #0059e6;
    }
    
    span.reportreceivers-icon-span {
        font-size: 30px;
        vertical-align: middle;
        line-height: 40px;
    }
    
    span.reportreceivers-txt-span {
        font-size: 16px;
        line-height: 40px;
        vertical-align: middle;
        margin-left: 5px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    ul.dropdown-menu-listing {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li.dropdown-menu-item {
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 5px;
        border-radius: 10px;
    }
    
    li.dropdown-menu-item:hover {
        background-color: #eaeaea;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    h3.reportreceivers-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 20px;
        display: inline-block;
    }
    
    div.report-receivers-top-controls-area {
        width: 100%;
    }
    
    .styled-table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .styled-table th, .styled-table td {
        padding: 8px;
        border-bottom: 1px solid lightgray;
        padding: 15px 20px;
        position: relative;
    }
    
    div.dropdown-menu {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        padding: 10px;
        right: 0;
        top: 60px;
        border: 1px solid #d1d1d1;
    }
    
    .styled-table th {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        text-align: left;
        padding: 10px 20px;
    }
    
    button.table-options-btn {
        float: right;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.table-options-btn:hover {
        background-color: #efefef;
    }
    
    .profile-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #0077ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        line-height: 50px;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.table-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        float: left;
        line-height: 50px;
        margin-left: 10px;
    }
    
    button.reportreceivers-add-receiver-btn {
        height: 40px;
        background-color: #0073ff;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        margin: 20px 0;
        padding: 0 15px 0 10px;
        float: right;
    }
    
    button.reportreceivers-add-receiver-btn:hover {
        background-color: #0059e6;
    }
    
    span.reportreceivers-icon-span {
        font-size: 30px;
        vertical-align: middle;
        line-height: 40px;
    }
    
    span.reportreceivers-txt-span {
        font-size: 16px;
        line-height: 40px;
        vertical-align: middle;
        margin-left: 5px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    ul.dropdown-menu-listing {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li.dropdown-menu-item {
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 5px;
        border-radius: 10px;
    }
    
    li.dropdown-menu-item:hover {
        background-color: #eaeaea;
    }

    th.reportreceiver-phone-th {
        display: none;
    }

    td.reportreceiver-phone-td {
        display: none;
    }
}

@media (min-width: 1200px) {
    h3.reportreceivers-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        padding: 20px;
        display: inline-block;
    }
    
    div.report-receivers-top-controls-area {
        width: 100%;
    }
    
    .styled-table {
        width: 100%;
        border-collapse: collapse;
    }
    
    .styled-table th, .styled-table td {
        padding: 8px;
        border-bottom: 1px solid lightgray;
        padding: 15px 20px;
        position: relative;
    }
    
    div.dropdown-menu {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        padding: 10px;
        right: 0;
        top: 60px;
        border: 1px solid #d1d1d1;
    }
    
    .styled-table th {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        text-align: left;
        padding: 10px 20px;
    }
    
    button.table-options-btn {
        float: right;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    button.table-options-btn:hover {
        background-color: #efefef;
    }
    
    .profile-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #0077ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        line-height: 50px;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.table-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        margin: 0;
        padding: 0;
        font-weight: bold;
        float: left;
        line-height: 50px;
        margin-left: 10px;
    }
    
    button.reportreceivers-add-receiver-btn {
        height: 40px;
        background-color: #0073ff;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        margin: 20px 0;
        padding: 0 15px 0 10px;
        float: right;
    }
    
    button.reportreceivers-add-receiver-btn:hover {
        background-color: #0059e6;
    }
    
    span.reportreceivers-icon-span {
        font-size: 30px;
        vertical-align: middle;
        line-height: 40px;
    }
    
    span.reportreceivers-txt-span {
        font-size: 16px;
        line-height: 40px;
        vertical-align: middle;
        margin-left: 5px;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    ul.dropdown-menu-listing {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li.dropdown-menu-item {
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 5px;
        border-radius: 10px;
    }
    
    li.dropdown-menu-item:hover {
        background-color: #eaeaea;
    }
}

p.no-data-text {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 50px 0;
    padding: 20px;
    text-align: center;
}