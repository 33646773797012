@media (max-width: 575.98px) {
    div#created-things-list-container {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }
    
    div#created-things-list-inner-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
    
    div.created-thing-item-div {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        padding: 50px 0;
    }
    
    div.created-thing-item-div:hover {
        opacity: 0.85;
    }
    
    div.created-thing-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-icon-bubble {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-info-bubble {
        width: 100%;
        margin-top: 10px;
    }
    
    h1.created-thing-title-h1 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.created-thing-desc-p {
        font-size: 15px;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#created-things-list-container {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }
    
    div#created-things-list-inner-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
    
    div.created-thing-item-div {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        padding: 50px 0;
    }
    
    div.created-thing-item-div:hover {
        opacity: 0.85;
    }
    
    div.created-thing-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-icon-bubble {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-info-bubble {
        width: 100%;
        margin-top: 10px;
    }
    
    h1.created-thing-title-h1 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.created-thing-desc-p {
        font-size: 15px;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#created-things-list-container {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }
    
    div#created-things-list-inner-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
    
    div.created-thing-item-div {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        padding: 50px 0;
    }
    
    div.created-thing-item-div:hover {
        opacity: 0.85;
    }
    
    div.created-thing-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-icon-bubble {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-info-bubble {
        width: 100%;
        margin-top: 10px;
    }
    
    h1.created-thing-title-h1 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.created-thing-desc-p {
        font-size: 15px;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#created-things-list-container {
        width: 100%;
        height: calc(100% - 60px);
        padding: 10px;
        display: flex;
        align-items: center;
    }
    
    div#created-things-list-inner-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
        height: 450px;
    }
    
    div.created-thing-item-div {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
    }
    
    div.created-thing-item-div:hover {
        opacity: 0.85;
    }
    
    div.created-thing-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-icon-bubble {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-info-bubble {
        width: 100%;
        margin-top: 10px;
    }
    
    h1.created-thing-title-h1 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.created-thing-desc-p {
        font-size: 15px;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
}

@media (min-width: 1200px) {
    div#created-things-list-container {
        width: 100%;
        height: calc(100% - 60px);
        padding: 10px;
        display: flex;
        align-items: center;
    }
    
    div#created-things-list-inner-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
        height: 450px;
    }
    
    div.created-thing-item-div {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
    }
    
    div.created-thing-item-div:hover {
        opacity: 0.85;
    }
    
    div.created-thing-inner-area {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-icon-bubble {
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.created-thing-info-bubble {
        width: 100%;
        margin-top: 10px;
    }
    
    h1.created-thing-title-h1 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.created-thing-desc-p {
        font-size: 15px;
        margin: 0;
        color: white;
        text-align: center;
        font-family: 'SF Pro Display', sans-serif;
    }
}