
button.main-portal-menu-under-btn {
    width: 100%;
    height: 50px;
    float: left;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-open button.main-portal-menu-under-btn {
    width: calc(100% / 6);
}

button.main-portal-menu-under-btn.right {
    float: right;
}

button.main-portal-menu-under-btn:hover {
    background-color: #efefef;
}