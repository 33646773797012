div.verify-account-content {
    height: 100%;
    min-height: 100%;
    width: 100%;
}

h1.account-verify-title-h1 {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'SF Pro Display', 'Arial', sans-serif;
}

p.account-verify-txt-p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'SF Pro Display', 'Arial', sans-serif;
}

div.verification-wrapper-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.verification-input-container {
	display: flex;
	justify-content: center;
	gap: 12px;
	margin: 20px 0;
}

.verification-input {
	width: 60px;
	height: 70px;
	text-align: center;
	font-size: 24px;
    border: 1px solid #dfdfdf;
	border-radius: 8px;
	outline: none;
	transition: border-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
}

.verification-input:focus {
	border-color: #007bff;
	box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.verification-input::placeholder {
	color: #ccc;
}

button.verification-resend-confirm-code-btn {
    color: #007bff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    font-family: 'SF Pro Display', sans-serif;
    display: block;
    margin: 0 auto;
}

button.verification-resend-confirm-code-btn:hover {
    background-color: #f1f1f1;
}