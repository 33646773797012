ul.addplanthing-listing-ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

form.addplanthing-form {
    width: 100%;
}

button.addplanthing-submit-control-btn {
    width: 100px;
    height: 40px;
    background-color: rgb(0, 110, 255);
    color: white;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}

button.addplanthing-submit-control-btn:hover {
    background-color: rgb(0, 90, 235);
}

button.addplanthing-cancel-control-btn {
    width: 100px;
    height: 40px;
    background-color: white;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 10px;
    color: black;
}

button.addplanthing-cancel-control-btn:hover {
    background-color: #f0f0f0;
}