div.inner-div {
    width: 80%;
    display: block;
    margin: auto;
}

h1.privacy-h1-title {
    font-family: 'SF Pro Display';
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 50px;
    font-size: 30px;
}

p.privacy-txt-p {
    font-family: 'SF Pro Display';
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
}

h3.privacy-h3-title {
    font-family: 'SF Pro Display';
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 20px;
}

ul.privacy-listing-ul {
    font-family: 'SF Pro Display';
    margin: 0;
    margin-bottom: 20px;
}

li.privacy-txt-li {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'SF Pro Display';
}

ul#number-list {
    list-style-type: decimal;
    padding-left: 60px;
}

header {
    padding: 0;
    margin: 0;
}

section {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}