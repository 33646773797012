@media (max-width: 575.98px) {
    div.buycredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.buycredits-wrapper-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.buycredits-inner-div {
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        min-height: 100%;
        position: relative
    }
    
    div.buycredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.buycredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.buycredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.buycredits-close-btn span {
        font-size: 2em;
    }
    
    div.buycredits-content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    h2.buycredits-title {
        font-size: 1.5em;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        padding-right: 55px;
    }
    
    p.buycredits-txt-p {
        font-size: 0.9em;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.creditbuyer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.buycredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.buycredits-wrapper-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.buycredits-inner-div {
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        min-height: 100%;
        position: relative
    }
    
    div.buycredits-top-area {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: right;
    }
    
    button.buycredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.buycredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.buycredits-close-btn span {
        font-size: 2em;
    }
    
    div.buycredits-content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    h2.buycredits-title {
        font-size: 1.5em;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        padding-right: 55px;
    }
    
    p.buycredits-txt-p {
        font-size: 0.9em;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.creditbuyer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.buycredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.buycredits-wrapper-div {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.buycredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
        position: relative
    }
    
    div.buycredits-top-area {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: right;
        position: absolute;
        right: 20px;
    }
    
    button.buycredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.buycredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.buycredits-close-btn span {
        font-size: 2em;
    }
    
    div.buycredits-content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    h2.buycredits-title {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        padding-right: 55px;
    }
    
    p.buycredits-txt-p {
        font-size: 1em;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.creditbuyer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 50px 0;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.buycredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.buycredits-wrapper-div {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.buycredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
        position: relative
    }
    
    div.buycredits-top-area {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: right;
        position: absolute;
        right: 20px;
    }
    
    button.buycredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.buycredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.buycredits-close-btn span {
        font-size: 2em;
    }
    
    div.buycredits-content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    h2.buycredits-title {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        padding-right: 55px;
    }
    
    p.buycredits-txt-p {
        font-size: 1em;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.creditbuyer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 50px 0;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.buycredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.buycredits-wrapper-div {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.buycredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
        position: relative
    }
    
    div.buycredits-top-area {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: right;
        position: absolute;
        right: 20px;
    }
    
    button.buycredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.buycredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.buycredits-close-btn span {
        font-size: 2em;
    }
    
    div.buycredits-content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    h2.buycredits-title {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        padding-right: 55px;
    }
    
    p.buycredits-txt-p {
        font-size: 1em;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.creditbuyer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 50px 0;
    }
}

@media (min-width: 1450px) {
    div.buycredits-overlay-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto;
    }
    
    div.buycredits-wrapper-div {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.buycredits-inner-div {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 700px;
        width: 80%;
        margin: 50px 0;
        position: relative
    }
    
    div.buycredits-top-area {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: right;
        position: absolute;
        right: 20px;
    }
    
    button.buycredits-close-btn {
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    
    button.buycredits-close-btn:hover {
        background-color: #efefef;
    }
    
    button.buycredits-close-btn span {
        font-size: 2em;
    }
    
    div.buycredits-content-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    h2.buycredits-title {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        padding-right: 55px;
    }
    
    p.buycredits-txt-p {
        font-size: 1em;
        margin: 0;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1.2;
    }
    
    div.creditbuyer-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 50px 0;
    }
}