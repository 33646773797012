@media (max-width: 575.98px) {
    footer.main-page-footer {
        width: 100%;
        min-height: 300px;
        background-color: #000000;
        margin-top: 50px;
        border-top: 1px solid #e0e0e0;
    }

    ul.main-page-footer-listing-ul {
        max-width: 90%;
        margin: 0 auto;
        padding: 50px 0;
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-item-li {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    h4.main-page-footer-item-title-h4 {
        color: #ffffff;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
    }

    ul.main-page-footer-links-listing {
        padding: 0;
        /* Remove default padding */
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-links-item-li {
        margin-bottom: 10px;
    }

    p.main-page-footer-link-p {
        color: #dddddd;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        display: inline-block;
        cursor: pointer;
    }

    p.main-page-footer-link-p:hover {
        color: white;
    }

    ul.main-page-under-footer-listing-ul {
        width: 90%;
        margin: 0 auto;
        overflow: hidden;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    li.main-page-under-footer-item-li {
        width: 100%;
        margin-bottom: 20px;
    }

    p.main-page-under-footer-txt-p {
        color: #ffffff;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
        text-align: center;
    }

    p.main-page-under-footer-txt-p.copyright {}

    img.main-page-footer-logo-img {
        width: 150px;
        margin-bottom: 10px;
    }

    p.main-page-footer-txt-p {
        color: #ffffff;
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }

    p.main-page-footer-txt-p.extra-space {
        margin-bottom: 10px;
    }

    span.main-page-footer-var-span {
        color: #ffffff;
        width: 50px;
        font-weight: bold;
        display: inline-block;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    footer.main-page-footer {
        width: 100%;
        min-height: 300px;
        background-color: #000000;
        margin-top: 50px;
        border-top: 1px solid #e0e0e0;
    }

    ul.main-page-footer-listing-ul {
        max-width: 90%;
        margin: 0 auto;
        padding: 50px 0;
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-item-li {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    h4.main-page-footer-item-title-h4 {
        color: #ffffff;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
    }

    ul.main-page-footer-links-listing {
        padding: 0;
        /* Remove default padding */
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-links-item-li {
        margin-bottom: 10px;
    }

    p.main-page-footer-link-p {
        color: #dddddd;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        display: inline-block;
        cursor: pointer;
    }

    p.main-page-footer-link-p:hover {
        color: white;
    }

    ul.main-page-under-footer-listing-ul {
        width: 90%;
        margin: 0 auto;
        overflow: hidden;
    }

    li.main-page-under-footer-item-li {
        width: 100%;
        margin-bottom: 20px;
    }

    p.main-page-under-footer-txt-p {
        color: #ffffff;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
        text-align: center;
    }

    p.main-page-under-footer-txt-p.copyright {}

    img.main-page-footer-logo-img {
        width: 150px;
        margin-bottom: 10px;
    }

    p.main-page-footer-txt-p {
        color: #ffffff;
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }

    p.main-page-footer-txt-p.extra-space {
        margin-bottom: 10px;
    }

    span.main-page-footer-var-span {
        color: #ffffff;
        width: 50px;
        font-weight: bold;
        display: inline-block;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    footer.main-page-footer {
        width: 100%;
        min-height: 300px;
        background-color: #000000;
        margin-top: 50px;
        border-top: 1px solid #e0e0e0;
    }

    ul.main-page-footer-listing-ul {
        max-width: 90%;
        margin: 0 auto;
        padding: 50px 0;
        list-style: none;
        /* Remove default list style */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    li.main-page-footer-item-li {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    h4.main-page-footer-item-title-h4 {
        color: #ffffff;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
    }

    ul.main-page-footer-links-listing {
        padding: 0;
        /* Remove default padding */
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-links-item-li {
        margin-bottom: 10px;
    }

    p.main-page-footer-link-p {
        color: #dddddd;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        display: inline-block;
        cursor: pointer;
    }

    p.main-page-footer-link-p:hover {
        color: white;
    }

    ul.main-page-under-footer-listing-ul {
        width: 90%;
        margin: 0 auto;
        overflow: hidden;
        margin-bottom: 50px;
    }

    li.main-page-under-footer-item-li {
        width: 100%;
        margin-bottom: 20px;
    }

    p.main-page-under-footer-txt-p {
        color: #ffffff;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
        text-align: center;
    }

    p.main-page-under-footer-txt-p.copyright {}

    img.main-page-footer-logo-img {
        width: 150px;
        margin-bottom: 10px;
    }

    p.main-page-footer-txt-p {
        color: #ffffff;
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }

    p.main-page-footer-txt-p.extra-space {
        margin-bottom: 10px;
    }

    span.main-page-footer-var-span {
        color: #ffffff;
        width: 50px;
        font-weight: bold;
        display: inline-block;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

    footer.main-page-footer {
        width: 100%;
        min-height: 300px;
        background-color: #000000;
        margin-top: 50px;
        border-top: 1px solid #e0e0e0;
    }

    ul.main-page-footer-listing-ul {
        width: 1200px;
        max-width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 100px 0;
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-item-li {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    h4.main-page-footer-item-title-h4 {
        color: #ffffff;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
    }

    ul.main-page-footer-links-listing {
        padding: 0;
        /* Remove default padding */
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-links-item-li {
        margin-bottom: 10px;
    }

    p.main-page-footer-link-p {
        color: #dddddd;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        display: inline-block;
        cursor: pointer;
    }

    p.main-page-footer-link-p:hover {
        color: white;
    }

    ul.main-page-under-footer-listing-ul {
        width: 1200px;
        max-width: 90%;
        height: 80px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-content: end;
    }

    li.main-page-under-footer-item-li {
        height: 100%;
    }

    p.main-page-under-footer-txt-p {
        color: #ffffff;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        display: inline-block;
        cursor: pointer;
    }

    p.main-page-under-footer-txt-p.copyright {
        margin-right: 100px;
    }

    img.main-page-footer-logo-img {
        width: 150px;
        margin-bottom: 10px;
    }

    p.main-page-footer-txt-p {
        color: #ffffff;
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }

    p.main-page-footer-txt-p.extra-space {
        margin-bottom: 10px;
    }

    span.main-page-footer-var-span {
        color: #ffffff;
        width: 50px;
        font-weight: bold;
        display: inline-block;
    }
}

@media (min-width: 1200px) {

    footer.main-page-footer {
        width: 100%;
        min-height: 300px;
        background-color: #000000;
        margin-top: 50px;
        border-top: 1px solid #e0e0e0;
    }

    ul.main-page-footer-listing-ul {
        width: 1200px;
        max-width: 90%;
        margin: 0 auto;
        padding: 100px 0 50px 0;
        list-style: none;
    }

    div.main-page-footer-wrapper {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    li.main-page-footer-item-li {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    h4.main-page-footer-item-title-h4 {
        color: #ffffff;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
    }

    ul.main-page-footer-links-listing {
        padding: 0;
        /* Remove default padding */
        list-style: none;
        /* Remove default list style */
    }

    li.main-page-footer-links-item-li {
        margin-bottom: 10px;
    }

    p.main-page-footer-link-p {
        color: #dddddd;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        display: inline-block;
        cursor: pointer;
    }

    p.main-page-footer-link-p:hover {
        color: white;
    }

    ul.main-page-under-footer-listing-ul {
        width: 1200px;
        max-width: 90%;
        height: 80px;
        margin: 0 auto;
    }

    li.main-page-under-footer-item-li {
        height: 100%;
    }

    p.main-page-under-footer-txt-p {
        color: #ffffff;
        font-size: 18px;
        font-family: 'SF Pro Display', sans-serif;
        cursor: pointer;
        text-align: center;
        line-height: 80px;
    }

    img.main-page-footer-logo-img {
        width: 150px;
        margin-bottom: 10px;
    }

    p.main-page-footer-txt-p {
        color: #ffffff;
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
    }

    p.main-page-footer-txt-p.extra-space {
        margin-bottom: 10px;
    }

    span.main-page-footer-var-span {
        color: #ffffff;
        width: 50px;
        font-weight: bold;
        display: inline-block;
    }
}