    .credits-bar {
        background-color: white;
        border-radius: 20px;
        padding: 0px 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        padding: 5px;
        max-width: 100px;
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }

    .credits-bar:hover {
        background-color: rgb(236, 236, 236);
    }

    .credits-bar:focus {
        background-color: rgb(236, 236, 236);
    }

    .credits-icon {
        font-size: 1.5em;
        /* Grootte van het icoon */
        color: #FFD700;
        /* Goudkleur voor de munten */
    }

    .credits-value {
        color: black;
        padding: 5px;
        border-radius: 5px;
        font-weight: bold;
    }