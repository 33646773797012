li.main-portal-menu-item-li {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin-bottom: 5px;
}

a.main-portal-menu-item-href {
    text-decoration: none;
    color: black;
}

div.main-portal-menu-list-item-div {
    width: 100%;
    height: 50px;
    list-style-type: none;
    border-radius: 10px;
    padding: 7.5px;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 5px;
}


div.main-portal-menu-list-item-div:hover:not(.hovered) {
    background-color: #efefef;
}

div.main-portal-menu-item-icon-area {
    width: 35px;
    height: 100%;
    float: left;
}

span.main-portal-menu-item-icon-span {
    float: left;
    font-size: 35px;
}

nav#main-portal-nav.nav-open div.main-portal-menu-item-txt-area {
    display: block;
}

div.main-portal-menu-item-txt-area {
    width: calc(100% - 70px);
    height: 100%;
    float: left;
    display: none;
}

p.main-portal-menu-item-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    margin: 0;
    font-size: 18px;
    line-height: 35px;
    margin-left: 10px;
}