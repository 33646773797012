div#view-created-addthings-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

ul.created-addthings-list {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}