div.created-addthing-item {
    width: 100%;
    min-height: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
}

div.selected-addthing-div {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50px;
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

span.selected-addthing-icon-span {
    color: green;
    font-size: 20px;
}

div.created-addthing-item:hover {
    opacity: 0.85;
}

h2.created-addthing-title-h2 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    color: white;
    font-family: 'SF Pro Display', sans-serif;
}

hr.created-addthing-divder {
    width: 100%;
    margin: 10px 0;
}

p.created-addthing-txt-p {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: 'SF Pro Display', sans-serif;
    color: white;
}