@media (max-width: 575.98px) {
    div.company-info-content-div {
        width: 100%;
        min-height: 100%;
    }

    label.register-company-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.register-company-dropdown-div {
        width: 100%;
        height: 50px;
        margin-top: 10px;
        position: relative;
    }
    
    div.register-company-info-overview-item-div {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }
    
    div.register-company-info-left-area {
        /* width: calc((100% / 2) - 10px); */
        width: 100%;
        min-height: inherit;
        float: left;
    }
    
    div.register-company-info-left-area.housenumber {
        width: 100%;
        /* width: calc((60%) - 10px); */
    }
    
    div.register-company-info-right-area {
        /* width: calc((100% / 2) - 10px); */
        /* margin-left: 20px; */
        width: 100%;
        min-height: inherit;
        float: right;
    }
    
    div.register-company-info-right-area.housenumber {
        width: 100%;
        min-height: inherit;
        float: right;
        /* width: calc((40%) - 10px); */
        /* margin-left: 20px; */
    }
    
    input.register-company-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
    }
    
    div.register-company-dropdown-div.open input.register-company-input {
        border-radius: 10px 10px 0 0;
    }
    
    input.register-company-input:focus {
        outline: none;
    }
    
    div.register-company-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: 100%;
        min-height: 50px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        max-height: 325px;
        overflow: auto;
    }
    
    div.register-company-dropdown-div.open div.register-company-dropdown-content {
        display: block;
    }
    
    ul.register-company-dropdown-content-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    li.register-company-dropdown-content-item-li {
        list-style-type: none;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        box-sizing: border-box;
    }
    
    li.register-company-dropdown-content-item-li:hover {
        background-color: #f1f1f1;
    }
    
    li.register-company-dropdown-content-item-li:last-child {
        border-bottom: none;
    }
    
    p.register-company-dropdown-content-item-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.register-company-dropdown-under-content-txt-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 0.8em;
        color: gray;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.company-info-content-div {
        width: 100%;
        min-height: 100%;
    }

    label.register-company-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.register-company-dropdown-div {
        width: 100%;
        height: 50px;
        margin-top: 10px;
        position: relative;
    }
    
    div.register-company-info-overview-item-div {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }
    
    div.register-company-info-left-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: left;
    }
    
    div.register-company-info-left-area.housenumber {
        width: calc((60%) - 10px);
    }
    
    div.register-company-info-right-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    div.register-company-info-right-area.housenumber {
        width: calc((40%) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    input.register-company-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
    }
    
    div.register-company-dropdown-div.open input.register-company-input {
        border-radius: 10px 10px 0 0;
    }
    
    input.register-company-input:focus {
        outline: none;
    }
    
    div.register-company-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: 100%;
        min-height: 50px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        max-height: 325px;
        overflow: auto;
    }
    
    div.register-company-dropdown-div.open div.register-company-dropdown-content {
        display: block;
    }
    
    ul.register-company-dropdown-content-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    li.register-company-dropdown-content-item-li {
        list-style-type: none;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        box-sizing: border-box;
    }
    
    li.register-company-dropdown-content-item-li:hover {
        background-color: #f1f1f1;
    }
    
    li.register-company-dropdown-content-item-li:last-child {
        border-bottom: none;
    }
    
    p.register-company-dropdown-content-item-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.register-company-dropdown-under-content-txt-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 0.8em;
        color: gray;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.company-info-content-div {
        width: 100%;
        min-height: 100%;
    }

    label.register-company-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.register-company-dropdown-div {
        width: 100%;
        height: 50px;
        margin-top: 10px;
        position: relative;
    }
    
    div.register-company-info-overview-item-div {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }
    
    div.register-company-info-left-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: left;
    }
    
    div.register-company-info-left-area.housenumber {
        width: calc((60%) - 10px);
    }
    
    div.register-company-info-right-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    div.register-company-info-right-area.housenumber {
        width: calc((40%) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    input.register-company-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
    }
    
    div.register-company-dropdown-div.open input.register-company-input {
        border-radius: 10px 10px 0 0;
    }
    
    input.register-company-input:focus {
        outline: none;
    }
    
    div.register-company-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: 100%;
        min-height: 50px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        max-height: 325px;
        overflow: auto;
    }
    
    div.register-company-dropdown-div.open div.register-company-dropdown-content {
        display: block;
    }
    
    ul.register-company-dropdown-content-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    li.register-company-dropdown-content-item-li {
        list-style-type: none;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        box-sizing: border-box;
    }
    
    li.register-company-dropdown-content-item-li:hover {
        background-color: #f1f1f1;
    }
    
    li.register-company-dropdown-content-item-li:last-child {
        border-bottom: none;
    }
    
    p.register-company-dropdown-content-item-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.register-company-dropdown-under-content-txt-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 0.8em;
        color: gray;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.company-info-content-div {
        width: 100%;
        min-height: 100%;
    }

    label.register-company-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.register-company-dropdown-div {
        width: 100%;
        height: 50px;
        margin-top: 10px;
        position: relative;
    }
    
    div.register-company-info-overview-item-div {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }
    
    div.register-company-info-left-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: left;
    }
    
    div.register-company-info-left-area.housenumber {
        width: calc((60%) - 10px);
    }
    
    div.register-company-info-right-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    div.register-company-info-right-area.housenumber {
        width: calc((40%) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    input.register-company-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
    }
    
    div.register-company-dropdown-div.open input.register-company-input {
        border-radius: 10px 10px 0 0;
    }
    
    input.register-company-input:focus {
        outline: none;
    }
    
    div.register-company-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: 100%;
        min-height: 50px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        max-height: 325px;
        overflow: auto;
    }
    
    div.register-company-dropdown-div.open div.register-company-dropdown-content {
        display: block;
    }
    
    ul.register-company-dropdown-content-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    li.register-company-dropdown-content-item-li {
        list-style-type: none;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        box-sizing: border-box;
    }
    
    li.register-company-dropdown-content-item-li:hover {
        background-color: #f1f1f1;
    }
    
    li.register-company-dropdown-content-item-li:last-child {
        border-bottom: none;
    }
    
    p.register-company-dropdown-content-item-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.register-company-dropdown-under-content-txt-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 0.8em;
        color: gray;
    }
}

@media (min-width: 1200px) {
    div.company-info-content-div {
        width: 100%;
        min-height: 100%;
    }
    
    label.register-company-label {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    div.register-company-dropdown-div {
        width: 100%;
        height: 50px;
        margin-top: 10px;
        position: relative;
    }
    
    div.register-company-info-overview-item-div {
        width: 100%;
        min-height: 50px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
    }
    
    div.register-company-info-left-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: left;
    }
    
    div.register-company-info-left-area.housenumber {
        width: calc((60%) - 10px);
    }
    
    div.register-company-info-right-area {
        width: calc((100% / 2) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    div.register-company-info-right-area.housenumber {
        width: calc((40%) - 10px);
        min-height: inherit;
        float: right;
        margin-left: 20px;
    }
    
    input.register-company-input {
        width: 100%;
        height: 50px;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 0 10px;
    }
    
    div.register-company-dropdown-div.open input.register-company-input {
        border-radius: 10px 10px 0 0;
    }
    
    input.register-company-input:focus {
        outline: none;
    }
    
    div.register-company-dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        width: 100%;
        min-height: 50px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 0 0 10px 10px;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        max-height: 325px;
        overflow: auto;
    }
    
    div.register-company-dropdown-div.open div.register-company-dropdown-content {
        display: block;
    }
    
    ul.register-company-dropdown-content-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    li.register-company-dropdown-content-item-li {
        list-style-type: none;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid lightgray;
        box-sizing: border-box;
    }
    
    li.register-company-dropdown-content-item-li:hover {
        background-color: #f1f1f1;
    }
    
    li.register-company-dropdown-content-item-li:last-child {
        border-bottom: none;
    }
    
    p.register-company-dropdown-content-item-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    p.register-company-dropdown-under-content-txt-p {
        margin: 0;
        padding: 0;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 0.8em;
        color: gray;
    }
}

