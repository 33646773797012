div.inner-div {
    width: 80%;
    display: block;
    margin: auto;
}

h1.general-terms-title-h1 {
    font-family: 'SF Pro Display';
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 50px;
    font-size: 30px;
}

p.general-terms-txt-p {
    font-family: 'SF Pro Display';
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
}

h3.general-terms-title-h3 {
    font-family: 'SF Pro Display';
    font-weight: bold;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 20px;
}

ul.general-terms-listing-ul {
    font-family: 'SF Pro Display';
    margin: 0;
    margin-bottom: 20px;
}

li.general-terms-item-li {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'SF Pro Display';
}

ul#number-list {
    list-style-type: decimal;
}

header {
    padding: 0;
    margin: 0;
}

section {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
}