label.forgot-password-email-input {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: bold;
    float: left;
    margin-bottom: 10px;
}

input.forgot-password-input {
    width: 100%;
    height: 40px;
    border: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 7.5px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    box-sizing: border-box;
    font-family: 'SF Pro Display', sans-serif;
}