.vip-widget {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    height: auto;
}

div.vip-widget-inner-title-area {
    width: calc(100% - 35px);
    height: 50px;
    float: left;
}

button.vip-widget-add-btn {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    background-color: black;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    float: left;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.vip-widget-add-btn:hover {
    background-color: #333;
}

span.vip-widget-add-icon-span {
    color: white;
}

.VIP-widget-txt {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.vip-widget-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.vip-widget-row {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    flex-wrap: wrap;
}

.vip-widget-cell {
    display: flex;
    align-items: center;
    padding: 10px;
}

.vip-widget-cell.fixed {
    flex: 0 0 auto;
    min-width: 150px;
}

.vip-widget-cell.flexible {
    flex: 1 1 auto;
    text-align: right;
}

.vip-widget-cell .highlight {
    font-weight: bold;
    margin-right: 5px;
}

.pax-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    text-align: center;
    line-height: 1.2;
}

.pax-text {
    font-size: 10px;
    color: #333;
    margin-top: 2px;
}

.vip-widget-bijzonderheden {
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
}

.pax-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid black; 
    background-color: transparent; 
    color: black; /* Kleur van de tekst */
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    text-align: center;
    line-height: 1.2;
}

.pax-text {
    font-size: 10px;
    color: black; /* Kleur van de tekst onder de cirkel */
    margin-top: 2px;
}

div.vipwidget-no-vip-div {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
}

p.vip-widget-no-vips-txt {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #333;
}
