@media (max-width: 575.98px) {
    div#things-list-pre-briefing-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        min-height: inherit;
    }
    
    div.thinglist-pre-briefing-type-column {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 20px;
    }

    div.thinglist-pre-briefing-type-column:not(.active) {
        display: none;
    }
    
    div.thinglist-pre-briefing-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-pre-briefing-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#things-list-pre-briefing-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        min-height: inherit;
    }
    
    div.thinglist-pre-briefing-type-column {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 20px;
    }

    div.thinglist-pre-briefing-type-column:not(.active) {
        display: none;
    }
    
    div.thinglist-pre-briefing-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-pre-briefing-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#things-list-pre-briefing-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        min-height: inherit;
    }
    
    div.thinglist-pre-briefing-type-column {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 20px;
    }

    div.thinglist-pre-briefing-type-column:not(.active) {
        display: none;
    }
    
    div.thinglist-pre-briefing-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-pre-briefing-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#things-list-pre-briefing-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        min-height: inherit;
    }
    
    div.thinglist-pre-briefing-type-column {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 20px;
    }

    div.thinglist-pre-briefing-type-column:not(.active) {
        display: none;
    }
    
    div.thinglist-pre-briefing-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-pre-briefing-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

@media (min-width: 1200px) {
    div#things-list-pre-briefing-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        min-height: inherit;
    }
    
    div.thinglist-pre-briefing-type-column {
        width: 100%;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
    }

    /* div.thinglist-pre-briefing-type-column:hover {
        background-color: black;
    } */
    
    div.thinglist-pre-briefing-inner-container {
        display: flex;
        justify-content: center;
    }
    
    h1.thingtype-title-h1 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        margin: 0;
        margin-bottom: 10px;
        float: left;
        font-weight: bold;
    }
    
    span.thingtype-info-icon-span {
        font-size: 24px;
        vertical-align: middle;
        float: left;
        line-height: 24px;
    }
    
    ul.thinglist-pre-briefing-listing-ul {
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    
    div.add-action-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    button.add-action-btn {
        width: 50px;
        height: 50px;
        background-color: black;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    
    span.add-action-icon-span {
        color: white;
        font-size: 35px;
    }
}

div.signature.thinglist-pre-briefing-type-column:hover {
    background-color: rgb(182, 182, 255);
}

div.thinglist-pre-briefing-type-column.active.signature {
    background-color: rgb(182, 182, 255);
}

div.suggestion.thinglist-pre-briefing-type-column:hover {
    background-color: rgb(255, 151, 255);
}

div.suggestion.thinglist-pre-briefing-type-column.active {
    background-color: rgb(255, 151, 255);
}

div.task.thinglist-pre-briefing-type-column:hover {
    background-color: rgb(127, 255, 169);
}

div.task.thinglist-pre-briefing-type-column.active {
    background-color: rgb(127, 255, 169);
}

div.process.thinglist-pre-briefing-type-column:hover {
    background-color: rgb(255, 127, 127);
}

div.process.thinglist-pre-briefing-type-column.active {
    background-color: rgb(255, 127, 127);
}

div.thing.thinglist-pre-briefing-type-column:hover {
    background-color: rgb(255, 227, 127);
}

div.thing.thinglist-pre-briefing-type-column.active {
    background-color: rgb(255, 227, 127);
}