@media (max-width: 575.98px) {
    div#calendarview-controls-area {
        width: 100%;
        height: 50px;
        overflow: hidden;
        border-bottom: 1px solid lightgray;
        padding: 0 10px;
    }

    div#calendarview-month-txt-area {
        width: calc(100% - 90px);
        height: 100%;
        float: left;
    }

    h3#calendarview-month-txt-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        font-weight: bold;
        line-height: 40px;
    }

    div#calendarview-center-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }

    div#calendarview-right-controls-area {
        width: 90px;
        height: 100%;
        float: right;
    }

    div#calendarview-controls-container {
        float: right;
    }

    button#calendarview-right-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }

    span.calendarview-icon-span {
        vertical-align: middle;
    }

    button#calendarview-today-control-btn {
        height: 40px;
        padding: 0 50px;
        float: left;
        background-color: white;
        border: 1px solid lightgray;
        margin: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 10px;
        display: none;
    }

    button#calendarview-left-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        margin-left: 10px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#calendarview-controls-area {
        width: 100%;
        height: 50px;
        overflow: hidden;
        border-bottom: 1px solid lightgray;
        padding: 0 10px;
    }

    div#calendarview-month-txt-area {
        width: 25%;
        height: 100%;
        float: left;
    }

    h3#calendarview-month-txt-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        font-weight: bold;
    }

    div#calendarview-center-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }

    div#calendarview-right-controls-area {
        width: 50%;
        height: 100%;
        float: right;
    }

    div#calendarview-controls-container {
        float: right;
    }

    button#calendarview-right-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }

    span.calendarview-icon-span {
        vertical-align: middle;
    }

    button#calendarview-today-control-btn {
        height: 40px;
        padding: 0 50px;
        float: left;
        background-color: white;
        border: 1px solid lightgray;
        margin: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 10px;
    }

    button#calendarview-left-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#calendarview-controls-area {
        width: 100%;
        height: 50px;
        overflow: hidden;
        border-bottom: 1px solid lightgray;
        padding: 0 10px;
    }

    div#calendarview-month-txt-area {
        width: 25%;
        height: 100%;
        float: left;
    }

    h3#calendarview-month-txt-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        font-weight: bold;
    }

    div#calendarview-center-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }

    div#calendarview-right-controls-area {
        width: 50%;
        height: 100%;
        float: right;
    }

    div#calendarview-controls-container {
        float: right;
    }

    button#calendarview-right-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }

    span.calendarview-icon-span {
        vertical-align: middle;
    }

    button#calendarview-today-control-btn {
        height: 40px;
        padding: 0 50px;
        float: left;
        background-color: white;
        border: 1px solid lightgray;
        margin: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 10px;
    }

    button#calendarview-left-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#calendarview-controls-area {
        width: 100%;
        height: 50px;
        overflow: hidden;
        border-bottom: 1px solid lightgray;
        padding: 0 10px;
    }

    div#calendarview-month-txt-area {
        width: 25%;
        height: 100%;
        float: left;
    }

    h3#calendarview-month-txt-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        font-weight: bold;
    }

    div#calendarview-center-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }

    div#calendarview-right-controls-area {
        width: 50%;
        height: 100%;
        float: right;
    }

    div#calendarview-controls-container {
        float: right;
    }

    button#calendarview-right-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }

    span.calendarview-icon-span {
        vertical-align: middle;
    }

    button#calendarview-today-control-btn {
        height: 40px;
        padding: 0 50px;
        float: left;
        background-color: white;
        border: 1px solid lightgray;
        margin: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 10px;
    }

    button#calendarview-left-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }
}

@media (min-width: 1200px) {
    div#calendarview-controls-area {
        width: 100%;
        height: 50px;
        overflow: hidden;
        border-bottom: 1px solid lightgray;
        padding: 0 10px;
    }

    div#calendarview-month-txt-area {
        width: 430px;
        height: 100%;
        float: left;
    }

    h3#calendarview-month-txt-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 30px;
        margin: 0;
        font-weight: bold;
    }

    div#calendarview-center-area {
        width: calc(100% - 860px);
        height: 100%;
        float: left;
    }

    div#calendarview-right-controls-area {
        width: 430px;
        height: 100%;
        float: right;
    }

    button.calendarview-add-event-btn {
        height: 40px;
        background-color: rgb(0, 94, 255);
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
    }

    span.calendarview-add-event-icon-span {
        vertical-align: middle;
    }

    span.calendarview-add-event-txt-span {
        vertical-align: middle;
        margin-left: 10px;
    }

    div#calendarview-controls-container {
        float: right;
    }

    button#calendarview-right-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }

    span.calendarview-icon-span {
        vertical-align: middle;
    }

    button#calendarview-today-control-btn {
        height: 40px;
        padding: 0 50px;
        float: left;
        background-color: white;
        border: 1px solid lightgray;
        margin: 0 10px;
        font-family: 'SF Pro Display', sans-serif;
        border-radius: 10px;
    }

    button#calendarview-left-control-btn {
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid lightgray;
        border-radius: 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
    }
}