h3.deleteaccount-title-h3 {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 24px;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

p.deleteaccount-txt-p {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    margin-top: 5px;
}

ul.deleteaccount-listing-ul {
    list-style-type: disc;
    margin-left: 20px;
}

ul.deleteaccount-listing-ul li {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    margin-left: 20px;
    margin-top: 5px;
}

button.delete-account-btn {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    background-color: #dc3545;
    color: white;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
}

button.delete-account-btn:hover {
    background-color: #c82333;
}