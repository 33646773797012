div.edit-vip-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

div.edit-vip-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.edit-vip-modal-container {
    width: 100%;
    width: 800px;
    min-height: 100px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    padding: 20px;
}

div.edit-vip-modal-top-area {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

div.edit-vip-modal-top-inner-title-area {
    width: calc(100% - 50px);
    height: 50px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: start;
}

h2.edit-vip-modal-title {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 0;
    padding: 0;
}

div.edit-vip-modal-top-inner-close-area {
    width: 50px;
    height: 50px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: end;
}

button.edit-vip-modal-close-btn {
    width: 40px;
    height: 40px;
    background-color: white;
    border: none;
    font-weight: bold;
    color: black;
    float: right;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

button.edit-vip-modal-close-btn:hover {
    background-color: #efefef;
}

button.edit-vip-modal-close-btn span {
    color: black;
    font-size: 30px;
}

div.edit-vip-modal-content {
    width: 100%;
}

.edit-vip-modal-slider {
    width: 100%;
}

.edit-vip-modal-input {
    width: 100%;
    margin-bottom: 15px;
}

div.edit-vip-modal-controls-area {
    width: 100%;
    height: 50px;
    margin-top: 20px;
}

button.edit-vip-modal-add-btn {
    height: 40px;
    background-color: rgb(0, 110, 255);
    color: white;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    padding: 0 15px;
}

button.edit-vip-modal-add-btn:hover {
    background-color: rgb(0, 94, 255);
}

button.edit-vip-modal-cancel-btn {
    height: 40px;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 10px;
    padding: 0 15px;
}

button.edit-vip-modal-cancel-btn:hover {
    background-color: #efefef;
}