@media (max-width: 575.98px) {
    div.payment-left-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        box-sizing: border-box;
        display: none;
    }
    
    img.payment-img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    div.payment-right-area {
        width: 100%;
        height: 100%;
        float: right;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        overflow: auto;
        position: relative;
    }
    
    button.cancel-payment-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 15px;
        color: black;
    }
    
    button.cancel-payment-btn:hover {
        background-color: #eaeaea;
    }
    
    span.cancel-payment-icon {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    div.payment-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.payment-inner-content-div {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    img.payment-logo-img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }
    
    div#payment-pay-progress-area-div {
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: 50px;
    }
    
    ul#payment-pay-progress-bar-ul {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: #d1d1d1;
        display: flex;
        margin-top: 40px;
    }
    
    li#payment-pay-progress-step-li {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    li#payment-pay-progress-step-li.active {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span {
        width: 15px;
        height: 15px;
        top: -5.5px;
        border-radius: 50%;
        background-color: #d1d1d1;
        position: absolute;
    }
    
    span#payment-pay-step-icon-span.inprogress {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span.active {
        width: 30px;
        height: 30px;
        top: -12.5px;
        background-color: #000000;
    }
    
    span#step-icon {
        width: 100%;
        font-size: 20px;
        color: white;
        text-align: center;
        line-height: 30px;
        display: none;
    }
    
    span#payment-pay-step-icon-span.active span#step-icon {
        display: block;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-icon-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span.active:nth-child(2) {
        left: -10px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(2) {
        left: -7.5px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(4) {
        right: 0;
    }
    
    span#payment-pay-step-txt-span {
        font-family: 'SF Pro Display', sans-serif;
        position: absolute;
        top: -44px;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-txt-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(1) {
        left: -25px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(3) {
        right: 0;
    }
    
    div.payment-content-area {
        width: 100%;
        min-height: 200px;
    }
    
    ul.payment-products-listing {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    div.payment-controls-area {
        width: 100%;
        margin-top: 20px;
        float: left;
    }
    
    button.payment-next-btn {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    
    div.payment-confirm-txt-area {
        width: 100%;
        float: left;
        margin-top: 50px;
    }
    
    p.payment-confirm-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        text-align: center;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.payment-left-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        box-sizing: border-box;
        display: none;
    }
    
    img.payment-img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    div.payment-right-area {
        width: 100%;
        height: 100%;
        float: right;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        overflow: auto;
        position: relative;
    }
    
    button.cancel-payment-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 15px;
        color: black;
    }
    
    button.cancel-payment-btn:hover {
        background-color: #eaeaea;
    }
    
    span.cancel-payment-icon {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    div.payment-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.payment-inner-content-div {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    img.payment-logo-img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }
    
    div#payment-pay-progress-area-div {
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: 50px;
    }
    
    ul#payment-pay-progress-bar-ul {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: #d1d1d1;
        display: flex;
        margin-top: 40px;
    }
    
    li#payment-pay-progress-step-li {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    li#payment-pay-progress-step-li.active {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span {
        width: 15px;
        height: 15px;
        top: -5.5px;
        border-radius: 50%;
        background-color: #d1d1d1;
        position: absolute;
    }
    
    span#payment-pay-step-icon-span.inprogress {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span.active {
        width: 30px;
        height: 30px;
        top: -12.5px;
        background-color: #000000;
    }
    
    span#step-icon {
        width: 100%;
        font-size: 20px;
        color: white;
        text-align: center;
        line-height: 30px;
        display: none;
    }
    
    span#payment-pay-step-icon-span.active span#step-icon {
        display: block;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-icon-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span.active:nth-child(2) {
        left: -10px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(2) {
        left: -7.5px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(4) {
        right: 0;
    }
    
    span#payment-pay-step-txt-span {
        font-family: 'SF Pro Display', sans-serif;
        position: absolute;
        top: -44px;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-txt-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(1) {
        left: -25px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(3) {
        right: 0;
    }
    
    div.payment-content-area {
        width: 100%;
        min-height: 200px;
    }
    
    ul.payment-products-listing {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    div.payment-controls-area {
        width: 100%;
        margin-top: 20px;
        float: left;
    }
    
    button.payment-next-btn {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    
    div.payment-confirm-txt-area {
        width: 100%;
        float: left;
        margin-top: 50px;
    }
    
    p.payment-confirm-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.payment-left-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        box-sizing: border-box;
        display: none;
    }
    
    img.payment-img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    div.payment-right-area {
        width: 100%;
        height: 100%;
        float: right;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        overflow: auto;
        position: relative;
    }
    
    button.cancel-payment-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 15px;
        color: black;
    }
    
    button.cancel-payment-btn:hover {
        background-color: #eaeaea;
    }
    
    span.cancel-payment-icon {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    div.payment-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.payment-inner-content-div {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    img.payment-logo-img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }
    
    div#payment-pay-progress-area-div {
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: 50px;
    }
    
    ul#payment-pay-progress-bar-ul {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: #d1d1d1;
        display: flex;
        margin-top: 40px;
    }
    
    li#payment-pay-progress-step-li {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    li#payment-pay-progress-step-li.active {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span {
        width: 15px;
        height: 15px;
        top: -5.5px;
        border-radius: 50%;
        background-color: #d1d1d1;
        position: absolute;
    }
    
    span#payment-pay-step-icon-span.inprogress {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span.active {
        width: 30px;
        height: 30px;
        top: -12.5px;
        background-color: #000000;
    }
    
    span#step-icon {
        width: 100%;
        font-size: 20px;
        color: white;
        text-align: center;
        line-height: 30px;
        display: none;
    }
    
    span#payment-pay-step-icon-span.active span#step-icon {
        display: block;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-icon-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span.active:nth-child(2) {
        left: -10px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(2) {
        left: -7.5px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(4) {
        right: 0;
    }
    
    span#payment-pay-step-txt-span {
        font-family: 'SF Pro Display', sans-serif;
        position: absolute;
        top: -44px;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-txt-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(1) {
        left: -25px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(3) {
        right: 0;
    }
    
    div.payment-content-area {
        width: 100%;
        min-height: 200px;
    }
    
    ul.payment-products-listing {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    div.payment-controls-area {
        width: 100%;
        margin-top: 20px;
        float: left;
    }
    
    button.payment-next-btn {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    
    div.payment-confirm-txt-area {
        width: 100%;
        float: left;
        margin-top: 50px;
    }
    
    p.payment-confirm-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        text-align: center;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.payment-left-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        box-sizing: border-box;
    }
    
    img.payment-img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    div.payment-right-area {
        width: 800px;
        height: 100%;
        float: right;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        overflow: auto;
        position: relative;
    }
    
    button.cancel-payment-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 15px;
        color: black;
    }
    
    button.cancel-payment-btn:hover {
        background-color: #eaeaea;
    }
    
    span.cancel-payment-icon {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    div.payment-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.payment-inner-content-div {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    img.payment-logo-img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }
    
    div#payment-pay-progress-area-div {
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: 50px;
    }
    
    ul#payment-pay-progress-bar-ul {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: #d1d1d1;
        display: flex;
        margin-top: 40px;
    }
    
    li#payment-pay-progress-step-li {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    li#payment-pay-progress-step-li.active {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span {
        width: 15px;
        height: 15px;
        top: -5.5px;
        border-radius: 50%;
        background-color: #d1d1d1;
        position: absolute;
    }
    
    span#payment-pay-step-icon-span.inprogress {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span.active {
        width: 30px;
        height: 30px;
        top: -12.5px;
        background-color: #000000;
    }
    
    span#step-icon {
        width: 100%;
        font-size: 20px;
        color: white;
        text-align: center;
        line-height: 30px;
        display: none;
    }
    
    span#payment-pay-step-icon-span.active span#step-icon {
        display: block;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-icon-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span.active:nth-child(2) {
        left: -10px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(2) {
        left: -7.5px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(4) {
        right: 0;
    }
    
    span#payment-pay-step-txt-span {
        font-family: 'SF Pro Display', sans-serif;
        position: absolute;
        top: -44px;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-txt-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(1) {
        left: -25px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(3) {
        right: 0;
    }
    
    div.payment-content-area {
        width: 100%;
        min-height: 200px;
    }
    
    ul.payment-products-listing {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    div.payment-controls-area {
        width: 100%;
        margin-top: 20px;
        float: left;
    }
    
    button.payment-next-btn {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    
    div.payment-confirm-txt-area {
        width: 100%;
        float: left;
        margin-top: 50px;
    }
    
    p.payment-confirm-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        text-align: center;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.payment-left-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        box-sizing: border-box;
    }
    
    img.payment-img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    div.payment-right-area {
        width: 800px;
        height: 100%;
        float: right;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        overflow: auto;
        position: relative;
    }
    
    button.cancel-payment-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 15px;
        color: black;
    }
    
    button.cancel-payment-btn:hover {
        background-color: #eaeaea;
    }
    
    span.cancel-payment-icon {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    div.payment-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.payment-inner-content-div {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    img.payment-logo-img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }
    
    div#payment-pay-progress-area-div {
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: 50px;
    }
    
    ul#payment-pay-progress-bar-ul {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: #d1d1d1;
        display: flex;
        margin-top: 40px;
    }
    
    li#payment-pay-progress-step-li {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    li#payment-pay-progress-step-li.active {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span {
        width: 15px;
        height: 15px;
        top: -5.5px;
        border-radius: 50%;
        background-color: #d1d1d1;
        position: absolute;
    }
    
    span#payment-pay-step-icon-span.inprogress {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span.active {
        width: 30px;
        height: 30px;
        top: -12.5px;
        background-color: #000000;
    }
    
    span#step-icon {
        width: 100%;
        font-size: 20px;
        color: white;
        text-align: center;
        line-height: 30px;
        display: none;
    }
    
    span#payment-pay-step-icon-span.active span#step-icon {
        display: block;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-icon-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span.active:nth-child(2) {
        left: -10px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(2) {
        left: -7.5px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(4) {
        right: 0;
    }
    
    span#payment-pay-step-txt-span {
        font-family: 'SF Pro Display', sans-serif;
        position: absolute;
        top: -44px;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-txt-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(1) {
        left: -25px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(3) {
        right: 0;
    }
    
    div.payment-content-area {
        width: 100%;
        min-height: 200px;
    }
    
    ul.payment-products-listing {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    div.payment-controls-area {
        width: 100%;
        margin-top: 20px;
        float: left;
    }
    
    button.payment-next-btn {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    
    div.payment-confirm-txt-area {
        width: 100%;
        float: left;
        margin-top: 50px;
    }
    
    p.payment-confirm-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        text-align: center;
    }
}

@media (min-width: 1450px) {
    div.payment-left-area {
        width: calc(100% - 800px);
        height: 100%;
        float: left;
        box-sizing: border-box;
    }
    
    img.payment-img-left {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    div.payment-right-area {
        width: 800px;
        height: 100%;
        float: right;
        box-sizing: border-box;
        padding: 20px;
        background-color: white;
        overflow: auto;
        position: relative;
    }
    
    button.cancel-payment-btn {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: white;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-weight: bold;
        padding: 5px 15px;
        color: black;
    }
    
    button.cancel-payment-btn:hover {
        background-color: #eaeaea;
    }
    
    span.cancel-payment-icon {
        font-size: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }
    
    div.payment-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.payment-inner-content-div {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
    }
    
    img.payment-logo-img {
        width: 100px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
    }
    
    div#payment-pay-progress-area-div {
        width: 100%;
        height: 70px;
        overflow: hidden;
        margin-bottom: 50px;
    }
    
    ul#payment-pay-progress-bar-ul {
        width: 100%;
        height: 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        background-color: #d1d1d1;
        display: flex;
        margin-top: 40px;
    }
    
    li#payment-pay-progress-step-li {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    li#payment-pay-progress-step-li.active {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span {
        width: 15px;
        height: 15px;
        top: -5.5px;
        border-radius: 50%;
        background-color: #d1d1d1;
        position: absolute;
    }
    
    span#payment-pay-step-icon-span.inprogress {
        background-color: #000000;
    }
    
    span#payment-pay-step-icon-span.active {
        width: 30px;
        height: 30px;
        top: -12.5px;
        background-color: #000000;
    }
    
    span#step-icon {
        width: 100%;
        font-size: 20px;
        color: white;
        text-align: center;
        line-height: 30px;
        display: none;
    }
    
    span#payment-pay-step-icon-span.active span#step-icon {
        display: block;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-icon-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span.active:nth-child(2) {
        left: -10px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(2) {
        left: -7.5px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-icon-span:nth-child(4) {
        right: 0;
    }
    
    span#payment-pay-step-txt-span {
        font-family: 'SF Pro Display', sans-serif;
        position: absolute;
        top: -44px;
    }
    
    li#payment-pay-progress-step-li.step0 span#payment-pay-step-txt-span:nth-child(1) {
        left: 0;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(1) {
        left: -25px;
    }
    
    li#payment-pay-progress-step-li.step1 span#payment-pay-step-txt-span:nth-child(3) {
        right: 0;
    }
    
    div.payment-content-area {
        width: 100%;
        min-height: 200px;
    }
    
    ul.payment-products-listing {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    div.payment-controls-area {
        width: 100%;
        margin-top: 20px;
        float: left;
    }
    
    button.payment-next-btn {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    
    div.payment-confirm-txt-area {
        width: 100%;
        float: left;
        margin-top: 50px;
    }
    
    p.payment-confirm-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        text-align: center;
    }
}