@media (max-width: 575.98px) {
  .pdf-viewer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    overflow: auto;
  }
  
  div.pdf-modal-viewer-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  div#pdf-container {
    width: 90%;
  }
  
  nav.pdf-viewer-modal-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid lightgray;
  }
  
  .pdf-viewer-controls {
    top: 10px;
    display: flex;
    gap: 10px;
  }
  
  canvas.pdf-viewer-modal-canvas {
    border: 1px solid #ccc;
    margin: 50px 0;
  }
  
  canvas.pdf-viewer-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
  }
  
  div.pdf-modal-viewer-under-controls {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    padding: 0 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  div.pdf-modal-viewer-under-controls:hover {
    background-color: rgb(232, 232, 232);
  }
  
  div.pdf-modal-viewer-under-controls button {
    width: 50px;
    height: 50px;
  }
  
  span.pdf-modal-close-span {
    font-size: 50px
  }
  
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .pdf-viewer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    overflow: auto;
  }
  
  div.pdf-modal-viewer-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  div#pdf-container {
    width: 90%;
  }
  
  nav.pdf-viewer-modal-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid lightgray;
  }
  
  .pdf-viewer-controls {
    top: 10px;
    display: flex;
    gap: 10px;
  }
  
  canvas.pdf-viewer-modal-canvas {
    border: 1px solid #ccc;
    margin: 50px 0;
  }
  
  canvas.pdf-viewer-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
  }
  
  div.pdf-modal-viewer-under-controls {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    padding: 0 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  div.pdf-modal-viewer-under-controls:hover {
    background-color: rgb(232, 232, 232);
  }
  
  div.pdf-modal-viewer-under-controls button {
    width: 50px;
    height: 50px;
  }
  
  span.pdf-modal-close-span {
    font-size: 50px
  }
  
  
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .pdf-viewer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    overflow: auto;
  }
  
  div.pdf-modal-viewer-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  div#pdf-container {
    width: 90%;
  }
  
  nav.pdf-viewer-modal-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid lightgray;
  }
  
  .pdf-viewer-controls {
    top: 10px;
    display: flex;
    gap: 10px;
  }
  
  canvas.pdf-viewer-modal-canvas {
    border: 1px solid #ccc;
    margin: 50px 0;
  }
  
  canvas.pdf-viewer-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
  }
  
  div.pdf-modal-viewer-under-controls {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    padding: 0 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  div.pdf-modal-viewer-under-controls:hover {
    background-color: rgb(232, 232, 232);
  }
  
  div.pdf-modal-viewer-under-controls button {
    width: 50px;
    height: 50px;
  }
  
  span.pdf-modal-close-span {
    font-size: 50px
  }
  
  
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pdf-viewer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    overflow: auto;
  }
  
  div.pdf-modal-viewer-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  div#pdf-container {
    width: 90%;
  }
  
  nav.pdf-viewer-modal-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid lightgray;
  }
  
  .pdf-viewer-controls {
    top: 10px;
    display: flex;
    gap: 10px;
  }
  
  canvas.pdf-viewer-modal-canvas {
    border: 1px solid #ccc;
    margin: 50px 0;
  }
  
  canvas.pdf-viewer-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
  }
  
  div.pdf-modal-viewer-under-controls {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    padding: 0 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  div.pdf-modal-viewer-under-controls:hover {
    background-color: rgb(232, 232, 232);
  }
  
  div.pdf-modal-viewer-under-controls button {
    width: 50px;
    height: 50px;
  }
  
  span.pdf-modal-close-span {
    font-size: 50px
  }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
  .pdf-viewer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    overflow: auto;
  }
  
  div.pdf-modal-viewer-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  div#pdf-container {
    width: 90%;
  }
  
  nav.pdf-viewer-modal-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid lightgray;
  }
  
  .pdf-viewer-controls {
    top: 10px;
    display: flex;
    gap: 10px;
  }
  
  canvas.pdf-viewer-modal-canvas {
    border: 1px solid #ccc;
    margin: 50px 0;
  }
  
  canvas.pdf-viewer-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
  }
  
  div.pdf-modal-viewer-under-controls {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    padding: 0 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  div.pdf-modal-viewer-under-controls:hover {
    background-color: rgb(232, 232, 232);
  }
  
  div.pdf-modal-viewer-under-controls button {
    width: 50px;
    height: 50px;
  }
  
  span.pdf-modal-close-span {
    font-size: 50px
  }
  

}

@media (min-width: 1450px) {
  .pdf-viewer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    overflow: auto;
  }
  
  div.pdf-modal-viewer-modal-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  
  nav.pdf-viewer-modal-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid lightgray;
  }
  
  .pdf-viewer-controls {
    top: 10px;
    display: flex;
    gap: 10px;
  }
  
  canvas.pdf-viewer-modal-canvas {
    border: 1px solid #ccc;
    margin: 50px 0;
  }
  
  canvas.pdf-viewer-page {
    border: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  div.pdf-modal-viewer-under-controls {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid lightgray;
    padding: 0 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  div.pdf-modal-viewer-under-controls:hover {
    background-color: rgb(232, 232, 232);
  }
  
  div.pdf-modal-viewer-under-controls button {
    width: 50px;
    height: 50px;
  }
  
  span.pdf-modal-close-span {
    font-size: 50px
  }
}