@media (max-width: 575.98px) {
    div.selectable-label-btn-wrapper {
        display: inline-block;
        border-radius: 20px;
        padding: 2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        margin: 5px;
    }
    
    button.selectable-label-btn {
        background-color: #f5f5f5;
        border: none;
        border-radius: 18px;
        padding: 5px 20px;
        cursor: pointer;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        color: #333;
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-weight: bold;
    }
    
    button.selectable-label-btn.selected {
        outline: none;
        position: relative;
        z-index: 1;
        color: white;
    }
    
    button.selectable-label-btn.selected::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        border-radius: 18px;
        z-index: -1;
    }
    
    div.selectedlabels-controls-div {
        width: 100%;
        height: 50px;
        margin-top: 20px;
    }
    
    button.selectedlabels-cancel-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        float: right;
        margin-right: 20px;
    }
    
    button.selectedlabels-cancel-btn:hover {
        background-color: #d1d1d1;
    }
    
    button.selectedlabels-save-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
        outline: none;
        float: right;
    }
    
    button.selectedlabels-save-btn:hover {
        background-color: #0056b3;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.selectable-label-btn-wrapper {
        display: inline-block;
        border-radius: 20px;
        padding: 2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        margin: 5px;
    }
    
    button.selectable-label-btn {
        background-color: #f5f5f5;
        border: none;
        border-radius: 18px;
        padding: 5px 20px;
        cursor: pointer;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        color: #333;
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-weight: bold;
    }
    
    button.selectable-label-btn.selected {
        outline: none;
        position: relative;
        z-index: 1;
        color: white;
    }
    
    button.selectable-label-btn.selected::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        border-radius: 18px;
        z-index: -1;
    }
    
    div.selectedlabels-controls-div {
        width: 100%;
        height: 50px;
    }
    
    button.selectedlabels-cancel-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        float: right;
        margin-right: 20px;
    }
    
    button.selectedlabels-cancel-btn:hover {
        background-color: #d1d1d1;
    }
    
    button.selectedlabels-save-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
        outline: none;
        float: right;
    }
    
    button.selectedlabels-save-btn:hover {
        background-color: #0056b3;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.selectable-label-btn-wrapper {
        display: inline-block;
        border-radius: 20px;
        padding: 2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        margin: 5px;
    }
    
    button.selectable-label-btn {
        background-color: #f5f5f5;
        border: none;
        border-radius: 18px;
        padding: 5px 20px;
        cursor: pointer;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        color: #333;
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-weight: bold;
    }
    
    button.selectable-label-btn.selected {
        outline: none;
        position: relative;
        z-index: 1;
        color: white;
    }
    
    button.selectable-label-btn.selected::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        border-radius: 18px;
        z-index: -1;
    }
    
    div.selectedlabels-controls-div {
        width: 100%;
        height: 50px;
    }
    
    button.selectedlabels-cancel-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        float: right;
        margin-right: 20px;
    }
    
    button.selectedlabels-cancel-btn:hover {
        background-color: #d1d1d1;
    }
    
    button.selectedlabels-save-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
        outline: none;
        float: right;
    }
    
    button.selectedlabels-save-btn:hover {
        background-color: #0056b3;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.selectable-label-btn-wrapper {
        display: inline-block;
        border-radius: 20px;
        padding: 2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        margin: 5px;
    }
    
    button.selectable-label-btn {
        background-color: #f5f5f5;
        border: none;
        border-radius: 18px;
        padding: 5px 20px;
        cursor: pointer;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        color: #333;
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-weight: bold;
    }
    
    button.selectable-label-btn.selected {
        outline: none;
        position: relative;
        z-index: 1;
        color: white;
    }
    
    button.selectable-label-btn.selected::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        border-radius: 18px;
        z-index: -1;
    }
    
    div.selectedlabels-controls-div {
        width: 100%;
        height: 50px;
    }
    
    button.selectedlabels-cancel-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        float: right;
        margin-right: 20px;
    }
    
    button.selectedlabels-cancel-btn:hover {
        background-color: #d1d1d1;
    }
    
    button.selectedlabels-save-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
        outline: none;
        float: right;
    }
    
    button.selectedlabels-save-btn:hover {
        background-color: #0056b3;
    }
}

@media (min-width: 1200px) {
    div.selectable-label-btn-wrapper {
        display: inline-block;
        border-radius: 20px;
        padding: 2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        margin: 5px;
    }
    
    button.selectable-label-btn {
        background-color: #f5f5f5;
        border: none;
        border-radius: 18px;
        padding: 5px 20px;
        cursor: pointer;
        font-size: 14px;
        font-family: 'SF Pro Display', sans-serif;
        color: #333;
        display: block;
        width: 100%;
        box-sizing: border-box;
        font-weight: bold;
    }
    
    button.selectable-label-btn.selected {
        outline: none;
        position: relative;
        z-index: 1;
        color: white;
    }
    
    button.selectable-label-btn.selected::after {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, rgb(17, 77, 207), rgb(0, 108, 191));
        border-radius: 18px;
        z-index: -1;
    }
    
    div.selectedlabels-controls-div {
        width: 100%;
        height: 50px;
    }
    
    button.selectedlabels-cancel-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        float: right;
        margin-right: 20px;
    }
    
    button.selectedlabels-cancel-btn:hover {
        background-color: #d1d1d1;
    }
    
    button.selectedlabels-save-btn {
        height: 45px;
        border: none;
        border-radius: 10px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background-color: #007bff;
        cursor: pointer;
        outline: none;
        float: right;
    }
    
    button.selectedlabels-save-btn:hover {
        background-color: #0056b3;
    }
}