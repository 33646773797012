@media (max-width: 575.98px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 10000;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    .modal-wrapper {
        width: 100%;
        height: 100%;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        position: relative;
        min-height: 100%;
        overflow: hidden;
    }
    
    div.modal-top-area {
        width: 100%;
        height: 50px;
        margin-bottom: 20px
    }
    
    p.modal-title-p {
        width: calc(100% - 50px);
        font-size: 18px;
        height: 100%;
        float: left;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-top: 15px;
    }
    
    button.modal-close {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.modal-close:hover {
        background-color: #f0f0f0;
    }
    
    span.modal-close-icon {
        color: black;
        font-size: 25px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    .modal-wrapper {
        width: 100%;
        height: 100%;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        position: relative;
        min-height: 100%;
        overflow: hidden;
    }
    
    div.modal-top-area {
        width: 100%;
        height: 50px;
        margin-bottom: 20px
    }
    
    p.modal-title-p {
        width: calc(100% - 40px);
        font-size: 18px;
        height: 100%;
        float: left;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 50px;
    }
    
    button.modal-close {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.modal-close:hover {
        background-color: #f0f0f0;
    }
    
    span.modal-close-icon {
        color: black;
        font-size: 25px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    .modal-wrapper {
        width: 100%;
        height: 100%;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        position: relative;
        min-height: 100%;
        overflow: hidden;
    }
    
    div.modal-top-area {
        width: 100%;
        height: 50px;
        margin-bottom: 20px
    }
    
    p.modal-title-p {
        width: calc(100% - 40px);
        font-size: 18px;
        height: 100%;
        float: left;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 50px;
    }
    
    button.modal-close {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.modal-close:hover {
        background-color: #f0f0f0;
    }
    
    span.modal-close-icon {
        color: black;
        font-size: 25px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    .modal-wrapper {
        width: 100%;
        min-height: 100%;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        position: relative;
    }
    
    div.modal-top-area {
        width: 100%;
        height: 50px;
        margin-bottom: 20px
    }
    
    p.modal-title-p {
        width: calc(100% - 40px);
        font-size: 18px;
        height: 100%;
        float: left;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 50px;
    }
    
    button.modal-close {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.modal-close:hover {
        background-color: #f0f0f0;
    }
    
    span.modal-close-icon {
        color: black;
        font-size: 25px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    .modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
    }
    
    div.modal-top-area {
        width: 100%;
        height: 50px;
        margin-bottom: 20px
    }
    
    p.modal-title-p {
        width: calc(100% - 40px);
        font-size: 18px;
        height: 100%;
        float: left;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 50px;
    }
    
    button.modal-close {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.modal-close:hover {
        background-color: #f0f0f0;
    }
    
    span.modal-close-icon {
        color: black;
        font-size: 25px;
    }
}

@media (min-width: 1200px) {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        overflow: auto; /* This makes the overlay scrollable */
    }
    
    .modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        width: 800px;
        max-width: 100%; /* This makes sure the content doesn't overflow the overlay */
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
    }
    
    div.modal-top-area {
        width: 100%;
        height: 50px;
        margin-bottom: 20px
    }
    
    p.modal-title-p {
        width: calc(100% - 40px);
        font-size: 18px;
        height: 100%;
        float: left;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 50px;
    }
    
    button.modal-close {
        width: 40px;
        height: 40px;
        border-radius: 25px;
        float: right;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        right: 20px;
        z-index: 100;
    }
    
    button.modal-close:hover {
        background-color: #f0f0f0;
    }
    
    span.modal-close-icon {
        color: black;
        font-size: 25px;
    }
}