@media (max-width: 575.98px) {
    @keyframes fillfailed {
        100% {
            box-shadow: inset 0 0 0 40px #cc2929
        }
    }
    
    .checkmarkfailed__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #cc2929;
        fill: none;
        animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards;
    }
    
    .checkmarkfailed {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 0 auto 30px auto;
        box-shadow: inset 0 0 0 #cc2929;
        animation: fillfailed .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        margin-top: 50px;
    }
    
    h2#betaling-pay-content-title-h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p#payment-confirmed-txt-p {
        font-size: 16px;
        color: #3c3c3c;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    button#payment-confirmed-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    
    button#payment-confirmed-butt:hover {
        background-color: #333333;
    }
    
    span#payment-confirmed-butt-txt-span {
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span#payment-confirmed-butt-icon-span {
        font-size: 25px;
        margin-left: 10px;
        vertical-align: middle;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    @keyframes fillfailed {
        100% {
            box-shadow: inset 0 0 0 40px #cc2929
        }
    }
    
    .checkmarkfailed__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #cc2929;
        fill: none;
        animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards;
    }
    
    .checkmarkfailed {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 0 auto 30px auto;
        box-shadow: inset 0 0 0 #cc2929;
        animation: fillfailed .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        margin-top: 50px;
    }
    
    h2#betaling-pay-content-title-h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p#payment-confirmed-txt-p {
        font-size: 16px;
        color: #3c3c3c;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    button#payment-confirmed-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    
    button#payment-confirmed-butt:hover {
        background-color: #333333;
    }
    
    span#payment-confirmed-butt-txt-span {
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span#payment-confirmed-butt-icon-span {
        font-size: 25px;
        margin-left: 10px;
        vertical-align: middle;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    @keyframes fillfailed {
        100% {
            box-shadow: inset 0 0 0 40px #cc2929
        }
    }
    
    .checkmarkfailed__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #cc2929;
        fill: none;
        animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards;
    }
    
    .checkmarkfailed {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 0 auto 30px auto;
        box-shadow: inset 0 0 0 #cc2929;
        animation: fillfailed .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        margin-top: 50px;
    }
    
    h2#betaling-pay-content-title-h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p#payment-confirmed-txt-p {
        font-size: 16px;
        color: #3c3c3c;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    button#payment-confirmed-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    
    button#payment-confirmed-butt:hover {
        background-color: #333333;
    }
    
    span#payment-confirmed-butt-txt-span {
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span#payment-confirmed-butt-icon-span {
        font-size: 25px;
        margin-left: 10px;
        vertical-align: middle;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    @keyframes fillfailed {
        100% {
            box-shadow: inset 0 0 0 40px #cc2929
        }
    }
    
    .checkmarkfailed__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #cc2929;
        fill: none;
        animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards;
    }
    
    .checkmarkfailed {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 0 auto 30px auto;
        box-shadow: inset 0 0 0 #cc2929;
        animation: fillfailed .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        margin-top: 50px;
    }
    
    h2#betaling-pay-content-title-h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p#payment-confirmed-txt-p {
        font-size: 16px;
        color: #3c3c3c;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    button#payment-confirmed-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    
    button#payment-confirmed-butt:hover {
        background-color: #333333;
    }
    
    span#payment-confirmed-butt-txt-span {
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span#payment-confirmed-butt-icon-span {
        font-size: 25px;
        margin-left: 10px;
        vertical-align: middle;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    @keyframes fillfailed {
        100% {
            box-shadow: inset 0 0 0 40px #cc2929
        }
    }
    
    .checkmarkfailed__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #cc2929;
        fill: none;
        animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards;
    }
    
    .checkmarkfailed {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 0 auto 30px auto;
        box-shadow: inset 0 0 0 #cc2929;
        animation: fillfailed .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        margin-top: 50px;
    }
    
    h2#betaling-pay-content-title-h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p#payment-confirmed-txt-p {
        font-size: 16px;
        color: #3c3c3c;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    button#payment-confirmed-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    
    button#payment-confirmed-butt:hover {
        background-color: #333333;
    }
    
    span#payment-confirmed-butt-txt-span {
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span#payment-confirmed-butt-icon-span {
        font-size: 25px;
        margin-left: 10px;
        vertical-align: middle;
    }
}

@media (min-width: 1450px) {
    @keyframes fillfailed {
        100% {
            box-shadow: inset 0 0 0 40px #cc2929
        }
    }
    
    .checkmarkfailed__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: #cc2929;
        fill: none;
        animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards;
    }
    
    .checkmarkfailed {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 0 auto 30px auto;
        box-shadow: inset 0 0 0 #cc2929;
        animation: fillfailed .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        margin-top: 50px;
    }
    
    h2#betaling-pay-content-title-h2 {
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    p#payment-confirmed-txt-p {
        font-size: 16px;
        color: #3c3c3c;
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        padding: 0;
    }
    
    button#payment-confirmed-butt {
        width: 100%;
        height: 50px;
        background-color: #000000;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: bold;
        margin-top: 50px;
    }
    
    button#payment-confirmed-butt:hover {
        background-color: #333333;
    }
    
    span#payment-confirmed-butt-txt-span {
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    span#payment-confirmed-butt-icon-span {
        font-size: 25px;
        margin-left: 10px;
        vertical-align: middle;
    }
}