@media (max-width: 575.98px) {
    li.thinglist-listing-li {
        width: 100%;
        background-color: red;
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); 
    }
    
    div.thinglist-title-area-div {
        width: 100%;
        margin-bottom: 10px;
    }
    
    h2.thinglist-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: white;
    }
    
    div.thinglist-info-area-div {
        width: 100%;
        margin-top: 10px;
    }
    
    p.thinglist-info-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        color: white;
    }
    
    div.thinglist-item-control-bar {
        width: 100%;
        height: 35px;
    }
    
    div.thingslist-item-users-area {
        width: calc(100% - 35px);
        height: 100%;
        float: left;
    }
    
    span.things-item-icon-span {
        color: white;
        line-height: 35px;
    }
    
    div.thingslist-item-options-area {
        width: 35px;
        height: 100%;
        float: left;
    }
    
    button.thingslist-item-options-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.thingslist-item-options-btn:hover {
        background-color: rgba(239, 239, 239, 0.175);
    }
    
    span.thingslist-item-icon-span {
        font-size: 24px;
        color: white;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    li.thinglist-listing-li {
        width: 100%;
        background-color: red;
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); 
    }
    
    div.thinglist-title-area-div {
        width: 100%;
        margin-bottom: 10px;
    }
    
    h2.thinglist-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: white;
    }
    
    div.thinglist-info-area-div {
        width: 100%;
        margin-top: 10px;
    }
    
    p.thinglist-info-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        color: white;
    }
    
    div.thinglist-item-control-bar {
        width: 100%;
        height: 35px;
    }
    
    div.thingslist-item-users-area {
        width: calc(100% - 35px);
        height: 100%;
        float: left;
    }
    
    span.things-item-icon-span {
        color: white;
        line-height: 35px;
    }
    
    div.thingslist-item-options-area {
        width: 35px;
        height: 100%;
        float: left;
    }
    
    button.thingslist-item-options-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.thingslist-item-options-btn:hover {
        background-color: rgba(239, 239, 239, 0.175);
    }
    
    span.thingslist-item-icon-span {
        font-size: 24px;
        color: white;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    li.thinglist-listing-li {
        width: 100%;
        background-color: red;
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); 
    }
    
    div.thinglist-title-area-div {
        width: 100%;
        margin-bottom: 10px;
    }
    
    h2.thinglist-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: white;
    }
    
    div.thinglist-info-area-div {
        width: 100%;
        margin-top: 10px;
    }
    
    p.thinglist-info-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        color: white;
    }
    
    div.thinglist-item-control-bar {
        width: 100%;
        height: 35px;
    }
    
    div.thingslist-item-users-area {
        width: calc(100% - 35px);
        height: 100%;
        float: left;
    }
    
    span.things-item-icon-span {
        color: white;
        line-height: 35px;
    }
    
    div.thingslist-item-options-area {
        width: 35px;
        height: 100%;
        float: left;
    }
    
    button.thingslist-item-options-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.thingslist-item-options-btn:hover {
        background-color: rgba(239, 239, 239, 0.175);
    }
    
    span.thingslist-item-icon-span {
        font-size: 24px;
        color: white;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    li.thinglist-listing-li {
        width: 100%;
        background-color: red;
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); 
    }
    
    div.thinglist-title-area-div {
        width: 100%;
        margin-bottom: 10px;
    }
    
    h2.thinglist-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: white;
        font-size: 0.9em;
        line-height: 1.2;
    }
    
    div.thinglist-info-area-div {
        width: 100%;
        margin-top: 10px;
    }
    
    p.thinglist-info-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        color: white;
        font-size: 0.9em;
        line-height: 1.2;
    }
    
    div.thinglist-item-control-bar {
        width: 100%;
        height: 35px;
    }
    
    div.thingslist-item-users-area {
        width: calc(100% - 35px);
        height: 100%;
        float: left;
    }
    
    span.things-item-icon-span {
        color: white;
        line-height: 35px;
    }
    
    div.thingslist-item-options-area {
        width: 35px;
        height: 100%;
        float: left;
    }
    
    button.thingslist-item-options-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.thingslist-item-options-btn:hover {
        background-color: rgba(239, 239, 239, 0.175);
    }
    
    span.thingslist-item-icon-span {
        font-size: 24px;
        color: white;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    li.thinglist-listing-li {
        width: 100%;
        background-color: red;
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); 
    }
    
    div.thinglist-title-area-div {
        width: 100%;
        margin-bottom: 10px;
    }
    
    h2.thinglist-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: white;
    }
    
    div.thinglist-info-area-div {
        width: 100%;
        margin-top: 10px;
    }
    
    p.thinglist-info-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        color: white;
    }
    
    div.thinglist-item-control-bar {
        width: 100%;
        height: 35px;
    }
    
    div.thingslist-item-users-area {
        width: calc(100% - 35px);
        height: 100%;
        float: left;
    }
    
    span.things-item-icon-span {
        color: white;
        line-height: 35px;
    }
    
    div.thingslist-item-options-area {
        width: 35px;
        height: 100%;
        float: left;
    }
    
    button.thingslist-item-options-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.thingslist-item-options-btn:hover {
        background-color: rgba(239, 239, 239, 0.175);
    }
    
    span.thingslist-item-icon-span {
        font-size: 24px;
        color: white;
    }
}

@media (min-width: 1450px) {
    li.thinglist-listing-li {
        width: 100%;
        background-color: red;
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); 
    }
    
    div.thinglist-title-area-div {
        width: 100%;
        margin-bottom: 10px;
    }
    
    h2.thinglist-title-h2 {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        margin: 0;
        color: white;
    }
    
    div.thinglist-info-area-div {
        width: 100%;
        margin-top: 10px;
    }
    
    p.thinglist-info-p {
        font-family: 'SF Pro Display', sans-serif;
        margin: 0;
        color: white;
    }
    
    div.thinglist-item-control-bar {
        width: 100%;
        height: 35px;
    }
    
    div.thingslist-item-users-area {
        width: calc(100% - 35px);
        height: 100%;
        float: left;
    }
    
    span.things-item-icon-span {
        color: white;
        line-height: 35px;
    }
    
    div.thingslist-item-options-area {
        width: 35px;
        height: 100%;
        float: left;
    }
    
    button.thingslist-item-options-btn {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
    }
    
    button.thingslist-item-options-btn:hover {
        background-color: rgba(239, 239, 239, 0.175);
    }
    
    span.thingslist-item-icon-span {
        font-size: 24px;
        color: white;
    }
}