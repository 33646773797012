@media (max-width: 575.98px) {
    div.event-widget-small {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;
        box-sizing: border-box;
        height: 168px;
    }

    li.event-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
    }

    p.event-widget-small-day-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 11px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div.event-small-scrollable-events {
        height: calc(100% - 13px); 
        overflow-y: auto;
    }

    h3.event-widget-small-day-of-month-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }

    div.event-small-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        min-height: 10px;
        width: 100%;
    }

    /* li.event-widget-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);;
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        color: rgb(0, 94, 255);
        margin-bottom: 10px;
    } */

    div.event-small-info-area {
        width: 100%;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 10px;
    }

    div.event-small-date-info-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    p.event-small-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        font-size: 11px;
    }

    p.event-small-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.event-widget-small {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;
        box-sizing: border-box;
        height: 229px;
    }

    li.event-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
    }

    p.event-widget-small-day-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div.event-small-scrollable-events {
        height: 100%; 
        overflow-y: auto;
        padding-bottom: 20px;
    }

    h3.event-widget-small-day-of-month-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }

    div.event-small-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        min-height: 10px;
        width: 100%;
    }

    /* li.event-widget-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);;
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        color: rgb(0, 94, 255);
        margin-bottom: 10px;
    } */

    div.event-small-info-area {
        width: 100%;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 14px;
    }

    div.event-small-date-info-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    p.event-small-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        font-size: 11px;
    }

    p.event-small-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.event-widget-small {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;
        box-sizing: border-box;
    }

    li.event-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
    }

    p.event-widget-small-day-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div.event-small-scrollable-events {
        height: 100%; 
        overflow-y: auto;
        padding-bottom: 20px;
    }

    h3.event-widget-small-day-of-month-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }

    div.event-small-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        min-height: 10px;
        width: 100%;
    }

    /* li.event-widget-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);;
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        color: rgb(0, 94, 255);
        margin-bottom: 10px;
    } */

    div.event-small-info-area {
        width: 100%;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 14px;
    }

    div.event-small-date-info-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    p.event-small-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        font-size: 11px;
    }

    p.event-small-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.event-widget-small {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;
        box-sizing: border-box;
        max-height: 250px;
    }

    li.event-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
    }

    p.event-widget-small-day-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div.event-small-scrollable-events {
        height: 100%; 
        overflow-y: auto;
        padding-bottom: 20px;
    }

    h3.event-widget-small-day-of-month-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }

    div.event-small-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        min-height: 10px;
        width: 100%;
    }

    /* li.event-widget-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);;
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        color: rgb(0, 94, 255);
        margin-bottom: 10px;
    } */

    div.event-small-info-area {
        width: 100%;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 14px;
    }

    div.event-small-date-info-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    p.event-small-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        font-size: 11px;
    }

    p.event-small-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    div.event-widget-small {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;
        box-sizing: border-box;
        height: 233px;
    }

    li.event-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
    }

    p.event-widget-small-day-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div.event-small-scrollable-events {
        height: calc(100% - 27px);
        overflow-y: auto;
    }

    h3.event-widget-small-day-of-month-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }

    div.event-small-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        min-height: 10px;
        width: 100%;
    }

    /* li.event-widget-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);;
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        color: rgb(0, 94, 255);
        margin-bottom: 10px;
    } */

    div.event-small-info-area {
        width: 100%;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 14px;
    }

    div.event-small-date-info-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    p.event-small-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        font-size: 11px;
    }

    p.event-small-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 1600px) {
    div.event-widget-small {
        background-color: white;
        border-radius: 20px;
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        overflow: hidden;
        box-sizing: border-box;
        height: 233px;
    }

    li.event-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 10px;
        display: flex;
    }

    p.event-widget-small-day-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div.event-small-scrollable-events {
        height: calc(100% - 27px);
        overflow-y: auto;
    }

    h3.event-widget-small-day-of-month-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 35px;
        font-weight: bold;
    }

    div.event-small-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        min-height: 10px;
        width: 100%;
    }

    /* li.event-widget-small-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);;
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        color: rgb(0, 94, 255);
        margin-bottom: 10px;
    } */

    div.event-small-info-area {
        width: 100%;
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 14px;
    }

    div.event-small-date-info-area {
        width: 100%;
        float: left;
        min-height: inherit;
    }

    p.event-small-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        font-size: 11px;
    }

    p.event-small-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}