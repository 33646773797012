@media (max-width: 575.98px) {
    div.pre-briefing-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1001;
        overflow: auto;
    }
    
    div.pre-briefing-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.pre-briefing-modal-container {
        width: 100%;
        min-height: 100dvh;
        background-color: white;
        overflow: hidden;
        padding: 20px;
        padding-bottom: 120px;
    }
    
    div.pre-briefing-top-bar {
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-right-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2.pre-briefing-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-middle-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }
    
    div.pre-briefing-left-area {
        width: 50px;
        height: 100%;
        float: left;
    }
    
    button.pre-briefing-close-btn {
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.pre-briefing-close-btn:hover {
        background-color: #ebebeb;
    }

    button.pre-briefing-close-btn .close-icon {
        font-size: 1.8em;
    }

    div.pre-briefing-content-area {
        width: 100%;
    }
    
    div.pre-briefing-controls-area {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        right: 0;
    }
    
    div.form-control-buttons {
        width: 300px;
        border: 1px solid lightgray;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        bottom: 30px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    
    button.half-submit-buttons {
        width: 100%;
        background-color: white;
        cursor: pointer;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    button.half-submit-buttons:hover {
        background-color: #ebebeb;
    }
    
    span.submit-icon { 
        font-size: 1.5em;
        margin-right: 10px;
        font-size: 30px;
    }
    
    div.pre-briefing-error-div {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        color: red;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.pre-briefing-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1001;
        overflow: auto;
    }
    
    div.pre-briefing-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.pre-briefing-modal-container {
        width: 100%;
        min-height: 100dvh;
        background-color: white;
        overflow: hidden;
        padding: 20px;
    }
    
    div.pre-briefing-top-bar {
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-right-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2.pre-briefing-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-middle-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }
    
    div.pre-briefing-left-area {
        width: 50px;
        height: 100%;
        float: left;
    }
    
    button.pre-briefing-close-btn {
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.pre-briefing-close-btn:hover {
        background-color: #ebebeb;
    }

    button.pre-briefing-close-btn .close-icon {
        font-size: 1.8em;
    }

    div.pre-briefing-content-area {
        width: 100%;
    }
    
    div.pre-briefing-controls-area {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        right: 0;
    }
    
    div.form-control-buttons {
        width: 300px;
        border: 1px solid lightgray;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        bottom: 30px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    
    button.half-submit-buttons {
        width: 100%;
        background-color: white;
        cursor: pointer;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    button.half-submit-buttons:hover {
        background-color: #ebebeb;
    }
    
    span.submit-icon { 
        font-size: 1.5em;
        margin-right: 10px;
        font-size: 30px;
    }
    
    div.pre-briefing-error-div {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        color: red;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.pre-briefing-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1001;
        overflow: auto;
    }
    
    div.pre-briefing-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.pre-briefing-modal-container {
        width: 100%;
        min-height: 100dvh;
        background-color: white;
        overflow: hidden;
        padding: 20px;
    }
    
    div.pre-briefing-top-bar {
        width: 100%;
        height: 50px;
        margin-bottom: 50px;
    }
    
    div.pre-briefing-right-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2.pre-briefing-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-middle-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }
    
    div.pre-briefing-left-area {
        width: 50px;
        height: 100%;
        float: left;
    }
    
    button.pre-briefing-close-btn {
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.pre-briefing-close-btn:hover {
        background-color: #ebebeb;
    }

    button.pre-briefing-close-btn .close-icon {
        font-size: 1.8em;
    }

    div.pre-briefing-content-area {
        width: 100%;
    }
    
    div.pre-briefing-controls-area {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        right: 0;
    }
    
    div.form-control-buttons {
        width: 300px;
        border: 1px solid lightgray;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        bottom: 30px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    
    button.half-submit-buttons {
        width: 100%;
        background-color: white;
        cursor: pointer;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    button.half-submit-buttons:hover {
        background-color: #ebebeb;
    }
    
    span.submit-icon { 
        font-size: 1.5em;
        margin-right: 10px;
        font-size: 30px;
    }
    
    div.pre-briefing-error-div {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        color: red;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.pre-briefing-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1001;
        overflow: auto;
    }
    
    div.pre-briefing-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.pre-briefing-modal-container {
        width: 90%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        margin: 50px 0;
    }
    
    div.pre-briefing-top-bar {
        width: 100%;
        height: 50px;
    }
    
    div.pre-briefing-right-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2.pre-briefing-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-middle-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }
    
    div.pre-briefing-left-area {
        width: 50px;
        height: 100%;
        float: left;
    }

    button.pre-briefing-close-btn {
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.pre-briefing-close-btn:hover {
        background-color: #ebebeb;
    }

    button.pre-briefing-close-btn .close-icon {
        font-size: 1.8em;
    }
    
    div.pre-briefing-content-area {
        width: 100%;
    }
    
    div.pre-briefing-controls-area {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.form-control-buttons {
        width: 300px;
        border: 1px solid lightgray;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        bottom: 30px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    
    button.half-submit-buttons {
        width: 100%;
        background-color: white;
        cursor: pointer;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    button.half-submit-buttons:hover {
        background-color: #ebebeb;
    }
    
    span.submit-icon { 
        font-size: 1.5em;
        margin-right: 10px;
        font-size: 30px;
    }
    
    div.pre-briefing-error-div {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        color: red;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.pre-briefing-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1001;
        overflow: auto;
    }
    
    div.pre-briefing-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.pre-briefing-modal-container {
        width: 90%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        margin: 50px 0;
    }
    
    div.pre-briefing-top-bar {
        width: 100%;
        height: 50px;
    }
    
    div.pre-briefing-right-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2.pre-briefing-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-middle-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }
    
    div.pre-briefing-left-area {
        width: 50px;
        height: 100%;
        float: left;
    }

    button.pre-briefing-close-btn {
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.pre-briefing-close-btn:hover {
        background-color: #ebebeb;
    }

    button.pre-briefing-close-btn .close-icon {
        font-size: 1.8em;
    }
    
    div.pre-briefing-content-area {
        width: 100%;
    }
    
    div.pre-briefing-controls-area {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.form-control-buttons {
        width: 300px;
        border: 1px solid lightgray;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        bottom: 30px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    
    button.half-submit-buttons {
        width: 100%;
        background-color: white;
        cursor: pointer;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    button.half-submit-buttons:hover {
        background-color: #ebebeb;
    }
    
    span.submit-icon { 
        font-size: 1.5em;
        margin-right: 10px;
        font-size: 30px;
    }
    
    div.pre-briefing-error-div {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        color: red;
    }
}

@media (min-width: 1450px) {
    div.pre-briefing-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1001;
        overflow: auto;
    }
    
    div.pre-briefing-modal-wrapper {
        width: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.pre-briefing-modal-container {
        width: 90%;
        min-height: 100px;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        padding: 20px;
        margin: 50px 0;
    }
    
    div.pre-briefing-top-bar {
        width: 100%;
        height: 50px;
    }
    
    div.pre-briefing-right-area {
        width: calc(100% - 50px);
        height: 100%;
        float: left;
    }
    
    h2.pre-briefing-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-middle-area {
        width: 50%;
        height: 100%;
        float: left;
        display: none;
    }
    
    div.pre-briefing-left-area {
        width: 50px;
        height: 100%;
        float: left;
    }

    button.pre-briefing-close-btn {
        background-color: white;
        border: none;
        cursor: pointer;
        border-radius: 50px;
        color: black;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.pre-briefing-close-btn:hover {
        background-color: #ebebeb;
    }

    button.pre-briefing-close-btn .close-icon {
        font-size: 1.8em;
    }
    
    div.pre-briefing-content-area {
        width: 100%;
    }
    
    div.pre-briefing-controls-area {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div.form-control-buttons {
        width: 300px;
        border: 1px solid lightgray;
        display: flex;
        border-radius: 50px;
        overflow: hidden;
        bottom: 30px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }
    
    button.half-submit-buttons {
        width: 100%;
        background-color: white;
        cursor: pointer;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'SF Pro Display', sans-serif;
    }
    
    button.half-submit-buttons:hover {
        background-color: #ebebeb;
    }
    
    span.submit-icon { 
        font-size: 1.5em;
        margin-right: 10px;
        font-size: 30px;
    }
    
    div.pre-briefing-error-div {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        color: red;
    }
}