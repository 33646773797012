@media (max-width: 575.98px) {
    div.forgot-password-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    button.forgot-password-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.forgot-password-butt:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    div.forgot-password-wrapper {
        width: 400px;
        max-width: 90%;
        min-height: 100vh;
        display: block;
        margin: auto;
    }

    div.forgot-password-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.forgot-password-view-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    div.forgot-password-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    img.forgot-password-logo-img {
        height: 100px;
        display: block;
        margin: auto;
    }

    div.forgot-password-form-area-div {
        width: 100%;
        min-height: 100px;
    }

    form.forgot-password-form {}

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    label.forgot-password-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    div.forgot-password-error-field {
        width: 100%;
        min-height: 20px;
        margin-top: 10px;
        color: red;
    }

    p.forgot-password-error-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    button.forgot-password-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    button.forgot-password-button:hover {
        background-color: #0056b3;
    }

    h2.forgot-password-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    p.forgot-password-under-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        color: rgb(0, 102, 255);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    p.forgot-password-under-btn-txt-p:hover {
        text-decoration: underline;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.forgot-password-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    button.forgot-password-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.forgot-password-butt:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    div.forgot-password-wrapper {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }

    div.forgot-password-inner-page {
        width: 400px;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.forgot-password-view-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    div.forgot-password-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    img.forgot-password-logo-img {
        height: 100px;
        display: block;
        margin: auto;
    }

    div.forgot-password-form-area-div {
        width: 100%;
        min-height: 100px;
    }

    form.forgot-password-form {}

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    label.forgot-password-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    div.forgot-password-error-field {
        width: 100%;
        min-height: 20px;
        margin-top: 10px;
        color: red;
    }

    p.forgot-password-error-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    button.forgot-password-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    button.forgot-password-button:hover {
        background-color: #0056b3;
    }

    h2.forgot-password-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    p.forgot-password-under-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        color: rgb(0, 102, 255);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    p.forgot-password-under-btn-txt-p:hover {
        text-decoration: underline;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.forgot-password-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    button.forgot-password-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.forgot-password-butt:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    div.forgot-password-wrapper {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }

    div.forgot-password-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.forgot-password-view-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    div.forgot-password-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    img.forgot-password-logo-img {
        height: 100px;
        display: block;
        margin: auto;
    }

    div.forgot-password-form-area-div {
        width: 100%;
        min-height: 100px;
    }

    form.forgot-password-form {}

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    label.forgot-password-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    div.forgot-password-error-field {
        width: 100%;
        min-height: 20px;
        margin-top: 10px;
        color: red;
    }

    p.forgot-password-error-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    button.forgot-password-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    button.forgot-password-button:hover {
        background-color: #0056b3;
    }

    h2.forgot-password-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    p.forgot-password-under-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        color: rgb(0, 102, 255);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    p.forgot-password-under-btn-txt-p:hover {
        text-decoration: underline;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.forgot-password-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    button.forgot-password-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.forgot-password-butt:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    div.forgot-password-wrapper {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }

    div.forgot-password-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.forgot-password-view-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    div.forgot-password-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    img.forgot-password-logo-img {
        height: 100px;
        display: block;
        margin: auto;
    }

    div.forgot-password-form-area-div {
        width: 100%;
        min-height: 100px;
    }

    form.forgot-password-form {}

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    label.forgot-password-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    div.forgot-password-error-field {
        width: 100%;
        min-height: 20px;
        margin-top: 10px;
        color: red;
    }

    p.forgot-password-error-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    button.forgot-password-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    button.forgot-password-button:hover {
        background-color: #0056b3;
    }

    h2.forgot-password-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    p.forgot-password-under-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        color: rgb(0, 102, 255);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    p.forgot-password-under-btn-txt-p:hover {
        text-decoration: underline;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.forgot-password-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    button.forgot-password-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.forgot-password-butt:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    div.forgot-password-wrapper {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }

    div.forgot-password-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.forgot-password-view-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    div.forgot-password-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    img.forgot-password-logo-img {
        height: 100px;
        display: block;
        margin: auto;
    }

    div.forgot-password-form-area-div {
        width: 100%;
        min-height: 100px;
    }

    form.forgot-password-form {}

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    label.forgot-password-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    div.forgot-password-error-field {
        width: 100%;
        min-height: 20px;
        margin-top: 10px;
        color: red;
    }

    p.forgot-password-error-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    button.forgot-password-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    button.forgot-password-button:hover {
        background-color: #0056b3;
    }

    h2.forgot-password-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    p.forgot-password-under-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        color: rgb(0, 102, 255);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    p.forgot-password-under-btn-txt-p:hover {
        text-decoration: underline;
    }
}

@media (min-width: 1450px) {
    div.forgot-password-view {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    button.forgot-password-butt {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        left: 10px;
        top: 10px;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.forgot-password-butt:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    div.forgot-password-wrapper {
        width: 400px;
        min-height: 100vh;
        display: block;
        margin: auto;
    }

    div.forgot-password-inner-page {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.forgot-password-view-div {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    div.forgot-password-logo-area {
        width: 100%;
        min-height: 100px;
        margin-bottom: 50px;
    }

    img.forgot-password-logo-img {
        height: 100px;
        display: block;
        margin: auto;
    }

    div.forgot-password-form-area-div {
        width: 100%;
        min-height: 100px;
    }

    form.forgot-password-form {}

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    label.forgot-password-label {
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        float: left;
        margin-bottom: 10px;
    }

    div.forgot-password-form-input-bar {
        width: 100%;
        min-height: 0px;
        margin-bottom: 20px;
    }

    div.forgot-password-error-field {
        width: 100%;
        min-height: 20px;
        margin-top: 10px;
        color: red;
    }

    p.forgot-password-error-txt {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    button.forgot-password-button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 7.5px;
        background-color: #007bff;
        color: white;
        font-family: 'SF Pro Display', sans-serif;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        position: relative;
    }

    button.forgot-password-button:hover {
        background-color: #0056b3;
    }

    h2.forgot-password-title {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        text-align: center;
    }

    p.forgot-password-under-btn-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 18px;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        color: rgb(0, 102, 255);
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    p.forgot-password-under-btn-txt-p:hover {
        text-decoration: underline;
    }
}