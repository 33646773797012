@media (max-width: 575.98px) {
    div.pre-briefing-notices-step {
        width: 100%;
        margin: 0 auto;
    }
    
    h3.pre-briefing-notices-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-notices-events-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    div.pre-briefing-notices-vips-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    p.pre-briefing-notices-no-data-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin: 50px 0;
    }
    
    div.pre-briefing-notices-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
    }
    
    div.pre-briefing-notices-top-text-area {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    button.pre-briefing-notices-add-btn {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: rgb(0, 94, 255);
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        cursor: pointer;
    }
    
    button.pre-briefing-notices-add-btn span {
        vertical-align: middle;
    }

    span.pre-briefing-notices-add-btn-txt-span {
        display: none;
    }
    
    span.pre-briefing-notices-add-btn-icon-span {
    }
    
    ul.pre-briefing-notices-vips-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    
    
    li.event-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    div.event-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        display: flex;
        min-height: 10px;
        width: 100%;
    }
    
    li.event-item.holidays div.event-content-inner {
        border-color: red;
    }
    
    li.event-item.wouter_events div.event-content-inner {
        border-color: rgb(0, 94, 255);
    }
    
    li.event-item.vacations div.event-content-inner {
        border-color: orange;
    }
    
    li.event-item.sports div.event-content-inner {
        border-color: green;
    }
    
    li.event-item.created_events div.event-content-inner {
        border-color: rgb(221, 0, 221);
    }
    
    div.event-info-area {
        width: calc(100% - 150px);
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 18px;
    }
    
    div.event-date-info-area {
        width: 150px;
        float: left;
        min-height: inherit;
    }
    
    p.event-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
    }
    
    p.event-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.pre-briefing-notices-step {
        width: 100%;
        margin: 0 auto;
    }
    
    h3.pre-briefing-notices-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-notices-events-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    div.pre-briefing-notices-vips-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    p.pre-briefing-notices-no-data-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin: 50px 0;
    }
    
    div.pre-briefing-notices-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
    }
    
    div.pre-briefing-notices-top-text-area {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    button.pre-briefing-notices-add-btn {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: rgb(0, 94, 255);
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        cursor: pointer;
    }
    
    button.pre-briefing-notices-add-btn span {
        vertical-align: middle;
    }

    span.pre-briefing-notices-add-btn-txt-span {
        display: none;
    }
    
    span.pre-briefing-notices-add-btn-icon-span {
    }
    
    ul.pre-briefing-notices-vips-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    
    
    li.event-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    div.event-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        display: flex;
        min-height: 10px;
        width: 100%;
    }
    
    li.event-item.holidays div.event-content-inner {
        border-color: red;
    }
    
    li.event-item.wouter_events div.event-content-inner {
        border-color: rgb(0, 94, 255);
    }
    
    li.event-item.vacations div.event-content-inner {
        border-color: orange;
    }
    
    li.event-item.sports div.event-content-inner {
        border-color: green;
    }
    
    li.event-item.created_events div.event-content-inner {
        border-color: rgb(221, 0, 221);
    }
    
    div.event-info-area {
        width: calc(100% - 150px);
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 18px;
    }
    
    div.event-date-info-area {
        width: 150px;
        float: left;
        min-height: inherit;
    }
    
    p.event-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
    }
    
    p.event-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.pre-briefing-notices-step {
        width: 100%;
        margin: 0 auto;
    }
    
    h3.pre-briefing-notices-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-notices-events-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    div.pre-briefing-notices-vips-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    p.pre-briefing-notices-no-data-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin: 50px 0;
    }
    
    div.pre-briefing-notices-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
    }
    
    div.pre-briefing-notices-top-text-area {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    button.pre-briefing-notices-add-btn {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: rgb(0, 94, 255);
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        cursor: pointer;
    }
    
    button.pre-briefing-notices-add-btn span {
        vertical-align: middle;
    }

    span.pre-briefing-notices-add-btn-txt-span {
        display: none;
    }
    
    span.pre-briefing-notices-add-btn-icon-span {
    }
    
    ul.pre-briefing-notices-vips-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    
    
    li.event-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    div.event-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        display: flex;
        min-height: 10px;
        width: 100%;
    }
    
    li.event-item.holidays div.event-content-inner {
        border-color: red;
    }
    
    li.event-item.wouter_events div.event-content-inner {
        border-color: rgb(0, 94, 255);
    }
    
    li.event-item.vacations div.event-content-inner {
        border-color: orange;
    }
    
    li.event-item.sports div.event-content-inner {
        border-color: green;
    }
    
    li.event-item.created_events div.event-content-inner {
        border-color: rgb(221, 0, 221);
    }
    
    div.event-info-area {
        width: calc(100% - 150px);
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 18px;
    }
    
    div.event-date-info-area {
        width: 150px;
        float: left;
        min-height: inherit;
    }
    
    p.event-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
    }
    
    p.event-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.pre-briefing-notices-step {
        width: 80%;
        margin: 0 auto;
    }
    
    h3.pre-briefing-notices-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-notices-events-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    div.pre-briefing-notices-vips-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    p.pre-briefing-notices-no-data-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin: 50px 0;
    }
    
    div.pre-briefing-notices-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
    }
    
    div.pre-briefing-notices-top-text-area {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    button.pre-briefing-notices-add-btn {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: rgb(0, 94, 255);
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        cursor: pointer;
    }
    
    button.pre-briefing-notices-add-btn span {
        vertical-align: middle;
    }

    span.pre-briefing-notices-add-btn-txt-span {
        display: none;
    }
    
    span.pre-briefing-notices-add-btn-icon-span {
    }
    
    ul.pre-briefing-notices-vips-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    
    
    li.event-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    div.event-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        display: flex;
        min-height: 10px;
        width: 100%;
    }
    
    li.event-item.holidays div.event-content-inner {
        border-color: red;
    }
    
    li.event-item.wouter_events div.event-content-inner {
        border-color: rgb(0, 94, 255);
    }
    
    li.event-item.vacations div.event-content-inner {
        border-color: orange;
    }
    
    li.event-item.sports div.event-content-inner {
        border-color: green;
    }
    
    li.event-item.created_events div.event-content-inner {
        border-color: rgb(221, 0, 221);
    }
    
    div.event-info-area {
        width: calc(100% - 150px);
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 18px;
    }
    
    div.event-date-info-area {
        width: 150px;
        float: left;
        min-height: inherit;
    }
    
    p.event-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
    }
    
    p.event-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.pre-briefing-notices-step {
        width: 80%;
        margin: 0 auto;
    }
    
    h3.pre-briefing-notices-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-notices-events-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    div.pre-briefing-notices-vips-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    p.pre-briefing-notices-no-data-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin: 50px 0;
    }
    
    div.pre-briefing-notices-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
    }
    
    div.pre-briefing-notices-top-text-area {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    button.pre-briefing-notices-add-btn {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: rgb(0, 94, 255);
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        cursor: pointer;
    }
    
    button.pre-briefing-notices-add-btn span {
        vertical-align: middle;
    }
    
    span.pre-briefing-notices-add-btn-icon-span {
        margin-right: 10px;
    }
    
    ul.pre-briefing-notices-vips-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    
    
    li.event-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    div.event-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        display: flex;
        min-height: 10px;
        width: 100%;
    }
    
    li.event-item.holidays div.event-content-inner {
        border-color: red;
    }
    
    li.event-item.wouter_events div.event-content-inner {
        border-color: rgb(0, 94, 255);
    }
    
    li.event-item.vacations div.event-content-inner {
        border-color: orange;
    }
    
    li.event-item.sports div.event-content-inner {
        border-color: green;
    }
    
    li.event-item.created_events div.event-content-inner {
        border-color: rgb(221, 0, 221);
    }
    
    div.event-info-area {
        width: calc(100% - 150px);
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 18px;
    }
    
    div.event-date-info-area {
        width: 150px;
        float: left;
        min-height: inherit;
    }
    
    p.event-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
    }
    
    p.event-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}

@media (min-width: 1450px) {
    div.pre-briefing-notices-step {
        width: 60%;
        margin: 0 auto;
    }
    
    h3.pre-briefing-notices-title-h3 {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: black;
        margin: 0;
        padding: 0;
    }
    
    div.pre-briefing-notices-events-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    div.pre-briefing-notices-vips-area {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
    }
    
    p.pre-briefing-notices-no-data-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: normal;
        color: black;
        margin: 0;
        padding: 0;
        text-align: center;
        margin: 50px 0;
    }
    
    div.pre-briefing-notices-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid lightgray;
        padding: 10px 10px;
    }
    
    div.pre-briefing-notices-top-text-area {
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    div.pre-briefing-notices-top-butts-area {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    
    button.pre-briefing-notices-add-btn {
        font-family: 'SF Pro Display', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: white;
        background-color: rgb(0, 94, 255);
        border: none;
        border-radius: 50px;
        padding: 5px 20px;
        cursor: pointer;
    }
    
    button.pre-briefing-notices-add-btn span {
        vertical-align: middle;
    }
    
    span.pre-briefing-notices-add-btn-icon-span {
        margin-right: 10px;
    }
    
    ul.pre-briefing-notices-vips-listing-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    
    
    li.event-item {
        width: 100%;
        min-height: 20px;
        list-style-type: none;
        padding: 5px 10px;
        background-color: rgb(243, 244, 247);
        overflow: hidden;
        display: flex;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    
    div.event-content-inner {
        padding: 0 10px;
        border-left: 4px solid blue;
        display: flex;
        min-height: 10px;
        width: 100%;
    }
    
    li.event-item.holidays div.event-content-inner {
        border-color: red;
    }
    
    li.event-item.wouter_events div.event-content-inner {
        border-color: rgb(0, 94, 255);
    }
    
    li.event-item.vacations div.event-content-inner {
        border-color: orange;
    }
    
    li.event-item.sports div.event-content-inner {
        border-color: green;
    }
    
    li.event-item.created_events div.event-content-inner {
        border-color: rgb(221, 0, 221);
    }
    
    div.event-info-area {
        width: calc(100% - 150px);
        float: left;
        font-family: 'SF Pro Display', sans-serif;
        min-height: inherit;
        font-size: 18px;
    }
    
    div.event-date-info-area {
        width: 150px;
        float: left;
        min-height: inherit;
    }
    
    p.event-startdate-info-txt-p {
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
    }
    
    p.event-enddate-info-txt-p {
        font-family: 'SF Pro Display', sans-serif;
        text-align: right;
        color: rgb(145, 145, 145);
    }
}