@media (max-width: 575.98px) {
    div#quick-buttons-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    div.quick-widget-lock-area {
        display: flex;
        align-items: stretch;
        margin-bottom: 20px;
    }

    div.quick-widget-lock-grid-div {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
        grid-template-rows: 1fr 1fr; /* Two rows */
        gap: 15px;
        width: 100%;
    }

    div.quick-item {
        width: 100%;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
    }

    div.quick-item.inspire-me {
        background: linear-gradient(to bottom right, rgb(204, 62, 190) 0%, #e0d39b 100%);
        /* box-shadow: inset 0 0 10px 0 rgba(105, 105, 105, 0.4); */
        cursor: pointer;
    }

    div.quick-item.inspire-me:hover {
        opacity: 0.8;
    }

    div.quick-item.weather-item {
        position: relative;
    }

    div.under-info-div {
        position: absolute;
        bottom: 20px;
    }

    div.quick-item.weather-item.night {
        background: linear-gradient(to bottom, #000428 0%, #004e92 100%);
    }

    div.quick-item.weather-item.rain {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.clouds {
        background: linear-gradient(to bottom, #9098a1 0%, #84addb 100%);
    }

    div.quick-item.weather-item.drizzle {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.sun {
        background: linear-gradient(to bottom, #56bceb 0%, #b2d6ff 100%);
    }

    div.quick-item.weather-item.thunderstorm {
        background: linear-gradient(to bottom, #383c44 0%, #84addb 100%);
    }

    div.quick-item.weather-item.snow {
        background: linear-gradient(to bottom, #c3ccd7 0%, #93aac2 100%);
    }

    div.quick-item.weather-item.atmosphere {
        background: linear-gradient(to bottom, #c3ccd7 0%, #4c4e51 100%);
    }

    div.quick-item.weather-item p {
        color: white;
    }

    div.quick-button-icon-area {
        width: 100%;
        height: calc(100% - 30px);
    }

    span.quick-button-icon-span {
        font-size: 5em;
        color: rgb(0, 0, 0);
        height: 100%;
    }

    div.quick-button-txt-area {
        width: 100%;
        height: 30px;
    }

    p.quick-button-txt-p {
        font-size: 11px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    div.inspire-me p {
        color: white;
    }

    p.weather-txt {
        padding: 0;
        margin: 0;
        font-size: 20px;
        color: rgb(0, 0, 0);
        margin-bottom: 20px;
        margin-top: 5px
    }

    span.weather-icon-span {
        color: white;
        line-height: 1;
    }

    p.weather-under-txt-p {
        font-size: 11px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
    }


    p.weather-minmax-txt-p {
        font-size: 11px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    span.weather-minmax-span {
        margin-right: 10px;
    }

    img.quick-button-icon-img {
        height: 65px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div#quick-buttons-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    div.quick-widget-lock-area {
        display: flex;
        align-items: stretch;
        margin-bottom: 20px;
    }

    div.quick-widget-lock-grid-div {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
        grid-template-rows: 1fr 1fr; /* Two rows */
        gap: 15px;
        width: 100%;
    }

    div.quick-item {
        width: 100%;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
    }

    div.quick-item.inspire-me {
        background: linear-gradient(to bottom right, rgb(204, 62, 190) 0%, #e0d39b 100%);
        /* box-shadow: inset 0 0 10px 0 rgba(105, 105, 105, 0.4); */
        cursor: pointer;
    }

    div.quick-item.inspire-me:hover {
        opacity: 0.8;
    }

    div.quick-item.weather-item {
        position: relative;
    }

    div.under-info-div {
        position: absolute;
        bottom: 20px;
    }

    div.quick-item.weather-item.night {
        background: linear-gradient(to bottom, #000428 0%, #004e92 100%);
    }

    div.quick-item.weather-item.rain {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.clouds {
        background: linear-gradient(to bottom, #9098a1 0%, #84addb 100%);
    }

    div.quick-item.weather-item.drizzle {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.sun {
        background: linear-gradient(to bottom, #56bceb 0%, #b2d6ff 100%);
    }

    div.quick-item.weather-item.thunderstorm {
        background: linear-gradient(to bottom, #383c44 0%, #84addb 100%);
    }

    div.quick-item.weather-item.snow {
        background: linear-gradient(to bottom, #c3ccd7 0%, #93aac2 100%);
    }

    div.quick-item.weather-item.atmosphere {
        background: linear-gradient(to bottom, #c3ccd7 0%, #4c4e51 100%);
    }

    div.quick-item.weather-item p {
        color: white;
    }

    div.quick-button-icon-area {
        width: 100%;
        height: calc(100% - 30px);
    }

    span.quick-button-icon-span {
        font-size: 5em;
        color: rgb(0, 0, 0);
        height: 100%;
    }

    div.quick-button-txt-area {
        width: 100%;
        height: 30px;
    }

    p.quick-button-txt-p {
        font-size: 18px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    div.inspire-me p {
        color: white;
    }

    p.weather-txt {
        padding: 0;
        margin: 0;
        font-size: 30px;
        color: rgb(0, 0, 0);
        margin-bottom: 45px;
        margin-top: 5px
    }

    span.weather-icon-span {
        color: white;
        line-height: 1;
    }

    p.weather-under-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
    }


    p.weather-minmax-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    span.weather-minmax-span {
        margin-right: 10px;
    }

    img.quick-button-icon-img {
        height: 115px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#quick-buttons-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    div.quick-widget-lock-area {
        display: flex;
        align-items: stretch;
        margin-bottom: 20px;
    }

    div.quick-widget-lock-grid-div {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
        grid-template-rows: 1fr 1fr; /* Two rows */
        gap: 15px;
        width: 100%;
    }

    div.quick-item {
        width: 100%;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
    }

    div.quick-item.inspire-me {
        background: linear-gradient(to bottom right, rgb(204, 62, 190) 0%, #e0d39b 100%);
        /* box-shadow: inset 0 0 10px 0 rgba(105, 105, 105, 0.4); */
        cursor: pointer;
    }

    div.quick-item.inspire-me:hover {
        opacity: 0.8;
    }

    div.quick-item.weather-item {
        position: relative;
    }

    div.under-info-div {
        position: absolute;
        bottom: 20px;
    }

    div.quick-item.weather-item.night {
        background: linear-gradient(to bottom, #000428 0%, #004e92 100%);
    }

    div.quick-item.weather-item.rain {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.clouds {
        background: linear-gradient(to bottom, #9098a1 0%, #84addb 100%);
    }

    div.quick-item.weather-item.drizzle {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.sun {
        background: linear-gradient(to bottom, #56bceb 0%, #b2d6ff 100%);
    }

    div.quick-item.weather-item.thunderstorm {
        background: linear-gradient(to bottom, #383c44 0%, #84addb 100%);
    }

    div.quick-item.weather-item.snow {
        background: linear-gradient(to bottom, #c3ccd7 0%, #93aac2 100%);
    }

    div.quick-item.weather-item.atmosphere {
        background: linear-gradient(to bottom, #c3ccd7 0%, #4c4e51 100%);
    }

    div.quick-item.weather-item p {
        color: white;
    }

    div.quick-button-icon-area {
        width: 100%;
        height: calc(100% - 30px);
    }

    span.quick-button-icon-span {
        font-size: 5em;
        color: rgb(0, 0, 0);
        height: 100%;
    }

    div.quick-button-txt-area {
        width: 100%;
        height: 30px;
    }

    p.quick-button-txt-p {
        font-size: 20px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    div.inspire-me p {
        color: white;
    }

    p.weather-txt {
        padding: 0;
        margin: 0;
        font-size: 40px;
        color: rgb(0, 0, 0);
        margin-bottom: 110px;
        margin-top: 5px
    }

    span.weather-icon-span {
        color: white;
        line-height: 1;
    }

    p.weather-under-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
    }


    p.weather-minmax-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    span.weather-minmax-span {
        margin-right: 10px;
    }

    img.quick-button-icon-img {
        height: 115px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div#quick-buttons-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    div.quick-widget-lock-area {
        display: flex;
        align-items: stretch;
        margin-bottom: 20px;
    }

    div.quick-widget-lock-grid-div {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
        grid-template-rows: 1fr 1fr; /* Two rows */
        gap: 15px;
        width: 100%;
    }

    div.quick-item {
        width: 100%;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
    }

    div.quick-item.inspire-me {
        background: linear-gradient(to bottom right, rgb(204, 62, 190) 0%, #e0d39b 100%);
        /* box-shadow: inset 0 0 10px 0 rgba(105, 105, 105, 0.4); */
        cursor: pointer;
    }

    div.quick-item.inspire-me:hover {
        opacity: 0.8;
    }

    div.quick-item.weather-item {
        position: relative;
    }

    div.under-info-div {
        position: absolute;
        bottom: 20px;
    }

    div.quick-item.weather-item.night {
        background: linear-gradient(to bottom, #000428 0%, #004e92 100%);
    }

    div.quick-item.weather-item.rain {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.clouds {
        background: linear-gradient(to bottom, #9098a1 0%, #84addb 100%);
    }

    div.quick-item.weather-item.drizzle {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.sun {
        background: linear-gradient(to bottom, #56bceb 0%, #b2d6ff 100%);
    }

    div.quick-item.weather-item.thunderstorm {
        background: linear-gradient(to bottom, #383c44 0%, #84addb 100%);
    }

    div.quick-item.weather-item.snow {
        background: linear-gradient(to bottom, #c3ccd7 0%, #93aac2 100%);
    }

    div.quick-item.weather-item.atmosphere {
        background: linear-gradient(to bottom, #c3ccd7 0%, #4c4e51 100%);
    }

    div.quick-item.weather-item p {
        color: white;
    }

    div.quick-button-icon-area {
        width: 100%;
        height: calc(100% - 30px);
    }

    span.quick-button-icon-span {
        font-size: 5em;
        color: rgb(0, 0, 0);
        height: 100%;
    }

    div.quick-button-txt-area {
        width: 100%;
        height: 30px;
    }

    p.quick-button-txt-p {
        font-size: 18px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    div.inspire-me p {
        color: white;
    }

    p.weather-txt {
        padding: 0;
        margin: 0;
        font-size: 30px;
        color: rgb(0, 0, 0);
        margin-bottom: 45px;
        margin-top: 5px
    }

    span.weather-icon-span {
        color: white;
        line-height: 1;
    }

    p.weather-under-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
    }


    p.weather-minmax-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    span.weather-minmax-span {
        margin-right: 10px;
    }

    img.quick-button-icon-img {
        height: 115px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div#quick-buttons-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }

    div.quick-widget-lock-area {
        display: flex;
        align-items: stretch;
        margin-bottom: 20px;
    }

    div.quick-widget-lock-grid-div {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two columns */
        grid-template-rows: 1fr 1fr; /* Two rows */
        gap: 15px;
        width: 100%;
    }

    div.quick-item {
        width: 100%;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        padding: 15px;
    }

    div.quick-item.inspire-me {
        background: linear-gradient(to bottom right, rgb(204, 62, 190) 0%, #e0d39b 100%);
        /* box-shadow: inset 0 0 10px 0 rgba(105, 105, 105, 0.4); */
        cursor: pointer;
    }

    div.quick-item.inspire-me:hover {
        opacity: 0.8;
    }

    div.quick-item.weather-item {
        position: relative;
    }

    div.under-info-div {
        position: absolute;
        bottom: 20px;
    }

    div.quick-item.weather-item.night {
        background: linear-gradient(to bottom, #000428 0%, #004e92 100%);
    }

    div.quick-item.weather-item.rain {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.clouds {
        background: linear-gradient(to bottom, #9098a1 0%, #84addb 100%);
    }

    div.quick-item.weather-item.drizzle {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.sun {
        background: linear-gradient(to bottom, #56bceb 0%, #b2d6ff 100%);
    }

    div.quick-item.weather-item.thunderstorm {
        background: linear-gradient(to bottom, #383c44 0%, #84addb 100%);
    }

    div.quick-item.weather-item.snow {
        background: linear-gradient(to bottom, #c3ccd7 0%, #93aac2 100%);
    }

    div.quick-item.weather-item.atmosphere {
        background: linear-gradient(to bottom, #c3ccd7 0%, #4c4e51 100%);
    }

    div.quick-item.weather-item p {
        color: white;
    }

    div.quick-button-icon-area {
        width: 100%;
        height: calc(100% - 30px);
    }

    span.quick-button-icon-span {
        font-size: 5em;
        color: rgb(0, 0, 0);
        height: 100%;
    }

    div.quick-button-txt-area {
        width: 100%;
        height: 30px;
    }

    p.quick-button-txt-p {
        font-size: 20px;
        font-weight: 600;
        color: rgb(0, 0, 0);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    div.inspire-me p {
        color: white;
    }

    p.weather-txt {
        padding: 0;
        margin: 0;
        font-size: 40px;
        color: rgb(0, 0, 0);
        margin-bottom: 110px;
        margin-top: 5px
    }

    span.weather-icon-span {
        color: white;
        line-height: 1;
    }

    p.weather-under-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
    }


    p.weather-minmax-txt-p {
        font-size: 18px;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    span.weather-minmax-span {
        margin-right: 10px;
    }

    img.quick-button-icon-img {
        height: 115px;
    }
}

@media (min-width: 1200px) {
    div#quick-buttons-container {
        width: 100%;
        display: grid;
        padding: 20px;
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    div.quick-widget-lock-area {
        display: flex;
        align-items: stretch;
    }

    div.quick-widget-lock-grid-div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 15px;
        width: 100%;
    }

    div.quick-item {
        width: 100%;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        padding: 20px;
    }

    div.quick-item.inspire-me {
        background: linear-gradient(to bottom right, rgb(204, 62, 190) 0%, #e0d39b 100%);
        /* box-shadow: inset 0 0 10px 0 rgba(105, 105, 105, 0.4); */
    }

    div.quick-item.weather-item {
        position: relative;
    }

    div.under-info-div {
        position: absolute;
        bottom: 20px;
    }
    div.quick-item.pre-briefing-btn {
        cursor: pointer;
    }
    
    div.quick-item.pre-briefing-btn:hover {
        background-color: #efefef;
    }

    div.quick-item.weather-item.night {
        background: linear-gradient(to bottom, #000428 0%, #004e92 100%);
    }

    div.quick-item.weather-item.rain {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.clouds {
        background: linear-gradient(to bottom, #9098a1 0%, #84addb 100%);
    }

    div.quick-item.weather-item.drizzle {
        background: linear-gradient(to bottom, #c3ccd7 0%, #8a9eb2 100%);
    }

    div.quick-item.weather-item.sun {
        background: linear-gradient(to bottom, #56bceb 0%, #b2d6ff 100%);
    }

    div.quick-item.weather-item.thunderstorm {
        background: linear-gradient(to bottom, #383c44 0%, #84addb 100%);
    }

    div.quick-item.weather-item.snow {
        background: linear-gradient(to bottom, #c3ccd7 0%, #93aac2 100%);
    }

    div.quick-item.weather-item.atmosphere {
        background: linear-gradient(to bottom, #c3ccd7 0%, #4c4e51 100%);
    }

    div.quick-item.weather-item p {
        color: white;
    }

    div.quick-button-icon-area {
        width: 100%;
        height: calc(100% - 30px);
    }

    span.quick-button-icon-span {
        font-size: 5em;
        color: rgb(0, 0, 0);
        height: 100%;
    }

    div.quick-button-txt-area {
        width: 100%;
        height: 30px;
    }

    p.quick-button-txt-p {
        font-size: 1.2em;
        font-weight: 600;
        color: rgb(0, 0, 0);
        padding: 0;
        margin: 0;
        line-height: 1;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
    }

    div.inspire-me p {
        color: white;
    }

    p.weather-txt {
        padding: 0;
        margin: 0;
        font-size: 2.5em;
        color: rgb(0, 0, 0);
        margin-bottom: 20px;
        margin-top: 5px
    }

    span.weather-icon-span {
        color: white;
        line-height: 1;
    }

    p.weather-under-txt-p {
        font-size: 1em;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-bottom: 10px;
    }


    p.weather-minmax-txt-p {
        font-size: 1em;
        color: white;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    span.weather-minmax-span {
        margin-right: 10px;
    }

    img.quick-button-icon-img {
        height: 100px;
    }
}