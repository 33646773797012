div.password-input-bar {
    width: 100%;
    min-height: 50px;
    float: left;
    margin-bottom: 10px;
}

label.password-input-label {
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    line-height: 40px;
}

input.password-input {
    width: 100%;
    height: 40px;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 0 20px;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    padding: 0 10px;
}

input.password-input:focus {
    outline: none;
}

button.password-submit-btn {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
}

button.password-submit-btn:hover {
    background-color: #0368d3;
}