@media (max-width: 575.98px) {
    div.settings-inner-area {
        height: calc(100% - 80px);
        background-color: white;
        position: relative;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    }

    div.settings-content-div {
        width: 100%;
        float: left;
        height: 100%;
        padding: 0 15px 80px 15px;
        overflow: auto;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.settings-inner-area {
        height: calc(100% - 80px);
        background-color: white;
        position: relative;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    }

    div.settings-content-div {
        width: 100%;
        float: left;
        height: 100%;
        padding: 0 15px 80px 15px;
        overflow: auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.settings-inner-area {
        height: calc(100% - 80px);
        background-color: white;
        position: relative;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        overflow: hidden;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    }

    div.settings-content-div {
        width: 100%;
        float: left;
        height: 100%;
        padding: 0 15px 80px 15px;
        overflow: auto;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.settings-inner-area {
        height: calc(100% - 100px);
        background-color: white;
        margin-left: 2.5%;
        margin: 20px;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    div.settings-content-div {
        width: calc(100% - 300px);
        float: left;
        height: 100%;
        padding: 0 30px 30px 30px;
        overflow: auto;
    }
}

@media (min-width: 1200px) {
    div.settings-inner-area {
        height: calc(100% - 100px);
        background-color: white;
        margin-left: 2.5%;
        margin: 20px;
        border-radius: 20px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    }

    div.settings-content-div {
        width: calc(100% - 300px);
        float: left;
        height: 100%;
        padding: 0 30px 30px 30px;
        overflow: auto;
    }
}