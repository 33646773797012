.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke .6s cubic-bezier(.65, 0, .45, 1) forwards
}

.checkmarkdone {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0 auto 30px auto;
    box-shadow: inset 0 0 0 #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s cubic-bezier(.65, 0, .45, 1) .8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0 0 0 40px #7ac142
    }
}

p#payment-confirmed-txt-p {
    font-size: 18px;
    color: #000000;
    font-family: 'SF Pro Display', sans-serif;
}

div#payment-confirmed-box-div {
    width: 100%;
    margin-top: 50px;
}

p#payment-confirmation-title-txt-p {
    font-size: 20px;
    color: #000000;
    font-family: 'SF Pro Display', sans-serif;
    margin: 0;
}

span.payment-confirm-var-span {
    font-weight: bold;
    color: #000000;
    font-family: 'SF Pro Display', sans-serif;
}