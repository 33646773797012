body {
    background-color: rgb(243, 250, 255);
    overflow: hidden;
}

div.loading-wrapper {
    width: 100%;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}