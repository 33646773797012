@media (max-width: 575.98px) {
    div.add-subsidiary-view {
        width: 100%;
        height: 100vh;
        display: block;
        overflow: auto;
    }
    
    nav.add-subsidiary-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    
    ul.add-subsidiary-nav-listing-ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    li.add-subsidiary-left-li {
        display: flex;
        align-items: center;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }
    
    button.add-subsidiary-exit-btn {
        height: 40px;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button.add-subsidiary-exit-btn:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    
    li.add-subsidiary-center-li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        float: left;
    }
    
    li.add-subsidiary-right-li {
        display: flex;
        align-items: center;
        float: left;
    }
    
    div.add-subsidiary-content-area {
        width: 90%;
        min-height: calc(100% - 180px);
        margin: 0 auto;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 120px;
    }
    
    h1.add-subsidiary-form-title-h1 {
        font-size: 1.5em;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    p.add-subsidiary-form-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    div.add-subsidiary-controls-area {
        position: fixed;
        bottom: 20px;
    }
    
    p.error-msg {
        color: red;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        color: red;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    div.add-subsidiary-view {
        width: 100%;
        height: 100vh;
        display: block;
        overflow: auto;
    }
    
    nav.add-subsidiary-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    
    ul.add-subsidiary-nav-listing-ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    li.add-subsidiary-left-li {
        display: flex;
        align-items: center;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }
    
    button.add-subsidiary-exit-btn {
        height: 40px;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button.add-subsidiary-exit-btn:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    
    li.add-subsidiary-center-li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        float: left;
    }
    
    li.add-subsidiary-right-li {
        display: flex;
        align-items: center;
        float: left;
    }
    
    div.add-subsidiary-content-area {
        width: 90%;
        min-height: calc(100% - 180px);
        margin: 0 auto;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 120px;
    }
    
    h1.add-subsidiary-form-title-h1 {
        font-size: 1.5em;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    p.add-subsidiary-form-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    div.add-subsidiary-controls-area {
        position: fixed;
        bottom: 20px;
    }
    
    p.error-msg {
        color: red;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        color: red;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    div.add-subsidiary-view {
        width: 100%;
        height: 100vh;
        display: block;
        overflow: auto;
    }
    
    nav.add-subsidiary-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    
    ul.add-subsidiary-nav-listing-ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    li.add-subsidiary-left-li {
        display: flex;
        align-items: center;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }
    
    button.add-subsidiary-exit-btn {
        height: 40px;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button.add-subsidiary-exit-btn:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    
    li.add-subsidiary-center-li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        float: left;
    }
    
    li.add-subsidiary-right-li {
        display: flex;
        align-items: center;
        float: left;
    }
    
    div.add-subsidiary-content-area {
        width: 90%;
        min-height: calc(100% - 180px);
        margin: 0 auto;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 120px;
    }
    
    h1.add-subsidiary-form-title-h1 {
        font-size: 1.5em;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    p.add-subsidiary-form-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    div.add-subsidiary-controls-area {
        position: fixed;
        bottom: 20px;
    }
    
    p.error-msg {
        color: red;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        color: red;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    div.add-subsidiary-view {
        width: 100%;
        height: 100vh;
        display: block;
        overflow: auto;
    }
    
    nav.add-subsidiary-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    
    ul.add-subsidiary-nav-listing-ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    li.add-subsidiary-left-li {
        display: flex;
        align-items: center;
        width: 100%;
        float: left;
        margin-bottom: 20px;
    }
    
    button.add-subsidiary-exit-btn {
        height: 40px;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button.add-subsidiary-exit-btn:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    
    li.add-subsidiary-center-li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        float: left;
    }
    
    li.add-subsidiary-right-li {
        display: flex;
        align-items: center;
        float: left;
    }
    
    div.add-subsidiary-content-area {
        width: 90%;
        min-height: calc(100% - 180px);
        margin: 0 auto;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 120px;
    }
    
    h1.add-subsidiary-form-title-h1 {
        font-size: 1.5em;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    p.add-subsidiary-form-txt-p {
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    div.add-subsidiary-controls-area {
        position: fixed;
        bottom: 20px;
    }
    
    p.error-msg {
        color: red;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        color: red;
    }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
    div.add-subsidiary-view {
        width: 100%;
        height: 100vh;
        display: block;
        overflow: auto;
    }
    
    nav.add-subsidiary-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    
    ul.add-subsidiary-nav-listing-ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    li.add-subsidiary-left-li {
        display: flex;
        align-items: center;
        margin-right: 20px;
        width: 200px;
        float: left;
    }
    
    button.add-subsidiary-exit-btn {
        height: 40px;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button.add-subsidiary-exit-btn:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    
    li.add-subsidiary-center-li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: calc(100% - 400px);
        float: left;
    }
    
    li.add-subsidiary-right-li {
        display: flex;
        align-items: center;
        float: left;
    }
    
    div.add-subsidiary-content-area {
        width: 60%;
        min-height: calc(100% - 180px);
        margin: 0 auto;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 120px;
    }
    
    h1.add-subsidiary-form-title-h1 {
        font-size: 2.5em;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    p.add-subsidiary-form-txt-p {
        font-size: 1.2em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    div.add-subsidiary-controls-area {
        position: fixed;
        bottom: 20px;
    }
    
    p.error-msg {
        color: red;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        color: red;
    }
}

@media (min-width: 1450px) {
    div.add-subsidiary-view {
        width: 100%;
        height: 100vh;
        display: block;
    }
    
    nav.add-subsidiary-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    
    ul.add-subsidiary-nav-listing-ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    li.add-subsidiary-left-li {
        display: flex;
        align-items: center;
        margin-right: 20px;
        width: 200px;
        float: left;
    }
    
    button.add-subsidiary-exit-btn {
        height: 40px;
        padding: 0 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    button.add-subsidiary-exit-btn:hover {
        background-color: rgba(0, 0, 0, 0.03);
    }
    
    li.add-subsidiary-center-li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        width: calc(100% - 400px);
        float: left;
    }
    
    li.add-subsidiary-right-li {
        display: flex;
        align-items: center;
        float: left;
    }
    
    div.add-subsidiary-content-area {
        width: 40%;
        min-height: calc(100% - 180px);
        margin: 0 auto;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    h1.add-subsidiary-form-title-h1 {
        font-size: 2.5em;
        font-weight: bold;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    p.add-subsidiary-form-txt-p {
        font-size: 1.2em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
    }
    
    div.add-subsidiary-controls-area {
        position: fixed;
        bottom: 20px;
    }
    
    p.error-msg {
        color: red;
        font-size: 1em;
        font-family: 'SF Pro Display', sans-serif;
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        color: red;
    }
}